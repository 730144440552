/* General Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Container styling for the BlogList */
.container-blog {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Blog Layout */
.blog-layout {
  margin-top:82px;
  display: flex;
  gap: 20px;
  padding: 20px;
}

.main-content {
  flex: 2;
}

.sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Featured Blog */
.featured-blog {
  margin-bottom: 40px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.featured-blog img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.featured-blog-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 15px;
  border-radius: 4px;
}

.featured-blog-content h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

/* Blog Cards */
.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two cards per row */
  gap: 20px;
  margin-top: 20px;
}

.blog-card {
  background: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.blog-card:hover {
  transform: scale(1.03);
}

.blog-card-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Sidebar */
.search-bar {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.banner {
  padding: 0;
  margin: 0;
}

.banner img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
}

.qr-code img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
}

.qr-code p {
  text-align: center;
  margin-top: 5px;
}

/* .sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative; 
} */

/* Sticky Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: sticky; 
  top: 10px; 
  right: 50px;
  /* z-index: 1000;  */
  border:1px solid #ccc;
  background-color: #fff; /* Optional: Add a background for better visibility */
  padding: 20px; /* Optional: Add padding for better spacing */
  border-radius: 8px; /* Optional: Rounded corners for styling */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form button {
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}


/* Blog Title */
.blog-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: left;
}


/* Blog Content */
.blog-content {
  font-size: 1rem;
  line-height: 1.8;
  color: #444;
  text-align: justify;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-layout {
    flex-direction: column;
  }

  .card-grid {
    grid-template-columns: 1fr; /* Single column on small screens */
    gap: 15px;
  }
}
