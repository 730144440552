/* Main container */
.design-component-container {
    width: 100%;
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
}

/* Logo container */
.design-component-logo-container {
    position: absolute;
    top: 40px;
    left: 40px;
}

.design-component-logo-image {
    width: auto;
    height: 40px;
}

/* Left and right containers */
.design-component-left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    max-width: 50%;
}

.design-component-right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
}

/* Title and text styling */
.design-component-title {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.design-component-highlight {
    font-size: 36px;
}

.design-component-subtext {
    font-size: 30px;
    font-style: italic;
    color: #e63946;
    font-family: 'Lobster Two', cursive;
}

/* Stats container and box styling */
.design-component-stats-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* max-width: 400px; */
    margin-top: 20px;
    padding: 10px 20px;
    gap: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.design-component-stat-box {
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    min-width: 80px; /* Ensures minimum width to fit content */
    flex: 1; /* Allows stat boxes to flex and occupy available space */
}

.design-component-stat-number {
    font-size: 24px;
    font-weight: bold;
    color: #e63946;
}

.design-component-stat-label {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

/* QR code and button container */
.design-component-qr-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.design-component-computer-image {
    width: 80%;
    margin-bottom: 20px;
}

.design-component-qr-image {
    width: 100px;
    height: 100px;
}

.design-component-button {
    background-color: #e63946;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 20px;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .design-component-container {
        flex-direction: column;
        text-align: center;
        height: 100vh;
        padding: 10px;
        align-items: center;
        justify-content: center;
        width:100%;
    }

    .design-component-logo-container {
        top: 20px;
        left: 20px;
    }

    .design-component-right-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        /* margin-top: 20px; */
        padding-top: 2rem;
    }

    .design-component-title,
    .design-component-highlight {
        font-size: 22px;
        /* margin-top: 3.5rem; */
    }

    .design-component-subtext {
        font-size: 19px;
    }
    .design-component-left-container{
        max-width: 95%;
    }

    .design-component-stats-container {
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 10px;
        margin-top: 10px;
        gap: 5px;
        width: 100%;
    }

    .design-component-stat-box {
        min-width: 100px; /* Adjust min-width for mobile view */
        padding: 15px; /* Add padding for better spacing */
        flex: 1; /* Ensures stat boxes flex to fit */
    }

    .design-component-stat-number {
        font-size: 14px;
    }

    .design-component-stat-label {
        font-size: 7px;
    }

    .design-component-qr-container {
        flex-direction: row;
        gap: 10px;
    }

    .design-component-qr-image {
        width: 80px;
        height: 80px;
    }

    .design-component-computer-image {
        width: 90%;
    }

    .design-component-button {
        font-size: 14px;
        padding: 8px 16px;
        margin-bottom: 0px;
    }
}
