.industry {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns on desktop */
    gap: 30px;
    padding: 20px;
    justify-content: center; /* Center the grid horizontally */
    align-items: center; /* Center items vertically */
    justify-items: center; /* Center content within each grid cell */
}

.section-one,
.section-two {
    display: contents; /* Ensures they share the grid structure of .industry */
}


.software-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:50px;
    padding:30px;
}
.software-type1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:50px;
    padding:30px;
}

.process{
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items: center;
    justify-content: center;
}

.process {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow-x: auto; /* Enable horizontal scroll if content overflows */
    white-space: nowrap; /* Prevents items from wrapping to new lines */
}

/* Mobile view: switch to 2 columns */
@media (max-width: 768px) {
    .industry {
        grid-template-columns: repeat(2, 1fr); /* 2 columns */
        gap: 10px; /* Adjust gap as needed */
    }
    .industry img {
        width: 90%; /* Reduce image size by 20% */
        height: auto; /* Maintain aspect ratio */
    }
    .software-type{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* gap:50px;
        padding:30px; */
    }
    .software-type-image{
        width: 80%;
    }
    .software-type1{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        /* gap:50px;
        padding:30px; */
    }
    .software-type-image{
        width: 80%;
    }
    .process {
        display: none;
        /* visibility: none; */
    }
    
}
@media (max-width: 500px) {
    .industry {
        grid-template-columns: repeat(1, 1fr); /* 2 columns */
        gap: 10px; /* Adjust gap as needed */
    }
    .industry img {
        width: 100%;
        height: auto; 
    }
}