/* @media screen and (max-width:1124px) {
    .navbar-styles {
        .navbar-collapse {
            .navbar-nav {
                .nav-link {
                    font-size: 12px !important;
                }
            }
        }

        .lets-talk-btn {
            margin-left: 12px !important;
        }
    }

    .hero-container {
        height: 100vh !important;

        .hero-container-inner {
            .hero-section1 {
                width: 55%;

                .hero-smallText {
                    line-height: 28px !important;
                }
            }
        }
    }

    .home-section2 {
        .section2-lower {
            .sponsers {
                padding-left: 0px !important;
                padding-right: 0px !important;

                img {
                    width: 157px;
                }
            }
        }
    }

    .home-section3 {
        margin-top: 110px !important;

        .overlap-div {
            .image {
                top: -26px !important;

                img {
                    width: 353px;
                    height: 310px;
                }
            }
        }

        .overlap-div.leftside {

            .image {
                top: -23px !important;

                img {
                    width: 353px;
                    height: 310px;
                }
            }
        }
    }

    .home-section4 {
        .process-circle {
            .work-process-image {
                width: 526px !important;
                height: 574px !important;
            }

            .step {
                .stepnumber {
                    font-size: 21px !important;
                }

                .step-text {
                    font-size: 21px !important;
                }

                .step-subtext {
                    font-size: 13px !important;
                }
            }

            .step1 {
                top: unset !important;
                bottom: 0 !important;
                transform: translate(36px, -228px) !important;
            }

            .step2 {
                top: unset !important;
                left: -23px !important;
                bottom: 0 !important;
                transform: translate(36px, -352px) !important;
            }

            .step3 {
                top: 0 !important;
                left: 0px !important;
                bottom: unset !important;
                transform: translate(49px, -7px) !important;
            }

            .step4 {
                top: 0 !important;
                right: 0px !important;
                bottom: unset !important;
                transform: translate(-42px, 4px) !important;
            }

            .step5 {
                top: 0 !important;
                right: 0px !important;
                bottom: unset !important;
                transform: translate(-8px, 121px) !important;
            }

            .step6 {
                top: 0 !important;
                right: 0px !important;
                bottom: unset !important;
                transform: translate(-35px, 244px) !important;
            }

            .step7 {
                top: unset !important;

                bottom: 0 !important;

                text-align: center;
            }
        }
    }
}
 */


.web-view {
    @include respond(smallphone) {
        display: none !important;
    }

    @include respond(miphone) {
        display: none !important;
    }

    @include respond(marginproblem) {
        display: none !important;
    }

    @include respond(phone) {
        display: none !important;
    }

    @include respond(smalltab) {
        display: none !important;
    }

    @include respond(tab-port) {
        display: none !important;
    }

    @include respond(tab-land) {
        display: none !important;
    }

    @include respond(large-tab) {
        display: none !important;
    }

    @include respond(desktop) {
        display: flex !important;
    }

    @include respond(big-desktop) {
        display: flex !important;
    }

    @include respond(largedesktop) {
        display: flex !important;
    }
}

.mobile-view {
    @include respond(smallphone) {
        display: flex !important;
    }

    @include respond(miphone) {
        display: flex !important;
    }

    @include respond(marginproblem) {
        display: flex !important;
    }

    @include respond(phone) {
        display: flex !important;
    }

    @include respond(smalltab) {
        display: flex !important;
    }

    @include respond(tab-port) {
        display: flex !important;
    }

    @include respond(tab-land) {
        display: flex !important;
    }

    @include respond(large-tab) {
        display: flex !important;
    }

    @include respond(desktop) {
        display: none !important;
    }

    @include respond(big-desktop) {
        display: none !important;
    }

    @include respond(largedesktop) {
        display: none !important;
    }
}




@include respond(smallphone) {

    .navbar-styles {
        padding-left: 15px !important;
        padding-right: 15px !important;

        .btn.btn-primary {
            display: none;
        }

        .navbar-toggler {
            background: white;
        }

        .navbar-brand {
            margin-right: 0px !important;

            img {
                width: 195px !important;
                height: 50px !important;
            }
        }

        .menu-icon {
            div {
                width: 26px !important;
                height: 20px !important;
            }
        }
    }

    .hero-container.container {
        margin-top: -130px !important;

        .hero-container-inner {
            .hero-section1 {
                width: 100%;

                .section-1-inner {
                    .hero-bigText {
                        font-size: 24px !important;
                        text-align: center!important;
                    }

                    .hero-smallText {
                        line-height: 30px !important;
                        font-size: 18px !important;
                        text-align: center !important;
                    }

                    .btn-primary {
                        a {

                            font-size: 16px !important;

                            padding: 14px 5px !important;
                        }
                    }
                }
            }
        }
    }

    .hero-container.container.hero-container-industries-bg {

        .hero-container-inner {
            .hero-section1 {
                width: 100%;

                .section-1-inner {
                    .hero-smallText {
                        text-align: center !important;
                    }
                }
            }
        }
    }

    .home-section2 {

        padding: 60px 10px !important;

        .section2-upper {
            padding: 0px !important;
            .card-section{
                background-repeat: repeat-y!important;
                .row{
                    .uneven-columns{
                        margin:10px 10px 0px !important;
                    }
                }
            }
            .row {
                .col-lg-6 {
                    .greybox {
                        padding: 25px 20px !important;
                    }

                    .projectbox {
                        .lower-projectbox {
                            .pb-left {
                                margin-right: 75px !important;
                            }
                        }
                    }
                }
            }
        }

        .section2-lower {
            margin-top: 70px !important;
            padding: 10px!important;
            .mid-title{
                font-size: 26px !important;
                text-align: center !important;
                line-height: 30px !important;
            }
            .sponsers {
                display: inline-block !important;
                margin-top: 30px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
                
                img {
                    width: 120px !important;
                    margin: 10px !important;
                }
            }
        }

    }

    .home-section3 {
        margin-top: 0px !important;

        .mid-para {
            margin-bottom: 80px !important;
            padding: 0px 15px;
            font-size: 16px !important;
        }

        .overlap-div.rightside {

            justify-content: center;
        }

        .overlap-div {
            flex-direction: column;

            .image {
                top: 0px !important;
                position: relative !important;

                img {
                    width: 100% !important;
                }
            }

            .info-div {
                width: 100% !important;

                .red-heading {
                    padding: 10px !important;
                    text-align: center
                }

                .grey-container {
                    padding: 20px 0px !important;
                    .list-items{
                        .item{
                            .icon{
                                margin-right: 16px !important;
                                &::before{
                                    height: 16px;
                                    width: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-section4 {
        display: none;
    }

    .home-section5 {
        margin-top: 60px !important;

        padding: 40px 30px 50px!important;
        .home-carousel {
            margin-top: 30px !important;
            padding: 20px !important;

            .carousel {
                .carousel-control-next {
                    display: none;
                }

                .carousel-control-prev {
                    display: none;
                }

                .carousel-inner {

                    .carousel-item {
                        .carousel-item1 {
                            .upper-side {}

                            .lower-side {
                                flex-direction: column;

                                .left-side {
                                    width: 100% !important;
                                    height: 400px !important;
                                }

                                .right-side {
                                    width: 100% !important;
                                    padding: 30px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .contact-section {
        margin-top: 110px !important;
        padding: 10px !important;

        .midtitle {
            text-align: center;
            font-size: 26px !important;
            line-height:30px!important;
        }

        .midsmalltext {
            font-size: 18px !important;

        }

        .contact-box {
            margin: 20px !important;

            .row {
                .col-lg-8 {
                    .contact-form {
                        .contact-input {
                            padding: 20px 0px 20px 20px !important;
                        }

                        .textarea {
                            padding: 20px 0px 20px 20px !important;
                        }
                    }

                    .bottom-row {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .col-lg-6 {
                            width: 100% !important;

                            .contact-form-btn {
                                padding: 15px 40px !important;
                                font-size: 25px;
                            }
                        }
                    }
                }

                .col-lg-4 {
                    height: 380px;
                }
            }
        }
    }

    .footer-container {
        .top-row {
            padding: 30px 30px 60px !important;
            flex-direction: column;

            .socials {
                margin: 20px 0px !important;
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    margin-right: unset !important;
                    background: black;
                }
            }

            .column-2,
            .column-3,
            .column-4 {
                .heading {
                    margin-bottom: 20px !important;
                }
            }

            .column-2 {
                margin-bottom: 30px !important;
            }

            .column-3 {
                margin-bottom: 30px !important;
            }

            .column-4 {
                width: 100% !important;
                margin-top: 20px !important;

            }
        }

        .bottom-row {
            padding: 22px 40px !important;
            padding-bottom: 35px !important;
            height: unset !important;

            .bottom-left,
            .bottom-right {
                width: 100% !important;
                text-align: center;
            }
        }
    }

    .sponser-carousel {
        margin-top: 30px !important;
        padding: 0px 10px !important;

        .slick-slider {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        div {
                            display: flex !important;
                            align-items: center !important;
                            justify-content: center !important;
                            height: 80px;

                            img {
                                width: 230px !important;
                                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                                transform: scale(1);
                            }

                            img:hover {
                                transform: scale(1.2);
                                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                            }
                        }
                    }
                }
            }

            .custom-prev-icon-ind-trusted {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                left: 50%;
                transform: translate(-150%, -50%);
                top: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-repeat: no-repeat;
                background-size: 8px;
                background-position-x: 9px;
                background-position-y: 6px;

                span {
                    background-size: contain;
                    width: 12px;
                    height: 14px;
                }
            }

            .custom-next-icon-ind-trusted {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                right: 50%;
                transform: translate(150%, -50%);
                top: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-repeat: no-repeat;
                background-size: 8px;
                background-position-x: 11px;
                background-position-y: 6px;

                span {
                    background-size: contain;
                    width: 12px;
                    height: 14px;
                }
            }
        }
    }

    .contactus-section {
        .section1 {
            .row {
                .col-lg-5 {
                    padding: 0 10px !important;

                    .carousel {
                        .carousel-inner {
                            .active.carousel-item {
                                .carousel-item1 {
                                    .info-div {
                                        margin-top: 115px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .col-lg-6 {
                    padding: 0 10px !important;
                    margin-top: 100px;

                    .request-to-demo {
                        width: 100% !important;
                        margin-right: 0 !important;

                        .contact-form {
                            padding: 30px 40px !important;

                            form {
                                .row {
                                    margin-top: 0px !important;

                                    .col-lg-6 {
                                        margin-top: 20px !important;
                                        padding: 0px !important;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }

        .section2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px !important;

            .center-box {
                width: 100% !important;
                transform: unset !important;
                left: unset !important;
                top: unset !important;
                position: relative !important;
                margin-top: 0px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px !important;

                .right-box {
                    display: none;

                }

                .left-box {
                    width: 100% !important;
                    border-radius: 6px !important;
                    padding: 20px !important;

                    .suggestion {
                        text-align: center !important;
                    }

                    .btn-box {
                        margin-top: 35px !important;
                        .btn-red:nth-child(1){
                            padding:6px !important;
                        }
                        button {
                            a {
                                padding: 13px 0px !important;
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            .office-carousel {
                figure {
                    min-width: 180px !important;
                }
            }
        }

        .section4 {
            .details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

        }
    }

    .about-section {
        margin: 70px 0px !important;
        padding: 0px 10px;

        .section1 {
            .info-div {
                flex-direction: column;

                .grey-box {
                    width: 100% !important;
                }

                .info-text {
                    width: 100% !important;
                    padding: 0px 10px;
                }
            }

            .info-div.reverse {
                flex-direction: column !important;

                .grey-box {
                    margin-left: 0px !important;
                }
            }
        }

        .section2 {
            margin-top: 70px !important;

            .company-div {
                .row {
                    .company {
                        min-width: 131px !important;
                        min-height: 42px !important;
                        background-size: contain !important;
                    }
                }

                .row-second {
                    margin-top: 50px !important;
                }
            }
        }
    }


    .ind-section1 {
        .sponsers {
            padding-left: 10px !important;
            padding-right: 10px !important;

            img {
                width: 130px !important;
            }
        }
    }

    .ind-section2 {
        .big-card {
            .next-box {
                min-width: unset !important;
                width: 80%;
            }
        }
    }

    .ind-section3 {
        padding: 40px 20px;

        .col-lg-6 {
            align-items: center;

            text-align: center;
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    .ind-section4 {     
        .midtitle {
            text-align: center!important;
            line-height: 28px !important;
        }
        .midsmalltext{
            padding: 0px 20px !important;
        }
    }


    .section-breadcrumb {
        margin-top: 40px !important;

        .breadcrumb {
            font-size: 16px !important;
            padding-left: 20px !important;
        }
    }

    .games-section {
        .carousel-section {
            margin-top: 140px !important;
            padding-left: 10px !important;
            padding-right: 10px !important;

            .carousel-item1 {
                padding: 30px 20px !important;
                height: unset !important;

                .left-side {
                    .source {
                        bottom: -15px !important;

                        .source-link {
                            font-size: 16px !important;
                        }
                    }
                }

                .right-side {
                    background-size: contain;
                }
            }
        }

        .card-section {
            .row {
                margin: unset !important;

                .col-lg-6 {
                    padding-left: 10px !important;
                    padding-right: 10px !important;
                    margin-bottom: 160px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 20px !important;

                        .rightside {
                            background-size: contain;
                            width: 140.39px !important;
                            right: -5px;
                            background-size: contain;
                        }

                        .leftside {
                            padding-bottom: 35px;
                            padding-top: 0px !important;

                            .heading {
                                font-size: 22px !important;
                            }

                            .para,
                            .para-black {
                                margin-top: 10px !important;
                            }

                            .btn-div {

                                .source-link {
                                    font-size: 16px !important;
                                }
                            }
                        }

                        .rummy-image {
                            width: 250px !important;
                        }

                        .rummy-leftside {
                            padding-top: 50px !important;
                        }
                    }
                }
            }

            .row:nth-child(1) {
                margin-top: 160px !important;
                margin-bottom: 0px !important;
            }

            .row:last-child {
                margin-bottom: 80px !important;

                .col-lg-12 {
                    padding: 0px 10px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 20px !important;


                        .leftside {
                            padding-bottom: 35px;
                            padding-top: 95px !important;
                            width: 100% !important;

                            .heading {
                                text-align: center;
                                font-size: 22px !important;
                            }

                            .para,
                            .para-black {
                                margin-top: 10px !important;
                            }

                            .btn-div {
                                .source-link {
                                    font-size: 16px !important;
                                }
                            }
                        }

                        .rightside.SportBettingLaptop {
                            background-size: contain;
                            width: 264.39px !important;
                            right: 20px !important;

                        }
                    }
                }
            }
        }
    }

    .gamepages-section {
        margin-top: 50px !important;

        .section1 {
            .breadcrumb {
                font-size: 16px !important;
                padding-left: 20px !important;
            }
        }

        .section2 {
            margin-top: 40px !important;

            .row {
                margin-bottom: 25px !important;

                .left-side {
                    padding: 0px 20px !important;
                }

                .right-side {
                    img {
                        height: unset !important;
                        margin-top: unset !important;
                        width: 100% !important;
                    }
                }
            }
        }

        .section3 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;
            margin-bottom: 50px !important;

            .heading {
                font-size: 30px !important;
            }

            .card-section {
                padding: 0px 15px !important;
                background-repeat: repeat-y !important;
            }

            .smalltext {
                padding: 0px 10px !important;
                text-align: center !important;
                margin-bottom: 30px !important;
            }

            .trivia-feature-section {
                justify-content: center;

                .row {
                    .col-lg-4 {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .section5 {
            margin-top: 50px !important;

            .mid-text {
                padding: 0px 15px !important;
            }
        }
    }

    .metaverse-section {
        .section1 {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }
        }

        .section2 {
            .midText {
                font-size: 26px !important;
                line-height: 30px !important;
            }

            .midSmallText {
                font-size: 16px !important;
                line-height: 26px !important;
                padding: 0px 10px;
            }

            .block-section {
                margin-top: 0px !important;

                .row {
                    padding: 0px 10px !important;

                    .card-style {
                        .card {
                            height: unset !important;
                        }
                    }

                    .card-style.box-style {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .section3 {
            min-height: 444px !important;
            padding-top: 50px !important;

            .black-heading {
                font-size: 24px !important;
            }

            .white-heading {
                font-size: 16px !important;
            }

            .center-box {
                margin-top: 328px !important;
            }
        }

        .section4 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section5 {
            padding: 0px 10px !important;

            .midText {
                font-size: 26px !important;
            }

            .midSmallText {
                font-size: 17px !important;
                line-height: 24px !important;
            }
        }
    }

    .blockchain-section {
        .section-breadcrumb {
            margin-top: 50px !important;

            .breadcrumb {
                font-size: 18px !important;
            }
        }

        .section1 {
            margin-top: 40px !important;

            .midText {
                font-size: 26px !important;
                line-height: 26px !important;
            }

            .midSmallText {
                font-size: 18px !important;
                padding: 0px 10px !important;
            }

            .block-section {
                padding: 10px 30px !important;

                .slick-slider {
                    .slick-list {
                        .slick-track {
                            height: 430px !important;
                            overflow-y: hidden;

                            .slick-slide {
                                height: 400px !important;
                                margin-top: 60px !important;

                                div {
                                    .item-box {
                                        height: 100% !important;

                                        .whitebox {
                                            height: 320px !important;
                                            padding: 50px 10px 10px 10px !important;

                                            .name {
                                                font-size: 18px !important;
                                                text-align: center !important;
                                                line-height: 20px !important;
                                            }

                                            .para-style {
                                                font-size: 15px !important;
                                                line-height: 20px !important;
                                            }
                                        }

                                        .redbox {
                                            width: 95px !important;
                                            height: 95px !important;
                                            top: -56px !important;
                                            left: unset !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            margin-top: 40px !important;
            padding: 40px 20px !important;

            .row {
                .card-side {
                    .row {
                        .card-details {
                            .card {
                                margin-left: 0px !important;
                                margin-bottom: 20px !important;
                                height: unset !important;
                            }
                        }
                    }
                }

                .para-side {
                    padding-left: 0px !important;
                    text-align: center !important;

                    .para-inner {
                        font-size: 24px !important;
                        line-height: 32px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;

            .midText {

                font-size: 24px !important;
                line-height: 30px !important;
                margin-bottom: 0px !important;
            }

            .list-items-section {
                .row {
                    .list-item-details {
                        padding-left: 0px !important;
                        padding-right: 0px !important;

                        .card {
                            height: unset !important;
                            margin-bottom: 20px !important;
                            padding: 20px 10px !important;

                            .upper-section {
                                font-size: 20px !important;
                                line-height: 22px !important;
                            }

                            .lower-section {
                                margin-top: 10px !important;
                                font-size: 16px !important;
                                line-height: 24px !important;
                            }
                        }
                    }
                }
            }
        }

        .section5 {
            margin-top: 0px !important;

            .heading {
                font-size: 28px !important;
                margin-bottom: 40px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }

        }
    }
    .fintech-section{
        .section1{
            padding: 15px 15px!important;
        }
        .section2{
            margin-top: 30px!important;
            .midText{
                font-size: 28px !important;
            }
        }
        .section3{
            .row:nth-child(1){
                padding: 25px 15px!important;
            }
            .row{
                margin-bottom: 0px !important;
                padding: 25px 15px!important;
                .right-side-fintech{
                    .heading{
                        text-align: center;
                    }
                    .para{
                        font-size: 18px !important;
                        padding-right: 0px !important;
                    }
                }
            }
            .row:nth-child(2){
                flex-direction: column-reverse;
            }
        }
        .section4{
            padding: 40px 10px!important;
            .text-section{
                margin-bottom: 50px!important;
                .upper-section{
                    text-align: left!important;
                    font-size: 20px!important;
                    .icon{
                        margin-right:15px !important;
                    }
                }
                .lower-section{
                    font-size: 17px!important;
                    line-height: 23px!important;
                }
            }
        }
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
    }
    .healthcare-section{
        .section1{
            margin-top: 0px !important;
            padding: 15px 15px!important;
            .row{
                margin-bottom: 0px!important;
                padding: 15px 0px!important;
                .right-side-fintech{
                    padding-left: 10px!important;
                    padding-right: 10px!important;
                    padding-top: 20px!important;
                    .heading{
                        font-size: 26px!important;
                        text-align: center!important;
                    }
                    .para{
                        font-size: 18px!important;
                        line-height: 24px !important;
                    }
                }

            }
        }
        .section2{
            margin-top: 30px!important;
            padding: 0px 15px!important;
            .row{
                .right-side-fintech{
                    padding-top: 20px;
                    .para{
                        font-size: 18px!important;
                        line-height: 24px !important;
                        text-align:justify !important;
                    }
                }
            }
        }
        
        .section3{
            padding: 40px 10px!important;
            .text-section{
                margin-bottom: 50px!important;
                .upper-section{
                    text-align: left!important;
                    font-size: 20px!important;
                    .icon{
                        margin-right:15px !important;
                    }
                }
                .lower-section{
                    font-size: 17px!important;
                    line-height: 23px!important;
                }
            }
        }
        .section4{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section5{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
    }
    .AIML-section{
        .section1{
            margin-top: 0px !important;
            padding: 15px 15px!important;
            .row{
                margin-bottom: 0px!important;
                padding: 15px 0px!important;

                .col-lg-7{
                    .right-section{
                        .heading{
                            text-align: center;
                            padding-top: 20px;
                            font-size: 26px !important;
                            line-height:36px!important;
                        }
                        .para{
                            font-size: 18px !important;
                            
                        }

                    }
                }

            }
        }
        .section2{
            margin-top: 0px!important;
            padding: 0px 15px!important;
            .midText{
                font-size: 26px!important;
                line-height: 28px!important;
            }
            .midSmallText{
                font-size: 18px!important;
                line-height: 28px!important;
            }
            .card-area{
                padding: 0px 0px!important;
                .row{
                    .card-area-inner{
                        padding: 10px !important;
                        margin-bottom: 20px;
                        .card{
                            padding: 20px!important;
                            height:unset !important;
                        }
                    }
                }
            }
        }
        
        .section3{
            padding: 30px 10px!important;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            .row{
                .left-side-aiml{
                    .pics-block {
                        .row{
                            img{
                                width: 100%!important;
                                height: 100%!important;
                                margin-bottom: 30px!important;
                            }
                            img:last-child{
                                margin-top: 0px !important;
                            }
                            .secpic{
                                margin-left: 0px!important;
                            }
                        }
                    }
                }
                .right-side-aiml{
                    margin-top: 20px!important;
                    padding: 0px 10px !important;
                    .heading{
                        text-align: center;
                        padding-top: 20px;
                        font-size: 26px !important;
                        line-height:36px!important;
                    }
                    .para{
                        font-size: 18px !important;
                        
                    }
                }
            }
        }
        .section4{
            display: none;
        }
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }

        }
    }
    .services-section{
        .section1{
            margin-bottom: 0px!important;
            flex-wrap: wrap;
            .red-box{
                height: 80px!important;
                width: 230px!important;
                margin-bottom: 20px!important;

            }
        }
        .service-card-container{
            padding: 40px 10px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    .service-para{
                        margin-top: 0px !important;
                    }
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                    .heading{
                        font-size: 26px !important;
                        line-height: 26px !important;
                    }
                }
                .col-lg-5{
                    .image-container{
                        height: 294px !important;
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
        .service-card-container.reverse{
            padding: 40px 10px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    padding-left: 10px !important;
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                }
                .col-lg-5{
                    .image-container{
                        height: 294px !important;
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
    }
    .productdev-section{
        margin-top: 40px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            margin: 40px 10px!important;
            .row{
                .col-lg-6{
                  
                    .text-div{
                        padding-top: 10px!important;
                        .heading{
                            font-size: 26px !important;
                            line-height: 26px !important;
                            margin-bottom: 20px !important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
        .section3{
            min-height: 375px!important;
            margin-bottom: 350px!important;
            .black-heading{
                font-size: 26px !important;
                text-align: center!important;
                line-height: 26px !important;
            }
            .white-heading{
                font-size: 18px!important;
            }
            .center-box{
                margin-top: 270px!important;
            }
        }
        .section4{
            margin: 182px 10px 0px 10px!important;
            .mid-title{
                font-size: 26px!important;
                text-align: center!important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;
    
                img {
                    width: 120px !important;
                }
            }
        }
        .section5{
            padding-top: 40px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 10px!important;
            .mid-title{
                font-size: 26px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                margin-bottom: 60px!important;
            }
            .mid-section{
                .row{
                    .col-lg-6{
                        .mid-box{
                            margin-bottom: 60px;
                            .left-side{
                                left: 50%!important;
                                transform: translate(-50%, -50%);
                                top: 84px!important;
                                width: 100px!important;
                                height: 100px!important;
                                .icon{
                                    height: 40px!important;
                                    width: 50px!important;
                                }
                            }
                            .right-side{
                                max-width: unset !important;
                                margin-left: 0px!important;
                                h2{
                                    font-size: 24px!important;
                                    text-align: center;
                                    margin-bottom: 50px!important;
                                    padding-left: 0px!important;
                                }
                                .round-div{
                                    padding: 81px 20px 32px 20px!important;
                                    border-radius: 12px 12px 12px 12px!important;
                                    font-size: 16px!important;
                                }
                            }
                        }
                    }
                }
                .row:nth-child(2){
                    margin-top: 0px!important;
                }
            }
        }
        .contact-section{
            margin-top: 0px !important;
        }
    }

    .thinking-design-section{
        margin-top: 0px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            margin: 40px 10px!important;
            .row{
                .col-lg-6{
                  
                    .text-div{
                        padding-top: 10px!important;
                        .heading{
                            font-size: 26px !important;
                            line-height: 26px !important;
                            margin-bottom: 20px !important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
        .section3{
           .row{
            min-height: unset !important;
            width: 100%;
            margin-left: 0px !important;
            margin-right: 0px !important;
            .left-side{
                width: 100%!important;
                img{
                    height: 255px !important;
                }
            }
            .right-side-outer{
                .right-side{
                    padding: 20px 0px 20px 0px!important;
                    .heading{
                        font-size: 26px !important;
                        text-align: center !important;
                    }
                    .smallText{
                        text-align: center!important;
                    }
                }
            }
          
           }
           .list-section{
            flex-direction: column;
            margin-top: 10px!important;
            padding: 0px !important;
        }
        }
        .section4{
            margin:0px 10px 0px 10px!important;
            .mid-title{
                font-size: 26px!important;
                text-align: center!important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;
    
                img {
                    width: 120px !important;
                }
            }
        }
        .section5{
            padding-top: 40px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 10px!important;
            .mid-title{
                font-size: 26px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                margin-bottom: 60px!important;
            }
            .mid-section{
               .slider-styles{
                .slick-list{
                    .slick-track{
                        .slick-slide{
                            margin: 0px !important;
                        }
                    }
                }
               }
            }
        }
        .contact-section{
            margin-top: 0px !important;
        }
    }
    .software-dev-section{
        margin-top: 0px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            padding-bottom: 0px !important;
        }
        .section2,.section4,.section6{
            margin-top: 0px!important;
            margin-bottom: 60px !important;
            flex-direction: column !important;
            .left-side{
                padding: 60px 10px 0px 10px!important;
                width: 100% !important;
            }
            .right-side{
                width: 100% !important;
                .content-section{
                    padding: 20px 10px 10px 10px !important;
                    .redHeading,.blackHeading{
                        font-size: 30px !important;
                        text-align: center !important;
                    }
                }
                
            }
        }
        .section3,.section5,.section7{
            .row{
                .left-side{
                    .content-box{
                        margin-bottom: 35px;
                    }
                }
            }
        }
        .red-carousel-section{
            .upper-div{
                .big-redText{
                    font-size: 28px!important;
                    line-height: 30px !important;
                    text-align: center!important;
                }
                .para{
                    font-size: 18px !important;
                    padding: 0px 10px !important;
                }
            }
        }
    }
    .product-growth-section{
        margin-top: 0px!important;
        .section1{
            margin-bottom: 40px !important;
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            padding: 0px 10px !important;
            padding-top: 40px !important;
            height: 530px;
            margin-bottom: 150px!important;
            .black-heading{
                font-size: 28px!important;
            }
            .center-box{
                margin-top: 435px!important;
            }
        }
        .section3{
            padding-top: 55px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 20px!important;
            .mid-title{
                font-size: 28px!important;
                text-align: center!important;
                line-height: 33px!important;
                margin-bottom: 30px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                line-height: 32px!important;
            }
            .mid-section{
                margin-top: 30px!important;
                .row{
                    .col-lg-5{
                        .mid-box{
                            font-size: 18px!important;
                        }
                    }
                }
            }
        }
        .section4 {
            margin: 30px 10px 0px 10px!important;
            .mid-title{
                font-size: 28px!important;
                line-height: 30px!important;
                text-align: center !important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }
        }
        .contact-section{
            margin-top: 30px !important;
        }

    }
    .research-and-innovative-section{
        margin-top: 0px!important;
        .section1{
            margin-bottom: 40px !important;
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section3{
            padding-top: 20px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 30px!important;
            .mid-title{
                display: block!important;
            }
            .mid-section{
                img{
                    width: 100% !important;
                }
            }
        }
    }
}


@include respond(phone) {
    .navbar-styles {
        padding-left: 15px !important;
        padding-right: 15px !important;

        .btn.btn-primary {
            display: none;
        }

        .navbar-toggler {
            background: white;
        }

        .navbar-brand {
            margin-right: 0px !important;

            img {
                width: 195px !important;
                height: 50px !important;
            }
        }

        .menu-icon {
            div {
                width: 26px !important;
                height: 20px !important;
            }
        }
    }

    .hero-container.container {
        margin-top: -80px !important;

        .hero-container-inner {
            .hero-section1 {
                width: 100%;

                .section-1-inner {
                    .hero-bigText {
                        text-align: center !important;
                        display: block!important;
                        width: 100%!important;
                    }

                    .hero-smallText {
                        line-height: 30px !important;
                        text-align: center !important;
                    }
                    .hero-button-red-to-grey{
                        padding: 17px 20px!important;
                    }
                }
            }
        }
    }

    .hero-container.container.hero-container-industries-bg {

        .hero-container-inner {
            .hero-section1 {
                width: 100%;

                .section-1-inner {
                    .hero-smallText {
                        text-align: center !important;
                    }
                }
            }
        }
    }

    .home-section2 {

        padding: 30px 10px !important;

        .section2-upper {
            padding: 0px !important;
            .card-section{
                background-repeat: repeat-y!important;
                .row{
                    .uneven-columns{
                        margin:10px 10px 0px !important;
                    }
                }
            }
            .row {
                .col-lg-6 {
                    .greybox {
                        padding: 25px 20px !important;
                    }

                    .projectbox {
                        .lower-projectbox {
                            .pb-left {
                                margin-right: 75px !important;
                            }
                        }
                    }
                }
            }
        }

        .section2-lower {
            margin-top: 70px !important;
            padding: 10px!important;
            .mid-title{
                font-size: 26px !important;
                text-align: center !important;
                line-height: 30px !important;
            }
            .sponsers {
                margin-top: 30px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
                
                img {
                    width: 120px !important;
                    margin: 10px !important;
                }
            }
        }

    }

    .home-section3 {
        margin-top: 0px !important;

        .mid-para {
            margin-bottom: 80px !important;
            padding: 0px 15px;
            font-size: 16px !important;
        }

        .overlap-div.rightside {

            justify-content: center;
        }

        .overlap-div {
            flex-direction: column;

            .image {
                top: 0px !important;
                position: relative !important;

                img {
                    width: 100% !important;
                }
            }

            .info-div {
                width: 100% !important;

                .red-heading {
                    padding: 10px !important;
                    text-align: center!important;
                }

                .grey-container {
                    padding: 20px 0px !important;
                    .list-items{
                        .item{
                            .icon{
                                margin-right: 16px !important;
                                &::before{
                                    height: 16px;
                                    width: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-section4 {
        display: none;
    }

    .home-section5 {
        margin-top: 60px !important;

        padding: 40px 30px 50px!important;
        .home-carousel {
            margin-top: 30px !important;
            padding: 20px !important;

            .carousel {
                .carousel-control-next {
                    display: none;
                }

                .carousel-control-prev {
                    display: none;
                }

                .carousel-inner {

                    .carousel-item {
                        .carousel-item1 {
                            .upper-side {}

                            .lower-side {
                                flex-direction: column;

                                .left-side {
                                    width: 100% !important;
                                    height: 400px !important;
                                }

                                .right-side {
                                    width: 100% !important;
                                    padding: 30px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .contact-section {
        margin-top: 110px !important;
        padding: 10px !important;

        .midtitle {
            text-align: center;
            font-size: 26px !important;
        }

        .midsmalltext {
            font-size: 18px !important;

        }

        .contact-box {
            margin: 20px !important;

            .row {
                .col-lg-8 {
                    .contact-form {
                        .contact-input {
                            padding: 20px 0px 20px 20px !important;
                        }

                        .textarea {
                            padding: 20px 0px 20px 20px !important;
                        }
                    }

                    .bottom-row {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .col-lg-6 {
                            width: 100% !important;

                            .contact-form-btn {
                                padding: 15px 40px !important;
                                font-size: 25px;
                            }
                        }
                    }
                }

                .col-lg-4 {
                    height: 380px;
                }
            }
        }
    }

    .footer-container {
        .top-row {
            padding: 30px 30px 60px !important;
            flex-direction: column;

            .socials {
                margin: 20px 0px !important;
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    margin-right: unset !important;

                    background: black;
                }
            }

            .column-2,
            .column-3,
            .column-4 {
                .heading {
                    margin-bottom: 20px !important;
                }
            }

            .column-2 {
                margin-bottom: 30px !important;
            }

            .column-3 {
                margin-bottom: 30px !important;
            }

            .column-4 {
                width: 100% !important;
                margin-top: 20px !important;

            }
        }

        .bottom-row {
            padding: 22px 40px !important;
            padding-bottom: 35px !important;
            height: unset !important;

            .bottom-left,
            .bottom-right {
                width: 100% !important;
                text-align: center;
            }
        }
    }

    .sponser-carousel {
        margin-top: 30px !important;
        padding: 0px 10px !important;

        .slick-slider {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        div {
                            display: flex !important;
                            align-items: center !important;
                            justify-content: center !important;
                            height: 80px;

                            img {
                                width: 230px !important;
                                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                                transform: scale(1);
                            }

                            img:hover {
                                transform: scale(1.2);
                                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                            }
                        }
                    }
                }
            }

            .custom-prev-icon-ind-trusted {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                left: 50%;
                transform: translate(-150%, -50%);
                top: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-repeat: no-repeat;
                background-size: 8px;
                background-position-x: 9px;
                background-position-y: 6px;

                span {
                    background-size: contain;
                    width: 12px;
                    height: 14px;
                }
            }

            .custom-next-icon-ind-trusted {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                right: 50%;
                transform: translate(150%, -50%);
                top: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                background-repeat: no-repeat;
                background-size: 8px;
                background-position-x: 11px;
                background-position-y: 6px;

                span {
                    background-size: contain;
                    width: 12px;
                    height: 14px;
                }
            }
        }
    }

    .contactus-section {
        .section1 {
            .row {
                .col-lg-5 {
                    padding: 0 10px !important;

                    .carousel {
                        .carousel-inner {
                            .active.carousel-item {
                                .carousel-item1 {
                                    .info-div {
                                        margin-top: 115px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .col-lg-6 {
                    padding: 0 10px !important;
                    margin-top: 100px;

                    .request-to-demo {
                        width: 100% !important;
                        margin-right: 0 !important;

                        .contact-form {
                            padding: 30px 40px !important;

                            form {
                                .row {
                                    margin-top: 0px !important;

                                    .col-lg-6 {
                                        margin-top: 20px !important;
                                        padding: 0px !important;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }

        .section2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px !important;

            .center-box {
                width: 100% !important;
                transform: unset !important;
                left: unset !important;
                top: unset !important;
                position: relative !important;
                margin-top: 0px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px !important;

                .right-box {
                    display: none;

                }

                .left-box {
                    width: 100% !important;
                    border-radius: 6px !important;
                    padding: 20px !important;

                    .suggestion {
                        text-align: center !important;
                    }

                    .btn-box {
                        margin-top: 35px !important;

                        .btn-red:nth-child(1){
                            padding:6px !important;
                        }
                        button {
                            a {
                                padding: 13px 0px !important;
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            .office-carousel {
                figure {
                    min-width: 180px !important;
                }
            }
        }

        .section4 {
            .details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

        }
    }

    .about-section {
        margin: 70px 0px !important;
        padding: 0px 10px;

        .section1 {
            .info-div {
                flex-direction: column;

                .grey-box {
                    width: 100% !important;
                }

                .info-text {
                    width: 100% !important;
                    padding: 0px 10px;
                }
            }

            .info-div.reverse {
                flex-direction: column !important;

                .grey-box {
                    margin-left: 0px !important;
                }
            }
        }

        .section2 {
            margin-top: 70px !important;

            .company-div {
                .row {
                    .company {
                        min-width: 131px !important;
                        min-height: 42px !important;
                        background-size: contain !important;
                    }
                }

                .row-second {
                    margin-top: 50px !important;
                }
            }
        }
    }


    .ind-section1 {
        .sponsers {
            padding-left: 10px !important;
            padding-right: 10px !important;

            img {
                width: 130px !important;
            }
        }
    }

    .ind-section2 {
        .big-card {
            .next-box {
                min-width: unset !important;
                width: 80%;
            }
        }
    }

    .ind-section3 {
        padding: 40px 20px;

        .col-lg-6 {
            align-items: center;

            text-align: center;
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    .ind-section4 {
        .midsmalltext {
            padding: 0px 14px !important;
        }
    }

    .section-breadcrumb {
        .breadcrumb {
            padding-left: 20px !important;
        }
    }

    .games-section {
        .carousel-section {
            padding-left: 10px !important;
            padding-right: 10px !important;

            .carousel-item1 {
                padding: 30px 35px !important;
                height: unset !important;

                .left-side {
                    .source {
                        bottom: -15px !important;

                        .source-link {
                            font-size: 16px !important;
                        }
                    }
                }

                .right-side {
                    background-size: contain;
                }
            }
        }

        .card-section {
            .row {
                margin: unset !important;

                .col-lg-6 {
                    padding-left: 10px !important;
                    padding-right: 10px !important;
                    margin-bottom: 200px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 35px !important;

                        .rightside {
                            background-size: contain;
                            width: 150.39px !important;
                            right: -5px;
                            background-size: contain;
                        }

                        .leftside {
                            padding-bottom: 35px;
                        }

                        .rummy-image {
                            width: 250px !important;
                        }
                    }
                }
            }

            .row:nth-child(1) {
                margin-top: 200px !important;
                margin-bottom: 0px !important;
            }

            .row:last-child {
                margin-bottom: 200px !important;

                .col-lg-12 {
                    padding: 0px 10px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 35px !important;


                        .leftside {
                            padding-bottom: 35px;
                            padding-top: 95px !important;
                            width: 100% !important;
                        }

                        .rightside.SportBettingLaptop {
                            background-size: contain;
                            width: 320.39px !important;
                            right: 20px !important;

                        }
                    }
                }
            }
        }
    }

    .gamepages-section {
        margin-top: 50px !important;

        .section1 {
            .breadcrumb {
                font-size: 16px !important;
                padding-left: 20px !important;
            }
        }

        .section2 {
            margin-top: 40px !important;

            .row {
                margin-bottom: 25px !important;

                .left-side {
                    padding: 0px 20px !important;
                }

                .right-side {
                    img {
                        height: unset !important;
                        margin-top: unset !important;
                        width: 100% !important;
                    }
                }
            }
        }

        .section3 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;
            margin-bottom: 50px !important;

            .heading {
                font-size: 30px !important;
            }

            .card-section {
                padding: 0px 15px !important;
                background-repeat: repeat-y !important;
            }

            .smalltext {
                padding: 0px 10px !important;
                text-align: center !important;
                margin-bottom: 30px !important;
            }

            .trivia-feature-section {
                justify-content: center;

                .row {
                    .col-lg-4 {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .section5 {
            margin-top: 50px !important;

            .mid-text {
                padding: 0px 15px !important;
            }
        }
    }

    .metaverse-section {
        .section1 {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 130px !important;
                }
            }
        }

        .section2 {
            .midText {
                font-size: 26px !important;
                line-height: 30px !important;
                padding: 0px 10px;
            }

            .midSmallText {
                font-size: 16px !important;
                line-height: 26px !important;
                padding: 0px 20px;
            }

            .block-section {
                margin-top: 0px !important;

                .row {
                    padding: 0px 10px !important;

                    .card-style {
                        .card {
                            height: unset !important;
                        }
                    }

                    .card-style.box-style {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .section3 {
            min-height: 444px !important;
            padding-top: 50px !important;

            .black-heading {
                font-size: 26px !important;
            }

            .white-heading {
                font-size: 20px !important;
            }

            .center-box {
                margin-top: 312px !important;
            }
        }

        .section4 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section5 {
            padding: 0px 10px !important;

            .midText {
                font-size: 26px !important;
            }

            .midSmallText {
                font-size: 17px !important;
                line-height: 24px !important;
            }
        }
    }
    .blockchain-section {
        .section-breadcrumb {
            margin-top: 50px !important;

            .breadcrumb {
                font-size: 18px !important;
            }
        }

        .section1 {
            margin-top: 40px !important;

            .midText {
                font-size: 26px !important;
                line-height: 26px !important;
            }

            .midSmallText {
                font-size: 18px !important;
                padding: 0px 10px !important;
            }

            .block-section {
                padding: 10px 30px !important;

                .slick-slider {
                    .slick-list {
                        .slick-track {
                            height: 430px !important;
                            overflow-y: hidden;

                            .slick-slide {
                                height: 400px !important;
                                margin-top: 60px !important;

                                div {
                                    .item-box {
                                        height: 100% !important;

                                        .whitebox {
                                            height: 320px !important;
                                            padding: 50px 10px 10px 10px !important;

                                            .name {
                                                font-size: 18px !important;
                                                text-align: center !important;
                                                line-height: 20px !important;
                                            }

                                            .para-style {
                                                font-size: 15px !important;
                                                line-height: 20px !important;
                                            }
                                        }

                                        .redbox {
                                            width: 95px !important;
                                            height: 95px !important;
                                            top: -56px !important;
                                            left: unset !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            margin-top: 40px !important;
            padding: 40px 20px !important;

            .row {
                .card-side {
                    .row {
                        .card-details {
                            .card {
                                margin-left: 0px !important;
                                margin-bottom: 20px !important;
                                height: unset !important;
                            }
                        }
                    }
                }

                .para-side {
                    padding-left: 0px !important;
                    text-align: center !important;

                    .para-inner {
                        font-size: 24px !important;
                        line-height: 32px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;

            .midText {

                font-size: 24px !important;
                line-height: 30px !important;
                margin-bottom: 0px !important;
            }

            .list-items-section {
                .row {
                    .list-item-details {
                        padding-left: 0px !important;
                        padding-right: 0px !important;

                        .card {
                            height: unset !important;
                            margin-bottom: 20px !important;
                            padding: 20px 10px !important;

                            .upper-section {
                                font-size: 20px !important;
                                line-height: 22px !important;
                            }

                            .lower-section {
                                margin-top: 10px !important;
                                font-size: 16px !important;
                                line-height: 24px !important;
                            }
                        }
                    }
                }
            }
        }

        .section5 {
            margin-top: 0px !important;

            .heading {
                font-size: 28px !important;
                margin-bottom: 40px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }

        }
    }
    .fintech-section{
        .section1{
            padding: 15px 15px!important;
        }
        .section2{
            margin-top: 30px!important;
            .midText{
                font-size: 28px !important;
            }
        }
        .section3{
            .row:nth-child(1){
                padding: 25px 15px!important;
            }
            .row{
                margin-bottom: 0px !important;
                padding: 25px 15px!important;
                .right-side-fintech{
                    .heading{
                        text-align: center;
                    }
                    .para{
                        font-size: 18px !important;
                        padding-right: 0px !important;
                    }
                }
            }
            .row:nth-child(2){
                flex-direction: column-reverse;
            }
        }
        .section4{
            padding: 40px 10px!important;
            .text-section{
                margin-bottom: 50px!important;
                .upper-section{
                    text-align: left!important;
                    font-size: 20px!important;
                    .icon{
                        margin-right:15px !important;
                    }
                }
                .lower-section{
                    font-size: 17px!important;
                    line-height: 23px!important;
                }
            }
        }
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
    }
    .healthcare-section{
        .section1{
            margin-top: 0px !important;
            padding: 15px 15px!important;
            .row{
                margin-bottom: 0px!important;
                padding: 15px 0px!important;
                .right-side-fintech{
                    padding-left: 10px!important;
                    padding-right: 10px!important;
                    padding-top: 20px!important;
                    .heading{
                        font-size: 26px!important;
                        text-align: center!important;
                    }
                    .para{
                        font-size: 18px!important;
                        line-height: 24px !important;
                    }
                }

            }
        }
        .section2{
            margin-top: 30px!important;
            padding: 0px 15px!important;
            .row{
                .right-side-fintech{
                    padding-top: 20px;
                    .para{
                        font-size: 18px!important;
                        line-height: 24px !important;
                        text-align:justify !important;
                    }
                }
            }
        }
        
        .section3{
            padding: 40px 10px!important;
            .text-section{
                margin-bottom: 50px!important;
                .upper-section{
                    text-align: left!important;
                    font-size: 20px!important;
                    .icon{
                        margin-right:15px !important;
                    }
                }
                .lower-section{
                    font-size: 17px!important;
                    line-height: 23px!important;
                }
            }
        }
        .section4{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section5{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
    }
    .AIML-section{
        .section1{
            margin-top: 0px !important;
            padding: 15px 15px!important;
            .row{
                margin-bottom: 0px!important;
                padding: 15px 0px!important;

                .col-lg-7{
                    .right-section{
                        .heading{
                            text-align: center;
                            padding-top: 20px;
                            font-size: 26px !important;
                            line-height:36px!important;
                        }
                        .para{
                            font-size: 18px !important;
                            
                        }

                    }
                }

            }
        }
        .section2{
            margin-top: 0px!important;
            padding: 0px 15px!important;
            .midText{
                font-size: 26px!important;
                line-height: 28px!important;
            }
            .midSmallText{
                font-size: 18px!important;
                line-height: 28px!important;
            }
            .card-area{
                padding: 0px 0px!important;
                .row{
                    .card-area-inner{
                        padding: 10px !important;
                        margin-bottom: 20px;
                        .card{
                            padding: 20px!important;
                            height:unset !important;
                        }
                    }
                }
            }
        }
        
        .section3{
            padding: 30px 10px!important;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            .row{
                .left-side-aiml{
                    .pics-block {
                        .row{
                            img{
                                width: 100%!important;
                                height: 100%!important;
                                margin-bottom: 30px!important;
                            }
                            img:last-child{
                                margin-top: 0px !important;
                            }
                            .secpic{
                                margin-left: 0px!important;
                            }
                        }
                    }
                }
                .right-side-aiml{
                    margin-top: 20px!important;
                    padding: 0px 10px !important;
                    .heading{
                        text-align: center;
                        padding-top: 20px;
                        font-size: 26px !important;
                        line-height:36px!important;
                    }
                    .para{
                        font-size: 18px !important;
                        
                    }
                }
            }
        }
        .section4{
            display: none;
        }
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }

        }
    }
    .services-section{
        .section1{
            margin-bottom: 0px!important;
            flex-wrap: wrap;
            .red-box{
                height: 80px!important;
                width: 230px!important;
                margin-bottom: 20px!important;

            }
        }
        .service-card-container{
            padding: 40px 10px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    .service-para{
                        margin-top: 0px !important;
                    }
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                    .heading{
                        font-size: 26px !important;
                        line-height: 26px !important;
                    }
                }
                .col-lg-5{
                    .image-container{
                        height: 294px !important;
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
        .service-card-container.reverse{
            padding: 40px 10px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    padding-left: 10px !important;
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                }
                .col-lg-5{
                    .image-container{
                        height: 294px !important;
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
    }

    .productdev-section{
        margin-top: 40px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            margin: 40px 10px!important;
            .row{
                .col-lg-6{
                   
                    .text-div{
                        padding-top: 10px!important;
                        .heading{
                            font-size: 26px !important;
                            line-height: 26px !important;
                            margin-bottom: 20px !important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
        .section3{
            min-height: 375px!important;
            margin-bottom: 350px!important;
            .black-heading{
                font-size: 26px !important;
                text-align: center!important;
                line-height: 26px !important;
            }
            .white-heading{
                font-size: 18px!important;
            }
            .center-box{
                margin-top: 270px!important;
            }
        }
        .section4{
            margin: 182px 10px 0px 10px!important;
            .mid-title{
                font-size: 26px!important;
                text-align: center!important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;
    
                img {
                    width: 120px !important;
                }
            }
        }
        .section5{
            padding-top: 40px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 10px!important;
            .mid-title{
                font-size: 26px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                margin-bottom: 60px!important;
            }
            .mid-section{
                .row{
                    .col-lg-6{
                        .mid-box{
                            margin-bottom: 60px;
                            .left-side{
                                left: 50%!important;
                                transform: translate(-50%, -50%);
                                top: 84px!important;
                                width: 100px!important;
                                height: 100px!important;
                                .icon{
                                    height: 40px!important;
                                    width: 50px!important;
                                }
                            }
                            .right-side{
                                max-width: unset !important;
                                margin-left: 0px!important;
                                h2{
                                    font-size: 24px!important;
                                    text-align: center;
                                    margin-bottom: 50px!important;
                                    padding-left: 0px!important;
                                }
                                .round-div{
                                    padding: 81px 20px 32px 20px!important;
                                    border-radius: 12px 12px 12px 12px!important;
                                    font-size: 16px!important;
                                }
                            }
                        }
                    }
                }
                .row:nth-child(2){
                    margin-top: 0px!important;
                }
            }
        }
        .contact-section{
            margin-top: 0px !important;
        }
    }
    .thinking-design-section{
        margin-top: 0px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            margin: 40px 10px!important;
            .row{
                .col-lg-6{
                  
                    .text-div{
                        padding-top: 10px!important;
                        .heading{
                            font-size: 26px !important;
                            line-height: 26px !important;
                            margin-bottom: 20px !important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
        .section3{
           .row{
            min-height: unset !important;
            width: 100%;
            margin-left: 0px !important;
            margin-right: 0px !important;
            .left-side{
                width: 100%!important;
                img{
                    height: 255px !important;
                }
            }
            .right-side-outer{
                .right-side{
                    padding: 20px 0px 20px 0px!important;
                    .heading{
                        font-size: 26px !important;
                        text-align: center !important;
                    }
                    .smallText{
                        text-align: center!important;
                    }
                }
            }
          
           }
           .list-section{
            flex-direction: column;
            margin-top: 10px!important;
            padding: 0px !important;
        }
        }
        .section4{
            margin:0px 10px 0px 10px!important;
            .mid-title{
                font-size: 26px!important;
                text-align: center!important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;
    
                img {
                    width: 120px !important;
                }
            }
        }
        .section5{
            padding-top: 40px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 10px!important;
            .mid-title{
                font-size: 26px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                margin-bottom: 60px!important;
            }
            .mid-section{
                .slider-styles{
                    .slick-list{
                        .slick-track{
                            .slick-slide{
                                margin: 0px 5px !important;
                            }
                        }
                    }
                }
            }
        }
        .contact-section{
            margin-top: 0px !important;
        }
    }
    .software-dev-section{
        margin-top: 0px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            padding-bottom: 0px !important;
        }
        .section2,.section4,.section6{
            margin-top: 0px!important;
            margin-bottom: 60px !important;
            flex-direction: column !important;
            .left-side{
                padding: 60px 10px 0px 10px!important;
                width: 100% !important;
            }
            .right-side{
                width: 100% !important;
                .content-section{
                    padding: 20px 10px 10px 10px !important;
                    .redHeading,.blackHeading{
                        font-size: 30px !important;
                        text-align: center !important;
                    }
                }
                
            }
        }
        .section3,.section5,.section7{
            .row{
                .left-side{
                    .content-box{
                        margin-bottom: 35px;
                    }
                }
            }
        }
        .red-carousel-section{
            .upper-div{
                .big-redText{
                    font-size: 28px!important;
                    line-height: 30px !important;
                    text-align: center!important;
                }
                .para{
                    font-size: 18px !important;
                    padding: 0px 10px !important;
                }
            }
        }
    }
    .product-growth-section{
        margin-top: 0px!important;
        .section1{
            margin-bottom: 40px !important;
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            padding: 0px 10px !important;
            padding-top: 40px !important;
            height: 450px;
            margin-bottom: 150px!important;
            .black-heading{
                font-size: 28px!important;
            }
            .center-box{
                margin-top: 360px!important;
            }
        }
        .section3{
            padding-top: 55px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 20px!important;
            .mid-title{
                font-size: 28px!important;
                text-align: center!important;
                line-height: 33px!important;
                margin-bottom: 30px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                line-height: 32px!important;
            }
            .mid-section{
                margin-top: 30px!important;
                .row{
                    .col-lg-5{
                        .mid-box{
                            font-size: 18px!important;
                        }
                    }
                }
            }
        }
        .section4 {
            margin: 30px 10px 0px 10px!important;
            .mid-title{
                font-size: 28px!important;
                line-height: 30px!important;
                text-align: center !important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }
        }
        .contact-section{
            margin-top: 30px !important;
        }

    }
    .research-and-innovative-section{
        margin-top: 0px!important;
        .section1{
            margin-bottom: 40px !important;
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section3{
            padding-top: 20px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 30px!important;
            .mid-title{
                display: block!important;
            }
            .mid-section{
                img{
                    width: 100% !important;
                }
            }
        }
    }
}


@include respond(smalltab) {
    
    .home-section2 {

        padding: 60px 10px !important;

        .section2-upper {
            padding: 0px !important;
            .card-section{
                background-repeat: repeat-y!important;
                .row{
                    .uneven-columns{
                        margin:10px 10px 0px !important;
                    }
                }
            }
            .row {
                .col-lg-6 {
                    .greybox {
                        padding: 25px 20px !important;
                    }

                    .projectbox {
                        .lower-projectbox {
                            .pb-left {
                                margin-right: 75px !important;
                            }
                        }
                    }
                }
            }
        }

        .section2-lower {
            margin-top: 70px !important;
            padding: 10px!important;
            .mid-title{
                font-size: 26px !important;
                text-align: center !important;
                line-height: 30px !important;
            }
            .sponsers {
                margin-top: 30px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
                
                img {
                    width: 120px !important;
                    margin: 10px !important;
                }
            }
        }

    }

    .home-section3 {
        margin-top: 0px !important;

        .mid-para {
            margin-bottom: 80px !important;
            padding: 0px 15px;
            font-size: 16px !important;
        }

        .overlap-div.rightside {
            margin-bottom: 30px !important;
        }

        .overlap-div {
            flex-direction: column;

            .image {
                display:none !important;
                img {
                    width: 100% !important;
                }
            }

            .info-div {
                width: 100% !important;

                .red-heading {
                    padding: 10px !important;
                    text-align: center!important;
                }

                .grey-container {
                    padding: 20px 0px !important;
                    .list-items{
                        .item{
                            .icon{
                                margin-right: 16px !important;
                                &::before{
                                    height: 16px;
                                    width: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-section4 {
        display: none;
    }

    .home-section5 {
        margin-top: 60px !important;

        padding: 40px 30px 50px!important;
        .home-carousel {
            margin-top: 30px !important;
            padding: 20px !important;

            .carousel {
                .carousel-control-next {
                    display: none;
                }

                .carousel-control-prev {
                    display: none;
                }

                .carousel-inner {

                    .carousel-item {
                        .carousel-item1 {
                            .upper-side {}

                            .lower-side {
                                flex-direction: column;

                                .left-side {
                                    width: 100% !important;
                                    height: 400px !important;
                                }

                                .right-side {
                                    width: 100% !important;
                                    padding: 30px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .contactus-section {
        .section1 {
            .row {
                .col-lg-5 {
                    padding: 0 10px !important;

                    .carousel {
                        .carousel-inner {
                            .active.carousel-item {
                                .carousel-item1 {
                                    .info-div {
                                        margin-top: 115px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .col-lg-6 {
                    padding: 0 10px !important;
                    margin-top: 100px;

                    .request-to-demo {
                        width: 100% !important;
                        margin-right: 0 !important;

                        .contact-form {
                            padding: 30px 40px !important;

                            form {
                                .row {
                                    margin-top: 0px !important;

                                    .col-lg-6 {
                                        margin-top: 20px !important;
                                        padding: 0px !important;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }

        .section2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px !important;

            .center-box {
                width: 100% !important;
                transform: unset !important;
                left: unset !important;
                top: unset !important;
                position: relative !important;
                margin-top: 0px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px !important;

                .right-box {
                    display: none;

                }

                .left-box {
                    width: 100% !important;
                    border-radius: 6px !important;
                    padding: 20px !important;

                    .suggestion {
                        text-align: center !important;
                    }

                    .btn-box {
                        margin-top: 35px !important;

                        button {
                            a {
                                padding: 13px 18px !important;
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            .office-carousel {
                figure {
                    min-width: 180px !important;
                }
            }
        }

        .section4 {
            .details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

        }
    }

    .footer-container {
        .top-row {
            padding: 30px 30px 60px !important;
            flex-direction: column;

            .socials {
                margin: 20px !important;
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    background: black;
                }
            }

            .column-2,
            .column-3,
            .column-4 {
                .heading {
                    margin-bottom: 20px !important;
                }
            }

            .column-2 {
                margin-bottom: 30px !important;
            }

            .column-3 {
                margin-bottom: 30px !important;
            }

            .column-4 {
                width: 100% !important;
                margin-top: 20px !important;

            }
        }

        .bottom-row {
            padding: 22px 40px !important;
            padding-bottom: 35px !important;
            height: unset !important;

            .bottom-left,
            .bottom-right {
                width: 100% !important;
                text-align: center;
            }
        }
    }

    .about-section {
        margin: 70px 0px !important;
        padding: 0px 10px;

        .section1 {
            .info-div {
                flex-direction: column;

                .grey-box {
                    width: 100% !important;
                }

                .info-text {
                    width: 100% !important;
                    padding: 0px 10px;
                }
            }

            .info-div.reverse {
                flex-direction: column !important;

                .grey-box {
                    margin-left: 0px !important;
                }
            }
        }

        .section2 {
            margin-top: 70px !important;

            .company-div {
                .row {
                    .company {
                        min-width: 131px !important;
                        min-height: 42px !important;
                        background-size: contain !important;
                    }
                }

                .row-second {
                    margin-top: 50px !important;
                }
            }
        }
    }

    .ind-section1 {
        .sponsers {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

    .ind-section2 {
        .big-card {
            .next-box {
                min-width: unset !important;
                width: 80%;
            }
        }
    }

    .ind-section3 {
        padding: 40px 20px;

        .col-lg-6 {
            align-items: center;

            text-align: center;
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    .ind-section4 {
        .midsmalltext {
            padding: 0px 14px !important;
        }
    }

    .section-breadcrumb {
        .breadcrumb {
            padding-left: 20px !important;
        }
    }

    .games-section {
        .carousel-section {
            padding-left: 10px !important;
            padding-right: 10px !important;

            .carousel-item1 {
                padding: 30px 35px !important;
                height: unset !important;

                .left-side {
                    .source {
                        bottom: -15px !important;

                        .source-link {
                            font-size: 16px !important;
                        }
                    }
                }
            }
        }

        .card-section {
            .row {
                margin: unset !important;

                .col-lg-6 {
                    padding-left: 10px !important;
                    padding-right: 10px !important;
                    margin-bottom: 200px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 35px !important;

                        .rightside {
                            background-size: contain;
                            width: 170.39px !important;
                            right: -5px;
                            background-size: contain;
                        }

                        .leftside {
                            padding-bottom: 35px;
                        }

                        .rummy-image {
                            width: 250px !important;
                        }
                    }
                }
            }

            .row:nth-child(1) {
                margin-top: 200px !important;
                margin-bottom: 0px !important;
            }

            .row:last-child {
                margin-bottom: 200px !important;

                .col-lg-12 {
                    padding: 0px 10px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 35px !important;


                        .leftside {
                            padding-bottom: 35px;
                            padding-top: 95px !important;
                            width: 100% !important;
                        }

                        .rightside.SportBettingLaptop {
                            background-size: contain;
                            width: 320.39px !important;
                            right: 20px !important;

                        }
                    }
                }
            }
        }
    }

    .gamepages-section {
        margin-top: 50px !important;

        .section1 {
            .breadcrumb {
                font-size: 16px !important;
                padding-left: 20px !important;
            }
        }

        .section2 {
            margin-top: 40px !important;

            .row {
                margin-bottom: 25px !important;

                .left-side {
                    padding: 0px 20px !important;
                }

                .right-side {
                    img {
                        height: unset !important;
                        margin-top: unset !important;
                        width: 100% !important;
                    }
                }
            }
        }

        .section3 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;
            margin-bottom: 50px !important;

            .heading {
                font-size: 30px !important;
            }

            .card-section {
                padding: 0px 15px !important;
                background-repeat: repeat-y !important;
            }

            .smalltext {
                padding: 0px 40px !important;
                text-align: center !important;
                margin-bottom: 30px !important;
            }

            .trivia-feature-section {
                justify-content: center;

                .row {
                    .col-lg-4 {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .section5 {
            margin-top: 50px !important;

            .mid-text {
                padding: 0px 15px !important;
            }
        }
    }

    .metaverse-section {
        .section1 {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 150px !important;
                }
            }
        }

        .section2 {
            .midText {
                font-size: 26px !important;
                line-height: 30px !important;
                padding: 0px 10px;
            }

            .midSmallText {
                font-size: 16px !important;
                line-height: 26px !important;
                padding: 0px 20px;
            }

            .block-section {
                margin-top: 0px !important;

                .row {
                    padding: 0px 10px !important;

                    .card-style {
                        .card {
                            height: unset !important;
                        }
                    }

                    .card-style.box-style {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .section3 {
            min-height: 444px !important;
            padding-top: 50px !important;

            .black-heading {
                font-size: 26px !important;
            }

            .white-heading {
                font-size: 20px !important;
            }

            .center-box {
                margin-top: 312px !important;
            }
        }

        .section4 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section5 {
            padding: 0px 10px !important;

            .midText {
                font-size: 26px !important;
            }

            .midSmallText {
                font-size: 17px !important;
                line-height: 24px !important;
            }
        }
    }
    .blockchain-section {
        .section-breadcrumb {
            margin-top: 50px !important;

            .breadcrumb {
                font-size: 18px !important;
            }
        }

        .section1 {
            margin-top: 40px !important;

            .midText {
                font-size: 26px !important;
                line-height: 26px !important;
            }

            .midSmallText {
                font-size: 18px !important;
                padding: 0px 10px !important;
            }

            .block-section {
                padding: 10px 30px !important;

                .slick-slider {
                    .slick-list {
                        .slick-track {
                            height: 430px !important;
                            overflow-y: hidden;

                            .slick-slide {
                                height: 400px !important;
                                margin-top: 60px !important;

                                div {
                                    .item-box {
                                        height: 100% !important;

                                        .whitebox {
                                            height: 320px !important;
                                            padding: 50px 10px 10px 10px !important;

                                            .name {
                                                font-size: 18px !important;
                                                text-align: center !important;
                                                line-height: 20px !important;
                                            }

                                            .para-style {
                                                font-size: 15px !important;
                                                line-height: 20px !important;
                                            }
                                        }

                                        .redbox {
                                            width: 95px !important;
                                            height: 95px !important;
                                            top: -56px !important;
                                            left: unset !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            margin-top: 40px !important;
            padding: 40px 20px !important;

            .row {
                .card-side {
                    .row {
                        .card-details {
                            .card {
                                margin-left: 0px !important;
                                margin-bottom: 20px !important;
                                height: unset !important;
                            }
                        }
                    }
                }

                .para-side {
                    padding-left: 0px !important;
                    text-align: center !important;

                    .para-inner {
                        font-size: 24px !important;
                        line-height: 32px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;

            .midText {

                font-size: 24px !important;
                line-height: 30px !important;
                margin-bottom: 0px !important;
            }

            .list-items-section {
                .row {
                    .list-item-details {
                        padding-left: 0px !important;
                        padding-right: 0px !important;

                        .card {
                            height: unset !important;
                            margin-bottom: 20px !important;
                            padding: 20px 10px !important;

                            .upper-section {
                                font-size: 20px !important;
                                line-height: 22px !important;
                            }

                            .lower-section {
                                margin-top: 10px !important;
                                font-size: 16px !important;
                                line-height: 24px !important;
                            }
                        }
                    }
                }
            }
        }

        .section5 {
            margin-top: 0px !important;

            .heading {
                font-size: 28px !important;
                margin-bottom: 40px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }

        }
    }
    .fintech-section{
       
        .section2{
            margin-top: 30px!important;
            padding: 0px 20px !important;
            .midText{
                font-size: 28px !important;
            }
        }
        .section3{
            .row:nth-child(1){
                padding: 25px 15px!important;
            }
            .row{
                margin-bottom: 0px !important;
                padding: 25px 15px!important;
                .right-side-fintech{
                    .heading{
                        text-align: center;
                    }
                    .para{
                        font-size: 18px !important;
                        padding-right: 0px !important;
                    }
                }
                .left-side-fintech{
                    display: flex;
                    align-items: center;
                    justify-content:center;
                    img{
                        max-width: 300px !important;
                    }
                }
            }
            .row:nth-child(2){
                flex-direction: column-reverse;
            }
        }
        
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
    }
    .healthcare-section{
       
        .section1{
            margin-top: 30px!important;
            padding: 0px 20px !important;
            .row{
                margin-bottom: 0px!important;
                .right-side-fintech{
                    padding-top: 20px !important;
                    .heading{
                        text-align: center;
                    }
                    .para{
                        font-size: 18px !important;
                        padding-right: 0px !important;
                    }
                }
                .left-side-fintech{
                    display: flex;
                    align-items: center;
                    justify-content:center;
                    img{
                        max-width: 300px !important;
                    }
                }
            }
        }
        .section2{
            margin-top: 0px!important;
            padding: 0px 20px !important;
            .row:nth-child(1){
                padding: 25px 15px!important;
            }
            .row{
                margin-bottom: 0px !important;
                padding: 25px 15px!important;
                .right-side-fintech{
                    padding-top: 20px !important;
                    .heading{
                        text-align: center;
                    }
                    .para{
                        font-size: 18px !important;
                        padding-right: 0px !important;
                        text-align: justify;
                    }
                }
                .left-side-fintech{
                    display: flex;
                    align-items: center;
                    justify-content:center;
                    img{
                        max-width: 300px !important;
                    }
                }
            }
            .row:nth-child(2){
                flex-direction: column-reverse;
            }
        }
        
        .section4{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section5{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
    }
    .AIML-section{
        .section1{
            margin-top: 0px !important;
            padding: 15px 25px!important;
            .row{
                margin-bottom: 0px!important;
                padding: 15px 0px!important;

                .col-lg-7{
                    .right-section{
                        .heading{
                            text-align: center;
                            padding-top: 20px;
                            font-size: 26px !important;
                            line-height:36px!important;
                        }
                        .para{
                            font-size: 18px !important;
                            
                        }

                    }
                }

            }
        }
        .section2{
            margin-top: 0px!important;
            padding: 0px 25px!important;
            .midText{
                font-size: 26px!important;
                line-height: 28px!important;
            }
            .midSmallText{
                font-size: 18px!important;
                line-height: 28px!important;
            }
            .card-area{
                padding: 0px 0px!important;
                .row{
                    .card-area-inner{
                        padding: 10px !important;
                        margin-bottom: 20px;
                        .card{
                            padding: 20px!important;
                            height:unset !important;
                        }
                    }
                }
            }
        }
        
        .section3{
            padding: 30px 10px!important;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            .row{
                
                .right-side-aiml{
                    margin-top: 20px!important;
                    padding: 0px 20px !important;
                    .heading{
                        text-align: center;
                        padding-top: 20px;
                        font-size: 26px !important;
                        line-height:36px!important;
                    }
                    .para{
                        font-size: 18px !important;
                        
                    }
                }
            }
        }
        .section4{
            display: none;
        }
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 150px !important;
                }
            }

        }
    }
    .services-section{
        .section1{
            margin-bottom: 0px!important;
            flex-wrap: wrap;
            .red-box{
                height: 80px!important;
                width: 230px!important;
                margin-bottom: 20px!important;

            }
        }
        .service-card-container{
            padding: 40px 20px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    .service-para{
                        margin-top: 0px !important;
                    }
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                    .heading{
                        font-size: 26px !important;
                        line-height: 26px !important;
                    }
                }
                .col-lg-5{
                    .image-container{
                        height: 294px !important;
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
        .service-card-container.reverse{
            padding: 40px 20px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    padding-left: 10px !important;
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                }
                .col-lg-5{
                    .image-container{
                        height: 294px !important;
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
    }

    .contact-section {
        margin-top: 110px !important;
        padding: 10px !important;

        .midtitle {
            text-align: center;
            font-size: 26px !important;
        }

        .midsmalltext {
            font-size: 18px !important;

        }

        .contact-box {
            margin: 20px !important;

            .row {
                .col-lg-8 {
                    .contact-form {
                        .contact-input {
                            padding: 20px 0px 20px 20px !important;
                        }

                        .textarea {
                            padding: 20px 0px 20px 20px !important;
                        }
                    }

                    .bottom-row {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .col-lg-6 {
                            width: 100% !important;

                            .contact-form-btn {
                                padding: 15px 40px !important;
                                font-size: 25px;
                            }
                        }
                    }
                }

                .col-lg-4 {
                    height: 380px;
                }
            }
        }
    }
    .productdev-section{
        margin-top: 40px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            margin: 40px 10px!important;
            .row{
                .col-lg-6{
                   
                    .text-div{
                        padding-top: 10px!important;
                        .heading{
                            font-size: 26px !important;
                            line-height: 26px !important;
                            margin-bottom: 20px !important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
        .section3{
            min-height: 345px!important;
            margin-bottom: 350px!important;
            .black-heading{
                font-size: 26px !important;
                text-align: center!important;
                line-height: 26px !important;
            }
            .white-heading{
                font-size: 18px!important;
            }
            .center-box{
                margin-top: 230px!important;
            }
        }
        .section4{
            margin: 182px 10px 0px 10px!important;
            .mid-title{
                font-size: 26px!important;
                text-align: center!important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;
    
                img {
                    width: 150px !important;
                }
            }
        }
        .section5{
            padding-top: 40px!important;
            padding-left: 20px!important;
            padding-right: 20px!important;
            padding-bottom: 10px!important;
            .mid-title{
                font-size: 26px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                margin-bottom: 60px!important;
            }
            .mid-section{
                .row{
                    .col-lg-6{
                        .mid-box{
                            margin-bottom: 60px;
                            .left-side{
                                left: 50%!important;
                                transform: translate(-50%, -50%);
                                top: 84px!important;
                                width: 100px!important;
                                height: 100px!important;
                                .icon{
                                    height: 40px!important;
                                    width: 50px!important;
                                }
                            }
                            .right-side{
                                max-width: unset !important;
                                margin-left: 0px!important;
                                h2{
                                    font-size: 24px!important;
                                    text-align: center;
                                    margin-bottom: 50px!important;
                                    padding-left: 0px!important;
                                }
                                .round-div{
                                    padding: 81px 20px 32px 20px!important;
                                    border-radius: 12px 12px 12px 12px!important;
                                    font-size: 16px!important;
                                }
                            }
                        }
                    }
                }
                .row:nth-child(2){
                    margin-top: 0px!important;
                }
            }
          
        }
        .contact-section{
            margin-top: 0px !important;
        }
    }
    .thinking-design-section{
        margin-top: 0px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            margin: 40px 10px!important;
            .row{
                .col-lg-6{
                  
                    .text-div{
                        padding-top: 10px!important;
                        .heading{
                            font-size: 26px !important;
                            line-height: 26px !important;
                            margin-bottom: 20px !important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
        .section3{
           .row{
            min-height: unset !important;
            width: 100%;
            margin-left: 0px !important;
            margin-right: 0px !important;
            .left-side{
                
                width: 100%!important;
                padding: 0px 60px;
                padding-bottom: 30px;
                img{
                    height: 255px !important;
                }
            }
            .right-side-outer{
                .right-side{
                    padding: 20px 0px 20px 0px!important;
                    .heading{
                        font-size: 26px !important;
                        text-align: center !important;
                    }
                    .smallText{
                        text-align: center!important;
                    }
                }
            }
          
           }
           .list-section{
            flex-direction: column;
            margin-top: 10px!important;
            padding: 0px !important;
        }
        }
        .section4{
            margin:0px 10px 0px 10px!important;
            .mid-title{
                font-size: 26px!important;
                text-align: center!important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;
    
                img {
                    width: 120px !important;
                }
            }
        }
        .section5{
            padding-top: 40px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 10px!important;
            .mid-title{
                font-size: 26px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                margin-bottom: 60px!important;
            }
            .mid-section{
                .row{
                    .col-lg-6{
                        .mid-box{
                            margin-bottom: 60px;
                            .left-side{
                                left: 50%!important;
                                transform: translate(-50%, -50%);
                                top: 84px!important;
                                width: 100px!important;
                                height: 100px!important;
                                .icon{
                                    height: 40px!important;
                                    width: 50px!important;
                                }
                            }
                            .right-side{
                                max-width: unset !important;
                                margin-left: 0px!important;
                                h2{
                                    font-size: 24px!important;
                                    text-align: center;
                                    margin-bottom: 50px!important;
                                    padding-left: 0px!important;
                                }
                                .round-div{
                                    padding: 81px 20px 32px 20px!important;
                                    border-radius: 12px 12px 12px 12px!important;
                                    font-size: 16px!important;
                                }
                            }
                        }
                    }
                }
                .row:nth-child(2){
                    margin-top: 0px!important;
                }
            }
        }
        .contact-section{
            margin-top: 0px !important;
        }
    }
    .software-dev-section{
        margin-top: 0px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            padding-bottom: 0px !important;
        }
        .section2,.section4,.section6{
            margin-top: 0px!important;
            margin-bottom: 60px !important;
            flex-direction: column !important;
            .left-side{
                padding: 60px 10px 0px 10px!important;
                width: 100% !important;
            }
            .right-side{
                width: 100% !important;
                .content-section{
                    padding: 20px 10px 10px 10px !important;
                    .redHeading,.blackHeading{
                        font-size: 30px !important;
                        text-align: center !important;
                    }

                }
                
            }
        }
        .section3,.section5,.section7{
            .row{
                .left-side{
                    .content-box{
                        margin-bottom: 35px;
                    }
                }
            }
        }
        .red-carousel-section{
            .upper-div{
                .big-redText{
                    font-size: 28px!important;
                    line-height: 30px !important;
                    text-align: center!important;
                }
                .para{
                    font-size: 18px !important;
                    padding: 0px 10px !important;
                }
            }
        }
    }
    .product-growth-section{
        margin-top: 0px!important;
        .section1{
            margin-bottom: 40px !important;
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            padding: 0px 10px !important;
            padding-top: 40px !important;
            height: 450px;
            margin-bottom: 150px!important;
            .black-heading{
                font-size: 28px!important;
            }
            .center-box{
                margin-top: 360px!important;
            }
        }
        .section3{
            padding-top: 55px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 20px!important;
            .mid-title{
                font-size: 28px!important;
                text-align: center!important;
                line-height: 33px!important;
                margin-bottom: 30px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                line-height: 32px!important;
            }
            .mid-section{
                margin-top: 30px!important;
                .row{
                    .col-lg-5{
                        .mid-box{
                            font-size: 18px!important;
                        }
                    }
                }
            }
        }
        .section4 {
            margin: 30px 10px 0px 10px!important;
            .mid-title{
                font-size: 28px!important;
                line-height: 30px!important;
                text-align: center !important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }
        }
        .contact-section{
            margin-top: 30px !important;
        }

    }
    .research-and-innovative-section{
        margin-top: 0px!important;
        .section1{
            margin-bottom: 40px !important;
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section3{
            padding-top: 20px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 30px!important;
            .mid-title{
                display: block!important;
            }
            .mid-section{
                img{
                    width: 100% !important;
                }
            }
        }
    }
}

@include respond(tab-port) {
    
    .home-section2 {

        padding: 60px 10px !important;

        .section2-upper {
            padding: 0px !important;
            .card-section{
                background-repeat: repeat-y!important;
                .row{
                    .uneven-columns{
                        margin:10px 10px 0px !important;
                    }
                }
            }
            .row {
                .col-lg-6 {
                    .greybox {
                        padding: 25px 20px !important;
                    }

                    .projectbox {
                        .lower-projectbox {
                            .pb-left {
                                margin-right: 75px !important;
                            }
                        }
                    }
                }
            }
        }

        .section2-lower {
            margin-top: 70px !important;
            padding: 10px!important;
            .mid-title{
                font-size: 26px !important;
                text-align: center !important;
                line-height: 30px !important;
            }
            .sponsers {
                margin-top: 30px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
                
                img {
                    width: 120px !important;
                    margin: 10px !important;
                }
            }
        }

    }

    .home-section3 {
        margin-top: 0px !important;

        .mid-para {
            margin-bottom: 80px !important;
            padding: 0px 15px;
            font-size: 16px !important;
        }

        .overlap-div.rightside {
            margin-bottom: 30px !important;
        }

        .overlap-div {
            flex-direction: column;

            .image {

                display: none !important;
                img {
                    width: 100% !important;
                }
            }

            .info-div {
                width: 100% !important;

                .red-heading {
                    padding: 10px !important;
                    text-align: center!important;
                }

                .grey-container {
                    padding: 20px 0px !important;
                    .list-items{
                        .item{
                            .icon{
                                margin-right: 16px !important;
                                &::before{
                                    height: 16px;
                                    width: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-section4 {
        display: none;
    }

    .home-section5 {
        margin-top: 60px !important;

        padding: 40px 30px 50px!important;
        .home-carousel {
            margin-top: 30px !important;
            padding: 20px !important;

            .carousel {
                .carousel-control-next {
                    display: none;
                }

                .carousel-control-prev {
                    display: none;
                }

                .carousel-inner {

                    .carousel-item {
                        .carousel-item1 {
                            .upper-side {}

                            .lower-side {
                                flex-direction: column;

                                .left-side {
                                    width: 100% !important;
                                    height: 400px !important;
                                }

                                .right-side {
                                    width: 100% !important;
                                    padding: 30px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .contactus-section {
        .section1 {
            .row {
                .col-lg-5 {
                    padding: 0 10px !important;

                    .carousel {
                        .carousel-inner {
                            .active.carousel-item {
                                .carousel-item1 {
                                    .info-div {
                                        margin-top: 115px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .col-lg-6 {
                    padding: 0 10px !important;
                    margin-top: 100px;

                    .request-to-demo {
                        width: 100% !important;
                        margin-right: 0 !important;

                        .contact-form {
                            padding: 30px 40px !important;

                            form {
                                .row {
                                    margin-top: 0px !important;

                                    .col-lg-6 {
                                        margin-top: 20px !important;
                                        padding: 0px !important;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }

        .section2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px !important;

            .center-box {
                width: 100% !important;
                transform: unset !important;
                left: unset !important;
                top: unset !important;
                position: relative !important;
                margin-top: 0px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px !important;

                .right-box {
                    display: none;

                }

                .left-box {
                    width: 100% !important;
                    border-radius: 6px !important;
                    padding: 20px !important;

                    .suggestion {
                        text-align: center !important;
                    }

                    .btn-box {
                        margin-top: 35px !important;

                        button {
                            a {
                                padding: 13px 18px !important;
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            .office-carousel {
                figure {
                    min-width: 180px !important;
                }
            }
        }

        .section4 {
            .details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

        }
    }

    .footer-container {
        .top-row {
            padding: 30px 30px 60px !important;
            flex-direction: column;

            .socials {
                margin: 20px !important;
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    background: black;
                }
            }

            .column-2,
            .column-3,
            .column-4 {
                .heading {
                    margin-bottom: 20px !important;
                }
            }

            .column-2 {
                margin-bottom: 30px !important;
            }

            .column-3 {
                margin-bottom: 30px !important;
            }

            .column-4 {
                width: 100% !important;
                margin-top: 20px !important;

            }
        }

        .bottom-row {
            padding: 22px 40px !important;
            padding-bottom: 35px !important;
            height: unset !important;

            .bottom-left,
            .bottom-right {
                width: 100% !important;
                text-align: center;
            }
        }
    }

    .about-section {
        margin: 70px 0px !important;
        padding: 0px 10px;

        .section1 {
            .info-div {
                flex-direction: column;

                .grey-box {
                    width: 100% !important;
                }

                .info-text {
                    width: 100% !important;
                    padding: 0px 10px;
                }
            }

            .info-div.reverse {
                flex-direction: column !important;

                .grey-box {
                    margin-left: 0px !important;
                }
            }
        }

        .section2 {
            margin-top: 70px !important;

            .company-div {
                .row {
                    .company {
                        min-width: 131px !important;
                        min-height: 42px !important;
                        background-size: contain !important;
                    }
                }

                .row-second {
                    margin-top: 50px !important;
                }
            }
        }
    }

    .ind-section1 {
        .sponsers {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

    .ind-section2 {
        .big-card {
            .next-box {
                min-width: unset !important;
                width: 80%;
            }
        }
    }

    .ind-section3 {
        padding: 40px 20px;

        .col-lg-6 {
            align-items: center;

            text-align: center;
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    .ind-section4 {
        .midsmalltext {
            padding: 0px 14px !important;
        }
    }

    .section-breadcrumb {
        .breadcrumb {
            padding-left: 20px !important;
        }
    }

    .games-section {
        .carousel-section {
            padding-left: 30px !important;
            padding-right: 30px !important;

            .carousel-item1 {
                padding: 30px 35px !important;
                height: unset !important;

                .left-side {
                    .source {
                        bottom: -15px !important;

                        .source-link {
                            font-size: 16px !important;
                        }
                    }
                }
            }
        }

        .card-section {
            .row {
                margin: unset !important;

                .col-lg-6 {
                    padding-left: 30px !important;
                    padding-right: 30px !important;

                    margin-bottom: 200px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 35px !important;

                        .rightside {
                            background-size: contain;
                            width: 200.39px !important;
                            right: 50px;
                            background-size: contain;
                        }

                        .leftside {
                            padding-bottom: 35px;
                        }

                        .rummy-image {
                            width: 300px !important;
                            right: 0 !important;
                        }
                    }
                }
            }

            .row:nth-child(1) {
                margin-top: 200px !important;
                margin-bottom: 0px !important;
            }

            .row:last-child {
                margin-bottom: 200px !important;

                .col-lg-12 {
                    padding: 0px 10px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 35px !important;


                        .leftside {
                            padding-bottom: 35px;
                            padding-top: 95px !important;
                            width: 100% !important;
                        }

                        .rightside.SportBettingLaptop {
                            background-size: contain;
                            width: 320.39px !important;
                            right: 20px !important;

                        }
                    }
                }
            }
        }
    }

    .gamepages-section {
        margin-top: 50px !important;

        .section1 {
            .breadcrumb {
                font-size: 16px !important;
                padding-left: 20px !important;
            }
        }

        .section2 {
            margin-top: 40px !important;

            .row {
                margin-bottom: 25px !important;

                .left-side {
                    padding: 0px 20px !important;
                }

                .right-side {
                    img {
                        height: 100% !important;
                        margin-top: unset !important;
                        width: 100% !important;
                        max-height: 500px !important;
                    }
                }
            }
        }

        .section3 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;
            margin-bottom: 50px !important;

            .heading {
                font-size: 30px !important;
            }

            .card-section {
                padding: 0px 15px !important;
                background-repeat: repeat-y !important;
            }

            .smalltext {
                padding: 0px 40px !important;
                text-align: center !important;
                margin-bottom: 30px !important;
            }

            .trivia-feature-section {
                justify-content: center;

                .row {
                    .col-lg-4 {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .section5 {
            margin-top: 50px !important;

            .mid-text {
                padding: 0px 15px !important;
            }
        }
    }

    .metaverse-section {
        .section1 {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 170px !important;
                }
            }
        }

        .section2 {
            .midText {
                font-size: 26px !important;
                line-height: 30px !important;
                padding: 0px 10px;
            }

            .midSmallText {
                font-size: 16px !important;
                line-height: 26px !important;
                padding: 0px 20px;
            }

            .block-section {
                margin-top: 0px !important;

                .row {
                    padding: 0px 10px !important;

                    .card-style {
                        .card {
                            height: unset !important;
                        }
                    }

                    .card-style.box-style {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .section3 {
            min-height: 345px !important;
            padding-top: 50px !important;

            .black-heading {
                font-size: 26px !important;
            }

            .white-heading {
                font-size: 20px !important;
            }

            .center-box {
                margin-top: 230px !important;
            }
        }

        .section4 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section5 {
            padding: 0px 10px !important;

            .midText {
                font-size: 26px !important;
            }

            .midSmallText {
                font-size: 17px !important;
                line-height: 24px !important;
            }
        }
    }
    .blockchain-section {
        .section-breadcrumb {
            margin-top: 50px !important;

            .breadcrumb {
                font-size: 18px !important;
            }
        }

        .section1 {
            margin-top: 40px !important;

            .midText {
                font-size: 26px !important;
                line-height: 26px !important;
            }

            .midSmallText {
                font-size: 18px !important;
                padding: 0px 10px !important;
            }

            .block-section {
                padding: 10px 30px !important;

                .slick-slider {
                    .slick-list {
                        .slick-track {
                            height: 430px !important;
                            overflow-y: hidden;

                            .slick-slide {
                                height: 400px !important;

                                div {
                                    justify-content: flex-end!important;
                                    .item-box {
                                        height: 100% !important;
                                        max-width: 260px !important;
                                        .whitebox {
                                            height: 400px !important;
                                            padding: 20px 10px 10px 60px !important;

                                            .name {
                                                font-size: 18px !important;
                                                text-align: center !important;
                                                line-height: 20px !important;
                                            }

                                            .para-style {
                                                font-size: 15px !important;
                                                line-height: 20px !important;
                                            }
                                        }
                                        .redbox{
                                            justify-content: center!important;
                                        }

                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            margin-top: 40px !important;
            padding: 40px 20px !important;

            .row {
                .card-side {
                    .row {
                        .card-details {
                            .card {
                                margin-left: 0px !important;
                                margin-bottom: 20px !important;
                                height: unset !important;
                            }
                        }
                    }
                }

                .para-side {
                    padding-left: 0px !important;
                    text-align: center !important;

                    .para-inner {
                        font-size: 24px !important;
                        line-height: 32px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;

            .midText {

                font-size: 24px !important;
                line-height: 30px !important;
                margin-bottom: 0px !important;
            }

            .list-items-section {
                .row {
                    .list-item-details {
                        padding-left: 0px !important;
                        padding-right: 0px !important;

                        .card {
                            height: unset !important;
                            margin-bottom: 20px !important;
                            padding: 20px 10px !important;

                            .upper-section {
                                font-size: 20px !important;
                                line-height: 22px !important;
                            }

                            .lower-section {
                                margin-top: 10px !important;
                                font-size: 16px !important;
                                line-height: 24px !important;
                            }
                        }
                    }
                }
            }
        }

        .section5 {
            margin-top: 0px !important;

            .heading {
                font-size: 28px !important;
                margin-bottom: 40px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 150px !important;
                }
            }

        }
    }
    .fintech-section{
       
        .section2{
            margin-top: 30px!important;
            padding: 0px 20px !important;
            .midText{
                font-size: 28px !important;
            }
        }
        .section3{
            .row:nth-child(1){
                padding: 25px 15px!important;
            }
            .row{
                margin-bottom: 0px !important;
                padding: 25px 15px!important;
                .right-side-fintech{
                    padding: 0px 40px;
                    .heading{
                        text-align: center;
                    }
                    .para{
                        font-size: 18px !important;
                        padding-right: 0px !important;
                    }
                }
                .left-side-fintech{
                    display: flex;
                    align-items: center;
                    justify-content:center;
                    img{
                        max-width: 300px !important;
                    }
                }
            }
            .row:nth-child(2){
                flex-direction: column-reverse;
            }
        }
        
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
    }
    .healthcare-section{
       
        .section1{
            margin-top: 30px!important;
            padding: 0px 20px !important;
            .row{
                margin-bottom: 0px!important;
                .right-side-fintech{
                    padding-top: 20px !important;
                    .heading{
                        text-align: center;
                    }
                    .para{
                        font-size: 18px !important;
                        padding-right: 0px !important;
                    }
                }
                .left-side-fintech{
                    display: flex;
                    align-items: center;
                    justify-content:center;
                    img{
                        max-width: 300px !important;
                    }
                }
            }
        }
        .section2{
            margin-top: 0px!important;
            padding: 0px 20px !important;
            .row:nth-child(1){
                padding: 25px 15px!important;
            }
            .row{
                margin-bottom: 0px !important;
                padding: 25px 15px!important;
                .right-side-fintech{
                    padding-top: 20px !important;
                    .heading{
                        text-align: center;
                    }
                    .para{
                        font-size: 18px !important;
                        padding-right: 0px !important;
                        text-align: justify;
                    }
                }
                .left-side-fintech{
                    display: flex;
                    align-items: center;
                    justify-content:center;
                    img{
                        max-width: 300px !important;
                    }
                }
            }
            .row:nth-child(2){
                flex-direction: column-reverse;
            }
        }
        
        .section4{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section5{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
    }
    .AIML-section{
        .section1{
            margin-top: 0px !important;
            padding: 15px 25px!important;
            .row{
                margin-bottom: 0px!important;
                padding: 15px 0px!important;

                .col-lg-7{
                    .right-section{
                        .heading{
                            text-align: center;
                            padding-top: 20px;
                            font-size: 26px !important;
                            line-height:36px!important;
                        }
                        .para{
                            font-size: 18px !important;
                            
                        }

                    }
                }

            }
        }
        .section2{
            margin-top: 0px!important;
            padding: 0px 25px!important;
            .midText{
                font-size: 26px!important;
                line-height: 28px!important;
            }
            .midSmallText{
                font-size: 18px!important;
                line-height: 28px!important;
            }
            .card-area{
                padding: 0px 0px!important;
                .row{
                    .card-area-inner{
                        padding: 10px !important;
                        margin-bottom: 20px;
                        .card{
                            padding: 20px!important;
                            height:unset !important;
                        }
                    }
                }
            }
        }
        
        .section3{
            padding: 30px 10px!important;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            .row{
                
                .right-side-aiml{
                    margin-top: 20px!important;
                    padding: 0px 20px !important;
                    .heading{
                        text-align: center;
                        padding-top: 20px;
                        font-size: 26px !important;
                        line-height:36px!important;
                    }
                    .para{
                        font-size: 18px !important;
                        
                    }
                }
            }
        }
        .section4{
            display: none;
        }
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 150px !important;
                }
            }

        }
    }
    .services-section{
        .section1{
            margin-bottom: 0px!important;
            flex-wrap: wrap;
            .red-box{
                height: 80px!important;
                width: 230px!important;
                margin-bottom: 20px!important;

            }
        }
        .service-card-container{
            padding: 40px 20px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    .service-para{
                        margin-top: 0px !important;
                    }
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                    .heading{
                        font-size: 26px !important;
                        line-height: 26px !important;
                    }
                }
                .col-lg-5{
                    .image-container{
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
        .service-card-container.reverse{
            padding: 40px 20px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    padding-left: 10px !important;
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                }
                .col-lg-5{
                    .image-container{
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
    }
    .contact-section {
        margin-top: 110px !important;
        padding: 10px 50px!important;

        .midtitle {
            text-align: center;
            font-size: 26px !important;
        }

        .midsmalltext {
            font-size: 18px !important;

        }

        .contact-box {
            margin: 20px !important;

            .row {
                .col-lg-8 {
                    .contact-form {
                        .contact-input {
                            padding: 20px 0px 20px 20px !important;
                        }

                        .textarea {
                            padding: 20px 0px 20px 20px !important;
                        }
                    }

                    .bottom-row {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .col-lg-6 {
                            width: 100% !important;

                            .contact-form-btn {
                                padding: 15px 40px !important;
                                font-size: 25px;
                            }
                        }
                    }
                }

                .col-lg-4 {
                    height: 380px;
                }
            }
        }
    }
    .productdev-section{
        margin-top: 40px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            margin: 40px 10px!important;
            .row{
                .col-lg-6{
                   
                    .text-div{
                        padding-top: 10px!important;
                        .heading{
                            font-size: 26px !important;
                            line-height: 26px !important;
                            margin-bottom: 20px !important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
        .section3{
            min-height: 345px!important;
            margin-bottom: 350px!important;
            .black-heading{
                font-size: 26px !important;
                text-align: center!important;
                line-height: 26px !important;
            }
            .white-heading{
                font-size: 18px!important;
            }
            .center-box{
                margin-top: 230px!important;
            }
        }
        .section4{
            margin: 182px 10px 0px 10px!important;
            .mid-title{
                font-size: 26px!important;
                text-align: center!important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;
    
                img {
                    width: 150px !important;
                }
            }
        }
        .section5{
            padding-top: 40px!important;
            padding-left: 20px!important;
            padding-right: 20px!important;
            padding-bottom: 10px!important;
            .mid-title{
                font-size: 26px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                margin-bottom: 60px!important;
            }
            .mid-section{
                .row{
                    .col-lg-6{
                        .mid-box{
                            margin-bottom: 60px;
                        }
                    }
                }
                .row:nth-child(2){
                    margin-top: 0px!important;
                }
            }
          
        }
        .contact-section{
            margin-top: 0px !important;
        }
    }
    .thinking-design-section{
        margin-top: 0px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            margin: 40px 10px!important;
            .row{
                .col-lg-6{
                  
                    .text-div{
                        padding-top: 10px!important;
                        .heading{
                            font-size: 26px !important;
                            line-height: 26px !important;
                            margin-bottom: 20px !important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
        .section3{
           .row{
            min-height: unset !important;
            width: 100%;
            margin-left: 0px !important;
            margin-right: 0px !important;
            .left-side{
                
                width: 100%!important;
                padding: 0px 60px;
                padding-bottom: 30px;
                img{
                    height: 255px !important;
                }
            }
            .right-side-outer{
                .right-side{
                    padding: 20px 0px 20px 0px!important;
                    .heading{
                        font-size: 26px !important;
                        text-align: center !important;
                    }
                    .smallText{
                        text-align: center!important;
                    }
                }
            }
          
           }
           .list-section{
            flex-direction: column;
            margin-top: 10px!important;
            padding: 0px !important;
        }
        }
        .section4{
            margin:0px 10px 0px 10px!important;
            .mid-title{
                font-size: 26px!important;
                text-align: center!important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;
    
                img {
                    width: 120px !important;
                }
            }
        }
        .section5{
            padding-top: 40px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 10px!important;
            .mid-title{
                font-size: 26px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                margin-bottom: 60px!important;
            }
            .mid-section{
                .row{
                    .col-lg-6{
                        .mid-box{
                            margin-bottom: 60px;
                            .left-side{
                                left: 50%!important;
                                transform: translate(-50%, -50%);
                                top: 84px!important;
                                width: 100px!important;
                                height: 100px!important;
                                .icon{
                                    height: 40px!important;
                                    width: 50px!important;
                                }
                            }
                            .right-side{
                                max-width: unset !important;
                                margin-left: 0px!important;
                                h2{
                                    font-size: 24px!important;
                                    text-align: center;
                                    margin-bottom: 50px!important;
                                    padding-left: 0px!important;
                                }
                                .round-div{
                                    padding: 81px 20px 32px 20px!important;
                                    border-radius: 12px 12px 12px 12px!important;
                                    font-size: 16px!important;
                                }
                            }
                        }
                    }
                }
                .row:nth-child(2){
                    margin-top: 0px!important;
                }
            }
        }
        .contact-section{
            margin-top: 0px !important;
        }
    }
    .software-dev-section{
        margin-top: 0px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            padding-bottom: 0px !important;
        }
        .section2,.section4,.section6{
            margin-top: 0px!important;
            margin-bottom: 60px !important;
            flex-direction: column !important;
            .left-side{
                padding: 60px 10px 0px 10px!important;
                width: 100% !important;
            }
            .right-side{
                width: 100% !important;
                .content-section{
                    padding: 20px 10px 10px 10px !important;
                    .redHeading,.blackHeading{
                        font-size: 30px !important;
                        text-align: center !important;
                    }

                }
                
            }
        }
        .section3,.section5,.section7{
            .row{
                .left-side{
                    .content-box{
                        margin-bottom: 35px;
                    }
                }
            }
        }
        .red-carousel-section{
            .upper-div{
                .big-redText{
                    font-size: 28px!important;
                    line-height: 30px !important;
                    text-align: center!important;
                }
                .para{
                    font-size: 18px !important;
                    padding: 0px 10px !important;
                }
            }
        }
    }
    .product-growth-section{
        margin-top: 0px!important;
        .section1{
            margin-bottom: 40px !important;
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            padding: 0px 10px !important;
            padding-top: 40px !important;
            height: 340px;
            margin-bottom: 150px!important;
            .black-heading{
                font-size: 28px!important;
            }
            .center-box{
                margin-top: 240px!important;
            }
        }
        .section3{
            padding-top: 55px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 20px!important;
            .mid-title{
                font-size: 28px!important;
                text-align: center!important;
                line-height: 33px!important;
                margin-bottom: 30px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                line-height: 32px!important;
            }
            .mid-section{
                margin-top: 30px!important;
                .row{
                    .col-lg-5{
                        .mid-box{
                            font-size: 18px!important;
                        }
                    }
                }
            }
        }
        .section4 {
            margin: 30px 10px 0px 10px!important;
            .mid-title{
                font-size: 28px!important;
                line-height: 30px!important;
                text-align: center !important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }
        }
        .contact-section{
            margin-top: 30px !important;
        }

    }
}

@include respond(tab-land) {
    
    .home-section2 {

        padding: 60px 10px !important;

        .section2-upper {
            padding: 0px !important;
            .card-section{
                background-repeat: repeat-y!important;
                .row{
                    .uneven-columns{
                        margin:10px 10px 0px !important;
                    }
                }
            }
            .row {
                .col-lg-6 {
                    .greybox {
                        padding: 25px 20px !important;
                    }

                    .projectbox {
                        .lower-projectbox {
                            .pb-left {
                                margin-right: 75px !important;
                            }
                        }
                    }
                }
            }
        }

        .section2-lower {
            margin-top: 70px !important;
            padding: 10px!important;
            .mid-title{
                font-size: 26px !important;
                text-align: center !important;
                line-height: 30px !important;
            }
            .sponsers {
                margin-top: 30px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
                
                img {
                    width: 120px !important;
                    margin: 10px !important;
                }
            }
        }

    }

    .home-section3 {
        margin-top: 0px !important;

        .mid-para {
            margin-bottom: 80px !important;
            padding: 0px 15px;
            font-size: 16px !important;
        }

        .overlap-div.rightside {
            margin-bottom:30px !important;
        }

        .overlap-div {
            flex-direction: column;

            .image {
                display: none !important;
                
                img {
                    width: 100% !important;
                }
            }

            .info-div {
                width: 100% !important;

                .red-heading {
                    padding: 10px !important;
                    text-align: center!important;
                }

                .grey-container {
                    padding: 20px 0px !important;
                    .list-items{
                        .item{
                            .icon{
                                margin-right: 16px !important;
                                &::before{
                                    height: 16px;
                                    width: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-section4 {
        display: none;
    }

    .home-section5 {
        margin-top: 60px !important;

        padding: 40px 30px 50px!important;
        .home-carousel {
            margin-top: 30px !important;
            padding: 20px !important;

            .carousel {
                .carousel-control-next {
                    display: none;
                }

                .carousel-control-prev {
                    display: none;
                }

                .carousel-inner {

                    .carousel-item {
                        .carousel-item1 {
                            .upper-side {}

                            .lower-side {
                                flex-direction: column;

                                .left-side {
                                    width: 100% !important;
                                    height: 400px !important;
                                }

                                .right-side {
                                    width: 100% !important;
                                    padding: 30px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .contactus-section {
        .section1 {
            .row {
                .col-lg-5 {
                    padding: 0 10px !important;

                    .carousel {
                        .carousel-inner {
                            .active.carousel-item {
                                .carousel-item1 {
                                    .info-div {
                                        margin-top: 115px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .col-lg-6 {
                    padding: 0 10px !important;
                    margin-top: 100px;

                    .request-to-demo {
                        width: 100% !important;
                        margin-right: 0 !important;

                        .contact-form {
                            padding: 30px 40px !important;

                            form {
                                .row {
                                    margin-top: 0px !important;

                                    .col-lg-6 {
                                        margin-top: 20px !important;
                                        padding: 0px !important;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }

        .section2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px !important;

            .center-box {
                width: 100% !important;
                transform: unset !important;
                left: unset !important;
                top: unset !important;
                position: relative !important;
                margin-top: 0px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 20px !important;

                .right-box {
                    display: none;

                }

                .left-box {
                    width: 100% !important;
                    border-radius: 6px !important;
                    padding: 20px !important;

                    .suggestion {
                        text-align: center !important;
                    }

                    .btn-box {
                        margin-top: 35px !important;

                        button {
                            a {
                                padding: 13px 18px !important;
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            .office-carousel {
                figure {
                    min-width: 180px !important;
                }
            }
        }

        .section4 {
            .details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

        }
    }

    .footer-container {
        .top-row {
            padding: 30px 30px 60px !important;
            flex-direction: column;

            .socials {
                margin: 20px !important;
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    background: black;
                }
            }

            .column-2,
            .column-3,
            .column-4 {
                .heading {
                    margin-bottom: 20px !important;
                }
            }

            .column-2 {
                margin-bottom: 30px !important;
            }

            .column-3 {
                margin-bottom: 30px !important;
            }

            .column-4 {
                width: 100% !important;
                margin-top: 20px !important;

            }
        }

        .bottom-row {
            padding: 22px 40px !important;
            padding-bottom: 35px !important;
            height: unset !important;

            .bottom-left,
            .bottom-right {
                width: 100% !important;
                text-align: center;
            }
        }
    }

    .about-section {
        margin: 70px 0px !important;
        padding: 0px 10px;

        .section1 {
            .info-div {
                flex-direction: column;

                .grey-box {
                    width: 100% !important;
                }

                .info-text {
                    width: 100% !important;
                    padding: 0px 10px;
                }
            }

            .info-div.reverse {
                flex-direction: column !important;

                .grey-box {
                    margin-left: 0px !important;
                }
            }
        }

        .section2 {
            margin-top: 70px !important;

            .company-div {
                .row {
                    .company {
                        min-width: 131px !important;
                        min-height: 42px !important;
                        background-size: contain !important;
                    }
                }

                .row-second {
                    margin-top: 50px !important;
                }
            }
        }
    }

    .games-section {
        .carousel-section {
            padding-left: 30px !important;
            padding-right: 30px !important;

            .carousel-item1 {
                padding: 30px 35px !important;
                height: unset !important;

                .left-side {
                    .source {
                        bottom: -15px !important;

                        .source-link {
                            font-size: 16px !important;
                        }
                    }
                }
            }
        }

        .card-section {
            .row {
                margin: unset !important;

                .col-lg-6 {
                    padding-left: 30px !important;
                    padding-right: 30px !important;

                    margin-bottom: 200px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 35px !important;

                        .rightside {
                            background-size: contain;
                            width: 200.39px !important;
                            right: 50px;
                            background-size: contain;
                        }

                        .leftside {
                            padding-bottom: 35px;
                        }

                        .rummy-image {
                            width: 300px !important;
                            right: 0 !important;
                        }
                    }
                }
            }

            .row:nth-child(1) {
                margin-top: 200px !important;
                margin-bottom: 0px !important;
            }

            .row:last-child {
                margin-bottom: 200px !important;

                .col-lg-12 {
                    padding: 0px 30px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 35px !important;


                        .leftside {
                            padding-bottom: 35px;
                            padding-top: 95px !important;
                            width: 100% !important;
                        }

                        .rightside.SportBettingLaptop {
                            background-size: contain;
                            width: 320.39px !important;
                            right: 20px !important;

                        }
                    }
                }
            }
        }
    }

    .gamepages-section {
        margin-top: 50px !important;

        .section1 {
            .breadcrumb {
                font-size: 16px !important;
                padding-left: 20px !important;
            }
        }

        .section2 {
            margin-top: 40px !important;

            .row {
                margin-bottom: 25px !important;

                .left-side {
                    padding: 0px 20px !important;
                }

                .right-side {
                    img {
                        height: 100% !important;
                        margin-top: unset !important;
                        width: 100% !important;
                        max-height: 500px !important;
                    }
                }
            }
        }

        .section3 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;
            margin-bottom: 50px !important;

            .heading {
                font-size: 30px !important;
            }

            .card-section {
                padding: 0px 15px !important;
                background-repeat: repeat-y !important;
            }

            .smalltext {
                padding: 0px 40px !important;
                text-align: center !important;
                margin-bottom: 30px !important;
            }

            .trivia-feature-section {
                justify-content: center;

                .row {
                    .col-lg-4 {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .section5 {
            margin-top: 50px !important;

            .mid-text {
                padding: 0px 15px !important;
            }
        }
    }

    .metaverse-section {


        .section2 {

            .midSmallText {
                font-size: 22px !important;
                line-height: 26px !important;
                padding: 0px 20px;
            }

            .block-section {
                margin-top: 30px !important;

                .row {
                    padding: 0px 30px !important;

                    .card-style.box-style {
                        margin-bottom: 30px;

                        .card {
                            height: unset !important;
                        }
                    }
                }
            }
        }

        .section3 {
            min-height: 320px !important;
            padding-top: 50px !important;

            .black-heading {
                font-size: 26px !important;
            }

            .white-heading {
                font-size: 20px !important;
            }

            .center-box {
                margin-top: 200px !important;
            }
        }

        .section4 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section5 {
            padding: 0px 10px !important;

            .midText {
                font-size: 26px !important;
            }

            .midSmallText {
                font-size: 17px !important;
                line-height: 24px !important;
            }
        }
    }

    .blockchain-section {
        .section-breadcrumb {
            margin-top: 50px !important;

            .breadcrumb {
                font-size: 18px !important;
            }
        }

        .section1 {
            margin-top: 40px !important;

            .midText {
                font-size: 26px !important;
                line-height: 26px !important;
            }

            .midSmallText {
                font-size: 18px !important;
                padding: 0px 10px !important;
            }

            .block-section {
                padding: 10px 30px !important;

                .slick-slider {
                    .slick-list {
                        .slick-track {
                            height: 430px !important;
                            overflow-y: hidden;

                            .slick-slide {
                                height: 400px !important;

                                div {
                                    justify-content: flex-end!important;
                                    .item-box {
                                        height: 100% !important;
                                        max-width: 260px !important;
                                        .whitebox {
                                            height: 400px !important;
                                            padding: 20px 10px 10px 60px !important;

                                            .name {
                                                font-size: 18px !important;
                                                text-align: center !important;
                                                line-height: 20px !important;
                                            }

                                            .para-style {
                                                font-size: 15px !important;
                                                line-height: 20px !important;
                                            }
                                        }
                                        .redbox{
                                            justify-content: center!important;
                                        }

                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            margin-top: 40px !important;
            padding: 40px 45px !important;

            .row {
                .card-side {
                    .row {
                        .card-details {
                            .card {
                                margin-left: 0px !important;
                                margin-bottom: 20px !important;
                                height: unset !important;
                            }
                        }
                    }
                }

                .para-side {
                    padding-left: 0px !important;
                    text-align: center !important;

                    .para-inner {
                        font-size: 24px !important;
                        line-height: 32px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;
            padding: 0px 40px !important;
            .midText {

                font-size: 24px !important;
                line-height: 30px !important;
                margin-bottom: 0px !important;
            }

            .list-items-section {
                .row {
                    .list-item-details {
                        padding-left: 0px !important;
                        padding-right: 0px !important;

                        .card {
                            height: unset !important;
                            margin-bottom: 40px !important;
                            padding: 20px 10px !important;

                            .upper-section {
                                font-size: 20px !important;
                                line-height: 22px !important;
                            }

                            .lower-section {
                                margin-top: 10px !important;
                                font-size: 16px !important;
                                line-height: 24px !important;
                            }
                        }
                    }
                }
            }
        }

        .section5 {
            margin-top: 0px !important;

            .heading {
                font-size: 28px !important;
                margin-bottom: 40px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 150px !important;
                }
            }

        }
    }

    .healthcare-section{
       
        .section1{
            margin-top: 30px!important;
            padding: 0px 20px !important;
            .row{
                margin-bottom: 0px!important;
                .right-side-fintech{
                    padding-top: 20px !important;
                    .heading{
                        text-align: center;
                    }
                    .para{
                        font-size: 18px !important;
                        padding-right: 0px !important;
                    }
                }
                .left-side-fintech{
                    display: flex;
                    align-items: center;
                    justify-content:center;
                    img{
                        max-width: 300px !important;
                    }
                }
            }
        }
        .section2{
            margin-top: 0px!important;
            padding: 0px 20px !important;
            .row:nth-child(1){
                padding: 25px 15px!important;
            }
            .row{
                margin-bottom: 0px !important;
                padding: 25px 15px!important;
                .right-side-fintech{
                    padding-top: 20px !important;
                    .heading{
                        text-align: center;
                    }
                    .para{
                        font-size: 18px !important;
                        padding-right: 0px !important;
                        text-align: justify;
                    }
                }
                .left-side-fintech{
                    display: flex;
                    align-items: center;
                    justify-content:center;
                    img{
                        max-width: 300px !important;
                    }
                }
            }
            .row:nth-child(2){
                flex-direction: column-reverse;
            }
        }
        
        .section4{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section5{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
    }
    .AIML-section{
        .section1{
            margin-top: 0px !important;
            padding: 15px 25px!important;
            .row{
                margin-bottom: 0px!important;
                padding: 15px 0px!important;

                .col-lg-7{
                    .right-section{
                        .heading{
                            text-align: center;
                            padding-top: 20px;
                            font-size: 26px !important;
                            line-height:36px!important;
                        }
                        .para{
                            font-size: 18px !important;
                            
                        }

                    }
                }

            }
        }
        .section2{
            margin-top: 0px!important;
            padding: 0px 25px!important;
            .midText{
                font-size: 26px!important;
                line-height: 28px!important;
            }
            .midSmallText{
                font-size: 18px!important;
                line-height: 28px!important;
            }
            .card-area{
                padding: 0px 0px!important;
                .row{
                    .card-area-inner{
                        padding: 10px !important;
                        margin-bottom: 20px;
                        .card{
                            padding: 20px!important;
                            height:unset !important;
                        }
                    }
                }
            }
        }
        
        .section3{
            padding: 30px 10px!important;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            .row{
                
                .right-side-aiml{
                    margin-top: 20px!important;
                    padding: 0px 20px !important;
                    .heading{
                        text-align: center;
                        padding-top: 20px;
                        font-size: 26px !important;
                        line-height:36px!important;
                    }
                    .para{
                        font-size: 18px !important;
                        
                    }
                }
            }
        }
        .section4{
            display: none;
        }
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 150px !important;
                }
            }

        }
    }
    .services-section{
        .section1{
            margin-bottom: 0px!important;
            flex-wrap: wrap;
            .red-box{
                height: 80px!important;
                width: 230px!important;
                margin-bottom: 20px!important;

            }
        }
        .service-card-container{
            padding: 40px 20px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    .service-para{
                        margin-top: 0px !important;
                    }
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                    .heading{
                        font-size: 26px !important;
                        line-height: 26px !important;
                    }
                }
                .col-lg-5{
                    .image-container{

                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
        .service-card-container.reverse{
            padding: 40px 20px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    padding-left: 10px !important;
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                }
                .col-lg-5{
                    .image-container{
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
    }
    .contact-section {
        margin-top: 110px !important;
        padding: 10px 50px!important;

        .midtitle {
            text-align: center;
            font-size: 26px !important;
        }

        .midsmalltext {
            font-size: 18px !important;

        }

        .contact-box {
            margin: 20px !important;

            .row {
                .col-lg-8 {
                    .contact-form {
                        .contact-input {
                            padding: 20px 0px 20px 20px !important;
                        }

                        .textarea {
                            padding: 20px 0px 20px 20px !important;
                        }
                    }

                    .bottom-row {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .col-lg-6 {
                            width: 100% !important;

                            .contact-form-btn {
                                padding: 15px 40px !important;
                                font-size: 25px;
                            }
                        }
                    }
                }

                .col-lg-4 {
                    height: 380px;
                }
            }
        }
    }
    .productdev-section{
        margin-top: 40px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            margin: 40px 10px!important;
            .row{
                .col-lg-6{
                   
                    .text-div{
                        padding-top: 10px!important;
                        .heading{
                            font-size: 26px !important;
                            line-height: 26px !important;
                            margin-bottom: 20px !important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
        .section3{
            min-height: 345px!important;
            margin-bottom: 350px!important;
            .black-heading{
                font-size: 26px !important;
                text-align: center!important;
                line-height: 26px !important;
            }
            .white-heading{
                font-size: 18px!important;
            }
            .center-box{
                margin-top: 230px!important;
            }
        }
        .section4{
            margin: 182px 10px 0px 10px!important;
            .mid-title{
                font-size: 26px!important;
                text-align: center!important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;
    
                img {
                    width: 150px !important;
                }
            }
        }
        .section5{
            padding-top: 40px!important;
            padding-left: 20px!important;
            padding-right: 20px!important;
            padding-bottom: 10px!important;
            .mid-title{
                font-size: 26px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                margin-bottom: 60px!important;
            }
            .mid-section{
                .row{
                    .col-lg-6{
                        .mid-box{
                            margin-bottom: 60px;
                        }
                    }
                }
                .row:nth-child(2){
                    margin-top: 0px!important;
                }
            }
          
        }
        .contact-section{
            margin-top: 0px !important;
        }
    }
    .product-growth-section{
        margin-top: 0px!important;
        .section1{
            margin-bottom: 40px !important;
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            padding: 0px 10px !important;
            padding-top: 40px !important;
            height: 340px;
            margin-bottom: 150px!important;
            .black-heading{
                font-size: 28px!important;
            }
            .center-box{
                margin-top: 240px!important;
            }
        }
        .section3{
            padding-top: 55px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 20px!important;
            .mid-title{
                font-size: 28px!important;
                text-align: center!important;
                line-height: 33px!important;
                margin-bottom: 30px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                line-height: 32px!important;
            }
            .mid-section{
                margin-top: 30px!important;
                .row{
                    .col-lg-5{
                        .mid-box{
                            font-size: 18px!important;
                        }
                    }
                }
            }
        }
        .section4 {
            margin: 30px 10px 0px 10px!important;
            .mid-title{
                font-size: 28px!important;
                line-height: 30px!important;
                text-align: center !important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }
        }
        .contact-section{
            margin-top: 30px !important;
        }

    }
}



@include respond(large-tab) {
    
    .home-section2 {

        padding: 60px 10px !important;

        .section2-upper {
            padding: 0px !important;
            .card-section{
                background-repeat: repeat-y!important;
                .row{
                    .uneven-columns{
                        margin:10px 10px 0px !important;
                    }
                }
            }
            .row {
                .col-lg-6 {
                    .greybox {
                        padding: 25px 20px !important;
                    }

                    .projectbox {
                        .lower-projectbox {
                            .pb-left {
                                margin-right: 75px !important;
                            }
                        }
                    }
                }
            }
        }

        .section2-lower {
            margin-top: 70px !important;
            padding: 10px!important;
            .mid-title{
                font-size: 26px !important;
                text-align: center !important;
                line-height: 30px !important;
            }
            .sponsers {
                margin-top: 30px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;
                
                img {
                    width: 140px !important;
                    margin: 10px !important;
                }
            }
        }

    }

    .home-section3 {
        margin-top: 0px !important;

        .mid-para {
            margin-bottom: 80px !important;
            padding: 0px 15px;
            font-size: 16px !important;
        }

        .overlap-div.rightside {
            margin-bottom: 30px !important;
        }

        .overlap-div {

            .image {
                display: none !important;
                img {
                    width: 100% !important;
                }
            }

            .info-div {
                width: 100% !important;

                .red-heading {
                    padding: 10px !important;
                    text-align: center!important;
                }

                .grey-container {
                    padding: 20px 0px !important;
                    .list-items{
                        .item{
                            .icon{
                                margin-right: 16px !important;
                                &::before{
                                    height: 16px;
                                    width: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-section4 {
        display: none;
    }

    .home-section5 {
        margin-top: 60px !important;

        padding: 40px 30px 50px!important;
        .home-carousel {
            margin-top: 30px !important;
            padding: 20px !important;

            .carousel {
                .carousel-control-next {
                    display: none;
                }

                .carousel-control-prev {
                    display: none;
                }

                .carousel-inner {

                    .carousel-item {
                        .carousel-item1 {
                            .upper-side {}

                            .lower-side {
                                flex-direction: column;

                                .left-side {
                                    width: 100% !important;
                                    height: 400px !important;
                                }

                                .right-side {
                                    width: 100% !important;
                                    padding: 30px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .contactus-section {
        .section1 {
            .row {
                .col-lg-5 {
                    padding: 0 10px !important;

                    .carousel {
                        .carousel-inner {
                            .active.carousel-item {
                                .carousel-item1 {
                                    .info-div {
                                        margin-top: 115px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .col-lg-6 {
                    padding: 0 10px !important;
                    margin-top: 100px;

                    .request-to-demo {
                        width: 100% !important;
                        margin-right: 0 !important;

                        .contact-form {
                            padding: 30px 40px !important;

                            form {
                                .row {
                                    margin-top: 0px !important;

                                    .col-lg-6 {
                                        margin-top: 20px !important;
                                        padding: 0px !important;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }

        .section2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px !important;

            .center-box {
                width: 780px !important;

                .left-box {
                    width: 50% !important;
                    border-radius: 6px !important;
                    padding: 20px !important;

                    .suggestion {
                        text-align: center !important;
                    }

                    .btn-box {
                        margin-top: 35px !important;

                        button {
                            a {
                                padding: 13px 18px !important;
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            .office-carousel {
                figure {
                    min-width: 180px !important;
                }
            }
        }

        .section4 {
            .details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

        }
    }

    .footer-container {
        .top-row {
            padding: 30px 30px 60px !important;
            flex-direction: column;

            .socials {
                margin: 20px !important;
                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    background: black;
                }
            }

            .column-2,
            .column-3,
            .column-4 {
                .heading {
                    margin-bottom: 20px !important;
                }
            }

            .column-2 {
                margin-bottom: 30px !important;
            }

            .column-3 {
                margin-bottom: 30px !important;
            }

            .column-4 {
                width: 100% !important;
                margin-top: 20px !important;

            }
        }

        .bottom-row {
            padding: 22px 40px !important;
            padding-bottom: 35px !important;
            height: unset !important;

            .bottom-left,
            .bottom-right {
                width: 100% !important;
                text-align: center;
            }
        }
    }

    .games-section {


        .card-section {
            .row {
                margin: unset !important;

                .col-lg-6 {
                    padding-left: 30px !important;
                    padding-right: 30px !important;

                    margin-bottom: 200px !important;


                }
            }

            .row:nth-child(1) {
                margin-top: 200px !important;
                margin-bottom: 0px !important;
            }

            .row:last-child {
                margin-bottom: 200px !important;

                .col-lg-12 {
                    padding: 0px 30px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 35px !important;


                        .leftside {
                            padding-bottom: 35px;
                            padding-top: 30px !important;
                            width: 100% !important;
                        }

                        .rightside.SportBettingLaptop {
                            background-size: contain;
                            width: 320.39px !important;
                            right: 50px !important;

                        }
                    }
                }
            }
        }
    }

    .gamepages-section {
        margin-top: 50px !important;

        .section1 {
            .breadcrumb {
                font-size: 16px !important;
                padding-left: 20px !important;
            }
        }

        .section2 {
            margin-top: 40px !important;

            .row {
                margin-bottom: 25px !important;

                .left-side {
                    padding: 0px 50px !important;
                }

                .right-side {
                    img {
                        height: 100% !important;
                        margin-top: unset !important;
                        width: 100% !important;
                        max-width: 700px !important;
                        max-height: 500px !important;
                    }
                }
            }
        }

        .section3 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 22px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;
            margin-bottom: 50px !important;

            .heading {
                font-size: 30px !important;
            }

            .card-section {
                padding: 0px 15px !important;
                background-repeat: repeat-y !important;
            }

            .smalltext {
                padding: 0px 60px !important;
                text-align: center !important;
                margin-bottom: 30px !important;
            }

            .trivia-feature-section {
                justify-content: center;

                .row {
                    .col-lg-4 {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        .section5 {
            margin-top: 50px !important;

            .mid-text {
                padding: 0px 15px !important;
            }
        }
    }

    .metaverse-section {
        .section2 {

            .midSmallText {
                font-size: 22px !important;
                line-height: 26px !important;
                padding: 0px 20px;
            }

            .block-section {
                margin-top: 30px !important;

                .row {
                    padding: 0px 30px !important;

                    .card-style.box-style {
                        margin-bottom: 30px;

                        .card {
                            height: unset !important;
                        }
                    }
                }
            }
        }


        .section4 {
            .heading {
                justify-content: center;
                padding-left: 0px !important;
                margin-top: 30px !important;
                margin-bottom: 25px !important;
                font-size: 30px !important;
            }

            .redbox {
                padding: 30px 40px 30px 40px !important;

                .list-div {
                    width: 25%;
                    text-align: center;


                }
            }
        }

    }
    .blockchain-section {
        .section-breadcrumb {
            margin-top: 50px !important;

            .breadcrumb {
                font-size: 18px !important;
            }
        }

        .section1 {
            margin-top: 40px !important;

            .midText {
                font-size: 26px !important;
                line-height: 26px !important;
            }

            .midSmallText {
                font-size: 18px !important;
                padding: 0px 10px !important;
            }

            .block-section {
                padding: 10px 30px !important;

                .slick-slider {
                    .slick-list {
                        .slick-track {
                            height: 430px !important;
                            overflow-y: hidden;

                            .slick-slide {
                                height: 400px !important;

                                div {
                                    justify-content: center!important;
                                    .item-box {
                                        height: 100% !important;
                                        max-width: 260px !important;
                                        .whitebox {
                                            height: 400px !important;
                                            padding: 20px 10px 10px 60px !important;

                                            .name {
                                                font-size: 18px !important;
                                                text-align: center !important;
                                                line-height: 20px !important;
                                            }

                                            .para-style {
                                                font-size: 15px !important;
                                                line-height: 20px !important;
                                            }
                                        }
                                        .redbox{
                                            justify-content: center!important;
                                        }

                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            margin-top: 40px !important;
            padding: 40px 45px !important;

            .row {
                .card-side {
                    .row {
                        .card-details {
                            .card {
                                margin-left: 0px !important;
                                margin-bottom: 20px !important;
                                height: unset !important;
                            }
                        }
                    }
                }

                .para-side {
                    padding-left: 0px !important;
                    text-align: center !important;

                    .para-inner {
                        font-size: 24px !important;
                        line-height: 32px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;
            padding: 0px 40px !important;
            .midText {

                font-size: 24px !important;
                line-height: 30px !important;
                margin-bottom: 0px !important;
            }

            .list-items-section {
                .row {
                    .list-item-details {
                        padding-left: 0px !important;
                        padding-right: 0px !important;

                        .card {
                            height: unset !important;
                            margin-bottom: 40px !important;
                            padding: 20px 10px !important;

                            .upper-section {
                                font-size: 20px !important;
                                line-height: 22px !important;
                            }

                            .lower-section {
                                margin-top: 10px !important;
                                font-size: 16px !important;
                                line-height: 24px !important;
                            }
                        }
                    }
                }
            }
        }

        .section5 {
            margin-top: 0px !important;

            .heading {
                font-size: 28px !important;
                margin-bottom: 40px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 150px !important;
                }
            }

        }
    }
    .AIML-section{
        .section1{
            margin-top: 0px !important;
            padding: 15px 25px!important;
            .row{
                margin-bottom: 0px!important;
                padding: 15px 0px!important;

                .col-lg-7{
                    .right-section{
                        .heading{
                            text-align: center;
                            padding-top: 20px;
                            font-size: 26px !important;
                            line-height:36px!important;
                        }
                        .para{
                            font-size: 18px !important;
                            
                        }

                    }
                }

            }
        }
        .section2{
            margin-top: 0px!important;
            padding: 0px 25px!important;
            .midText{
                font-size: 26px!important;
                line-height: 28px!important;
            }
            .midSmallText{
                font-size: 18px!important;
                line-height: 28px!important;
            }
            .card-area{
                padding: 0px 0px!important;
                .row{
                    .card-area-inner{
                        padding: 10px !important;
                        margin-bottom: 20px;
                        .card{
                            padding: 20px!important;
                            height:unset !important;
                        }
                    }
                }
            }
        }
        
        .section3{
            padding: 30px 10px!important;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            .row{
                
                .right-side-aiml{
                    margin-top: 20px!important;
                    padding: 0px 20px !important;
                    .heading{
                        text-align: center;
                        padding-top: 20px;
                        font-size: 26px !important;
                        line-height:36px!important;
                    }
                    .para{
                        font-size: 18px !important;
                        
                    }
                }
            }
        }
        .section4{
            .image-section{
                img{
                    width: 100%;
                }
               
            }
        }
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 150px !important;
                }
            }

        }
    }
    .services-section{
        .section1{
            margin-bottom: 0px!important;
            flex-wrap: wrap;
            .red-box{
                height: 80px!important;
                width: 230px!important;
                margin-bottom: 20px!important;

            }
        }
        .service-card-container{
            padding: 40px 20px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    .service-para{
                        margin-top: 0px !important;
                    }
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                    .heading{
                        font-size: 26px !important;
                        line-height: 26px !important;
                    }
                }
                .col-lg-5{
                    .image-container{
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
        .service-card-container.reverse{
            padding: 40px 20px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    padding-left: 10px !important;
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                }
                .col-lg-5{
                    .image-container{
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
    }
    .contact-section {
        margin-top: 110px !important;
        padding: 10px 50px!important;

        .midtitle {
            text-align: center;
            font-size: 26px !important;
        }

        .midsmalltext {
            font-size: 18px !important;

        }

        .contact-box {
            margin: 20px !important;

            .row {
                .col-lg-8 {
                    .contact-form {
                        .contact-input {
                            padding: 20px 0px 20px 20px !important;
                        }

                        .textarea {
                            padding: 20px 0px 20px 20px !important;
                        }
                    }

                    .bottom-row {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .col-lg-6 {
                            width: 100% !important;

                            .contact-form-btn {
                                padding: 15px 40px !important;
                                font-size: 25px;
                            }
                        }
                    }
                }

                .col-lg-4 {
                    height: 380px;
                }
            }
        }
    }
    .productdev-section{
        margin-top: 40px!important;
        .section1{
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            margin: 40px 10px!important;
            .row{
                .col-lg-6{
                   
                    .text-div{
                        padding-top: 10px!important;
                        .heading{
                            font-size: 26px !important;
                            line-height: 26px !important;
                            margin-bottom: 20px !important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
        .section3{
            min-height: 345px!important;
            margin-bottom: 350px!important;
            .black-heading{
                font-size: 26px !important;
                text-align: center!important;
                line-height: 26px !important;
            }
            .white-heading{
                font-size: 18px!important;
            }
            .center-box{
                margin-top: 230px!important;
            }
        }
        .section4{
            margin: 182px 10px 0px 10px!important;
            .mid-title{
                font-size: 26px!important;
                text-align: center!important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;
    
                img {
                    width: 150px !important;
                }
            }
        }
        .section5{
            padding-top: 40px!important;
            padding-left: 20px!important;
            padding-right: 20px!important;
            padding-bottom: 10px!important;
            .mid-title{
                font-size: 26px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                margin-bottom: 60px!important;
            }
            .mid-section{
                .row{
                    .col-lg-6{
                        .mid-box{
                            margin-bottom: 60px;
                        }
                    }
                }
                .row:nth-child(2){
                    margin-top: 0px!important;
                }
            }
          
        }
        .contact-section{
            margin-top: 0px !important;
        }
    }
    .product-growth-section{
        margin-top: 0px!important;
        .section1{
            margin-bottom: 40px !important;
                .breadcrumb {
                    margin-top: 40px !important;
                    font-size: 16px !important;
                    padding-left: 20px !important;
                }
        }
        .section2{
            padding: 0px 10px !important;
            padding-top: 40px !important;
            height: 340px;
            margin-bottom: 150px!important;
            .black-heading{
                font-size: 28px!important;
            }
            .center-box{
                margin-top: 240px!important;
            }
        }
        .section3{
            padding-top: 55px!important;
            padding-left: 10px!important;
            padding-right: 10px!important;
            padding-bottom: 20px!important;
            .mid-title{
                font-size: 28px!important;
                text-align: center!important;
                line-height: 33px!important;
                margin-bottom: 30px!important;
            }
            .midsmalltext{
                font-size: 18px!important;
                line-height: 32px!important;
            }
            .mid-section{
                margin-top: 30px!important;
                .row{
                    .col-lg-5{
                        .mid-box{
                            font-size: 18px!important;
                        }
                    }
                }
            }
        }
        .section4 {
            margin: 30px 10px 0px 10px!important;
            .mid-title{
                font-size: 28px!important;
                line-height: 30px!important;
                text-align: center !important;
            }
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 120px !important;
                }
            }
        }
        .contact-section{
            margin-top: 30px !important;
        }

    }
}



@include respond(desktop) {
    .blockchain-section {
        .section-breadcrumb {
            margin-top: 50px !important;

            .breadcrumb {
                font-size: 18px !important;
            }
        }

        .section1 {
            margin-top: 40px !important;

            .midText {
                font-size: 26px !important;
                line-height: 26px !important;
            }

            .midSmallText {
                font-size: 18px !important;
                padding: 0px 10px !important;
            }

            .block-section {
                padding: 10px 30px !important;

                .slick-slider {
                    .slick-list {
                        .slick-track {
                            height: 260px !important;
                            overflow-y: hidden;

                            .slick-slide {
                                height: 400px !important;

                                div {
                                    justify-content: center!important;
                                    .item-box {
                                        height: 100% !important;
                                        max-width: 400px !important;
                                        .whitebox {
                                            height: 250px !important;
                                            padding: 20px 10px 10px 60px !important;

                                            .name {
                                                font-size: 18px !important;
                                                text-align: center !important;
                                                line-height: 20px !important;
                                            }

                                            .para-style {
                                                font-size: 15px !important;
                                                line-height: 20px !important;
                                            }
                                        }
                                        .redbox{
                                            justify-content: center!important;
                                        }

                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .section3 {
            margin-top: 40px !important;
            padding: 40px 45px !important;

            .row {
                .card-side {
                    .row {
                        .card-details {
                            .card {
                                margin-left: 0px !important;
                                margin-bottom: 20px !important;
                                height: unset !important;
                            }
                        }
                    }
                }

                .para-side {
                    padding-left: 0px !important;
                    text-align: center !important;

                    .para-inner {
                        font-size: 24px !important;
                        line-height: 32px !important;
                    }
                }
            }
        }

        .section4 {
            margin-top: 50px !important;
            padding: 0px 40px !important;
            .midText {

                font-size: 24px !important;
                line-height: 30px !important;
                margin-bottom: 0px !important;
            }

            .list-items-section {
                .row {
                    .list-item-details {
                        padding-left: 0px !important;
                        padding-right: 0px !important;

                        .card {
                            height: unset !important;
                            margin-bottom: 40px !important;
                            padding: 20px 10px !important;
                            margin-right: 20px!important;
                            .upper-section {
                                font-size: 20px !important;
                                line-height: 22px !important;
                            }

                            .lower-section {
                                margin-top: 10px !important;
                                font-size: 16px !important;
                                line-height: 24px !important;
                            }
                        }
                    }
                }
            }
        }

        .section5 {
            margin-top: 0px !important;

            .heading {
                font-size: 28px !important;
                margin-bottom: 40px !important;
            }

            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }

        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 150px !important;
                }
            }

        }
    }
}


@media (min-width: 1025px) and (max-width: 1200px) {
    .games-section {


        .card-section {
            .row {
                margin: unset !important;

                .col-lg-6 {
                    padding-left: 30px !important;
                    padding-right: 30px !important;

                    margin-bottom: 200px !important;


                }
            }

            .row:nth-child(1) {
                margin-top: 200px !important;
                margin-bottom: 0px !important;
            }

            .row:last-child {
                margin-bottom: 200px !important;

                .col-lg-12 {
                    padding: 0px 30px !important;

                    .games-card {
                        min-width: unset !important;
                        height: unset !important;
                        padding: 30px 35px !important;


                        .leftside {
                            padding-bottom: 35px;
                            padding-top: 30px !important;
                            width: 100% !important;
                        }

                        .rightside.SportBettingLaptop {
                            background-size: contain;
                            width: 420.39px !important;
                            right: 50px !important;

                        }
                    }
                }
            }
        }
    }

    .metaverse-section {
        .section2 {



            .block-section {
                margin-top: 30px !important;

                .row {
                    padding: 0px 30px !important;

                    .card-style.box-style {
                        margin-bottom: 30px;

                        .card {
                            height: unset !important;
                        }
                    }
                }
            }
        }

    }
    .services-section{
        .section1{
            margin-bottom: 0px!important;
            flex-wrap: wrap;
            .red-box{
                height: 80px!important;
                width: 230px!important;
                margin-bottom: 20px!important;

            }
        }
        .service-card-container{
            padding: 40px 20px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    .service-para{
                        margin-top: 0px !important;
                    }
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                    .heading{
                        font-size: 26px !important;
                        line-height: 26px !important;
                    }
                }
                .col-lg-5{
                    .image-container{
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
        .service-card-container.reverse{
            padding: 40px 20px!important;
            .row{
                .col-lg-7{
                    padding-right: 10px !important;
                    padding-left: 10px !important;
                    .services{
                        margin-top: 30px !important;
                        h2{
                            font-size: 24px !important;
                            line-height: 24px !important;
                        }
                        .services-lists{
                            margin-top: 10px!important;
                            padding-left: 20px!important;
                        }
                    }
                }
                .col-lg-5{
                    .image-container{
                        background-size: cover !important;
                        width: 100% !important;
                    }
                    .link-container{
                        padding-left: 10px !important;
                        margin-top: 10px !important;
                        a{
                            font-size: 22px !important;
                        }
                        .icon{
                            margin-left: 16px !important;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1224px){
    .home-section4{
        display: none!important;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .footer-container {
        .top-row {
            .column-1 {
                width: 100% !important;
            }
        }
    }
}
@media (min-width: 980px) and (max-width:1154px){
    .AIML-section{
        .section1{
            margin-top: 0px !important;
            padding: 15px 25px!important;
            .row{
                margin-bottom: 0px!important;
                padding: 15px 0px!important;

                .col-lg-7{
                    .right-section{
                        .heading{
                            text-align: left;
                            padding-top: 20px;
                            font-size: 26px !important;
                            line-height:36px!important;
                        }
                        .para{
                            font-size: 18px !important;
                            
                        }

                    }
                }

            }
        }
        .section2{
            margin-top: 0px!important;
            padding: 0px 25px!important;
            .midText{
                font-size: 26px!important;
                line-height: 28px!important;
            }
            .midSmallText{
                font-size: 18px!important;
                line-height: 28px!important;
            }
            .card-area{
                padding: 0px 0px!important;
                .row{
                    .card-area-inner{
                        padding: 10px !important;
                        margin-bottom: 20px;
                        .card{
                            padding: 20px!important;
                            height:375px !important;
                        }
                    }
                }
            }
        }
        
        .section3{
            padding: 30px 10px!important;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            .row{
                
                .left-side-aiml{
                    width: 46.666667%!important;
                    .pics-block{
                        .row{
                            img{
                                width: 214px!important;
                                height: 230px!important;
                            }
                        }
                    }
                }
                .right-side-aiml{
                    margin-top: 20px!important;
                    padding: 0px 20px !important;
                    width: 53.333333%!important;
                    .heading{
                        text-align: center;
                        padding-top: 20px;
                        font-size: 26px !important;
                        line-height:36px!important;
                    }
                    .para{
                        font-size: 18px !important;
                        
                    }
                }
            }
        }
        .section4{  
            
            .image-section{
                width: 100% !important;
            }
        }
        .section5{
            margin-top: 40px!important;
            margin-bottom: 0px!important;
            .heading{
                font-size: 30px!important;
            }
            .redbox {
                padding: 30px 10px 30px 10px !important;

                .list-div {
                    width: 25%;
                    text-align: center;

                    .list-heading {
                        font-size: 18px !important;
                        line-height: 20px !important;
                        height: 35px !important;
                        margin-bottom: 10px !important;
                    }

                    .list-item {
                        font-size: 15px !important;
                        line-height: 20px !important;
                        text-transform: capitalize !important;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
        .section6{
            padding: 60px 15px!important;
            .heading{
                font-size: 26px!important;
                line-height: 30px!important;
            }     
            .para{
                font-size: 18px!important;
            }  
        }
        .section-last {
            .sponsers {
                padding-left: 10px !important;
                padding-right: 10px !important;

                img {
                    width: 150px !important;
                }
            }

        }
    }
}

@media  (min-width: 1600px) {
    .productdev-section {
        .section3 {    
            .center-box {
                .slider-styles {
                    .slick-list {
                        .slick-track {
                          width: 1320px !important;
                            .slick-slide {
                                width: 440px!important;
                                div {
                                    
                                }
                            }
                        }
                    }
                }
    
               
            }
        }
    }
}