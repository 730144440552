@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import './icons.scss';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./mixin.scss";
@import './responsive.scss';
@import '../hoverEffects/hover.scss';


#root {
    overflow-x: hidden;
}

.navbar-styles {
    justify-content: center;
    padding-left: 36px!important;
    padding-right: 36px!important;
    background-color: transparent;
    margin-left: 0px;
    margin-right: 0px;
    max-width: 100%;
    z-index: 100;

    .navbar-brand {
        margin-right: 50px;

        img {
            width: 235px;
            height: 60px;
        }
    }

    .navbar-collapse {
        width: 100%;
        justify-content: center;

        .navbar-nav {
            justify-content: flex-end;
            width: 100%;

            z-index: 10;



            .nav-link {
                margin: 0px 16px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                //font-size: 16px;
                font-size: 15px;
                line-height: 19px;
                /* identical to box height */
                color: gray;
                text-transform: uppercase;
                z-index: 10;
            }

            .nav-link.active {
                color: #ee3a38;
            }

            .nav-link:hover {
                color: #ee3a38;

            }
        }

        @media screen and (max-width: 1287px) {
            .navbar-nav {
                .dropdown {
                    margin-left: -10px;
                }
            }
        }
    }

    .btn.btn-primary,
    .lets-talk-btn {
        min-width: 125px;
        margin-left: 67px;
        justify-content: center;
        border-radius: 2px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 86.19%;
        /* identical to box height, or 12px */

        position: relative;
        transition: all .3s;
        border: none;
        overflow: hidden;
        z-index: 1;

        a {
            padding: 16px;
            color: #ffffff;
            text-decoration: none;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #EE3A38;

            border-radius: 2px;
            z-index: -2;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0%;
            background: linear-gradient(180deg, #ffffff 0%, #eeeeee 100%);
            color: #EE3A38;
            border: 0px solid white;

            transition: all .3s;
            border-radius: 2px;
            z-index: -1;
        }

        &:hover {
            color: #EE3A38;
            border: 1px solid #EE3A38;
            border-radius: 2px;

            a {
                color: #EE3A38;

            }

            &:before {
                height: 100%;

            }
        }
    }

}

.headerFixed {
    background: white;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100% !important;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.11);

    .navbar-collapse {
        .navbar-nav {
            .nav-link {
                color: #ee3a38;
            }

            .nav-link.active {
                color: black;
            }
        }
    }
    .menu-icon{
        div{
            span{
                background-color: black;
            }
        }
    }
}

//hero-styles
.hero-container {
    height: 637px;
    width: 100%;
    max-width: none !important;
    margin-top: -100px;
    padding: 100px 37px 0px 37px !important;
    margin-left: 0px !important;

    .hero-container-inner {
        height: 100%;
        display: flex;
        align-items: center;

        .hero-section1 {
            .section-1-inner {
                .hero-bigText {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    //font-size: 32px;
                    font-size: 28px;
                    line-height: 38px;
                    /* or 28px */
                    color: #ffffff;
                    margin-top: 80px;
                }

                .react-reveal {
                    width: 100%;
                    text-align: center;
                }

                .hero-smallText {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 300;
                    font-size: 22px;
                    line-height: 24px;
                    color: #d0d0d0;
                    margin-top: 20px;
                }



                .hero-button-red-to-grey {
                    display: flex;
                    align-items: center;

                    border-radius: 2px;


                    transition: all .3s;
                    position: relative;
                    overflow: hidden;
                    z-index: 1;
                    border: none;

                    a {
                        display: flex;
                        align-items: center;
                        text-transform: capitalize;
                        font-weight: 400;
                        font-size: 20px;
                        //font-size: 20px;
                        line-height: 86.19%;
                        /* or 17px */

                        padding: 17px 30px;
                        color: #545454;
                        text-decoration: none;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, #ffffff 0%, #eeeeee 100%);
                        border-radius: 2px;
                        z-index: -2;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0%;
                        height: 100%;
                        background: #EE3A38;
                        color: white;
                        border: 0px solid #780C0B;

                        transition: all .3s;
                        border-radius: 2px;
                        z-index: -1;
                    }

                    &:hover {
                        color: #fff;

                        a {
                            color: #fff;
                        }

                        .play-btn {
                            &::before {
                                background-image: url("../images/see-demo-white.png");

                            }
                        }

                        &:before {
                            width: 100%;
                        }
                    }
                }

                .hero-button-grey-to-red {
                    display: flex;
                    align-items: center;
                    border-radius: 2px;
                    margin: 40px auto;


                    transition: all .3s;
                    position: relative;
                    overflow: hidden;
                    z-index: 1;
                    border: none;

                    a {
                        display: flex;
                        align-items: center;
                        text-transform: capitalize;
                        font-weight: 400;
                        font-size: 20px;
                        //font-size: 20px;
                        line-height: 86.19%;
                        padding: 17px 30px;
                        color: #fff;
                        text-decoration: none;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #EE3A38;
                        border-radius: 2px;
                        z-index: -2;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0%;
                        height: 100%;
                        background: linear-gradient(180deg, #ffffff 0%, #eeeeee 100%);
                        color: white;
                        border: 0px solid #780C0B;

                        transition: all .3s;
                        border-radius: 2px;
                        z-index: -1;
                    }

                    &:hover {
                        color: #545454;

                        a {
                            color: #EE3A38;
                        }

                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.hero-container-home-bg {

    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .hero-container-inner {
        position: relative;

        .hero-section1 {
            .section-1-inner {
                .hero-smallText {
                    text-align: left;
                    line-height: unset;
                }

                .hero-button-red-to-grey {
                    margin-top: 46px;
                }
            }
        }
    }
}

.hero-container-industries-bg {
    background: url("../images/industries-bg.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-button {
                    background: #EE3A38;
                    border: none;
                    box-sizing: border-box;
                    border-radius: 2px;
                    font-family: "Roboto";
                    font-style: normal;
                    padding: 17px 30px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* or 17px */
                    margin-top: 77px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.hero-container-aboutus-bg {
    background: url('../images/about-bg.png');
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-button {

                    display: none;
                }
            }
        }
    }
}

.hero-container-contactus-bg {
    background: url("../images/contact-us-bg.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-button {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }
            }
        }
    }
}

.hero-container-services-bg {
    background: url("../images/services-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    width:100%;
    height:100%;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-smallText {
                    text-align: center;
                    line-height: 41px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 22px;
                    line-height: 34px;

                    /* identical to box height, or 19px */
                    color: #D0D0D0;
                }

                .hero-bigText {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 86.19%;
                    /* or 34px */

                    text-transform: capitalize;

                    color: #FFFFFF;
                }

                .hero-button {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }
            }
        }
    }
}

.hero-container-services-pd-bg {
    background: url("../images/ProductDevHomeBannerNew.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-smallText {
                    text-align: center;
                    line-height: 41px;
                }

                .hero-button {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }
            }
        }
    }
}

.hero-container-services-product-growth-bg {
    background: url("../images/ProductGrowthHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-smallText {
                    text-align: center;
                    line-height: 41px;
                }

                .hero-button {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }
            }
        }
    }
}

.hero-container-services-research-bg {
    background: url("../images/ResearchHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-smallText {
                    text-align: center;
                    line-height: 41px;
                }

                .hero-button {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }
            }
        }
    }
}

.hero-container-services-td-bg {
    background: url("../images/ThinkingDesignHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-smallText {
                    text-align: center;
                    line-height: 41px;
                }

                .hero-button {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }
            }
        }
    }
}

.hero-container-services-sd-bg {
    background: url("../images/SoftwareDevHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-smallText {
                    text-align: center;
                    line-height: 41px;
                }

                .hero-button {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }
            }
        }
    }
}

.hero-container-portfolio-bg {
    background: url("../images/services-pd-bg.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-smallText {
                    text-align: center;
                    line-height: 41px;
                }

                .hero-button {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-games-bg {
    background: url("../images/services-pd-bg.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-smallText {
                    text-align: center;
                    line-height: 41px;
                }

                .hero-button {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-iGamingHomeBanner-bg {
    background: url("../images/iGamingHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-smallText {
                    text-align: center;
                    line-height: 32px;
                }

                .hero-button {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-PokerHomeBanner-bg {
    background: url("../images/PokerHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 105px;
                padding-left: 30px;

                .hero-smallText {
                    text-align: left;
                    line-height: 32px;
                }

                .hero-button-grey-to-red {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                    display: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-TeenpattiHomeBanner-bg {
    background: url("../images/TeenpattiHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 105px;
                padding-left: 30px;

                .hero-smallText {
                    text-align: left;
                    line-height: 32px;
                }

                .hero-button-grey-to-red {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                    display: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-TriviaHomeBanner-bg {
    background: url("../images/TriviaHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 105px;
                padding-left: 30px;

                .hero-smallText {
                    text-align: left;
                    line-height: 32px;
                }

                .hero-button-grey-to-red {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                    display: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-BlackjackHomeBanner-bg {
    background: url("../images/BlackjackHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 105px;
                padding-left: 30px;

                .hero-smallText {
                    text-align: left;
                    line-height: 32px;
                }

                .hero-button-grey-to-red {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                    display: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-LudoHomeBanner-bg {
    background: url("../images/LudoHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 105px;
                padding-left: 30px;

                .hero-smallText {
                    text-align: left;
                    line-height: 32px;
                }

                .hero-button-grey-to-red {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                    display: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-FantasySportsHomeBanner-bg {
    background: url("../images/FantasySportsHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 105px;
                padding-left: 30px;

                .hero-smallText {
                    text-align: left;
                    line-height: 32px;
                }

                .hero-button-grey-to-red {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                    display: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-RummyHomeBanner-bg {
    background: url("../images/RummyHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 105px;
                padding-left: 30px;

                .hero-smallText {
                    text-align: left;
                    line-height: 32px;
                }

                .hero-button-grey-to-red {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                    display: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-SportsbettingCasinoHomeBanner-bg {
    background: url("../images/SportsbettingCasinoHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;
        width: 100%;

        .hero-section1 {
            width: 100%;

            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-bottom: 105px;
                padding-left: 30px;

                .hero-smallText {
                    text-align: left;
                    line-height: 32px;
                }

                .hero-button-grey-to-red {
                    background: #EE3A38;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                    display: none;
                }

                .portfolio-hero-section {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    flex-direction: row;
                    margin-top: 110px;

                    .info-section {
                        display: flex;
                        align-items: center;

                        flex-direction: column;

                        .big-number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 72px;
                            line-height: 86.19%;
                            /* identical to box height, or 62px */

                            text-transform: capitalize;
                            margin-bottom: 30px;
                            color: #FFFFFF;

                        }

                        .red-text {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 86.19%;
                            /* identical to box height, or 19px */

                            text-transform: capitalize;

                            color: #EE3A38;

                        }
                    }
                }
            }
        }
    }
}

.hero-container-MetaverseHomeBanner-bg {
    background: url("../images/MetaverseHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-button {
                    background: #EE3A38;
                    border: none;
                    box-sizing: border-box;
                    border-radius: 2px;
                    font-family: "Roboto";
                    font-style: normal;
                    padding: 17px 30px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* or 17px */
                    margin-top: 77px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.hero-container-BlockchainHomeBanner-bg {
    background: url("../images/BlockchainHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-button {
                    background: #EE3A38;
                    border: none;
                    box-sizing: border-box;
                    border-radius: 2px;
                    font-family: "Roboto";
                    font-style: normal;
                    padding: 17px 30px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* or 17px */
                    margin-top: 77px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.hero-container-AIMLHomeBanner-bg {
    background: url("../images/AIMLHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-button {
                    background: #EE3A38;
                    border: none;
                    box-sizing: border-box;
                    border-radius: 2px;
                    font-family: "Roboto";
                    font-style: normal;
                    padding: 17px 30px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* or 17px */
                    margin-top: 77px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.hero-container-FintechHomeBanner-bg {
    background: url("../images/FintechHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-button {
                    background: #EE3A38;
                    border: none;
                    box-sizing: border-box;
                    border-radius: 2px;
                    font-family: "Roboto";
                    font-style: normal;
                    padding: 17px 30px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* or 17px */
                    margin-top: 77px;
                    color: #FFFFFF;
                }
            }
        }
    }
}


.hero-container-HealthcareHomeBanner-bg {
    background: url("../images/HealthcareHomeBanner.png");
    background-size: cover;
    background-repeat: no-repeat;

    .hero-container-inner {
        justify-content: center;

        .hero-section1 {
            .section-1-inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .hero-button {
                    background: #EE3A38;
                    border: none;
                    box-sizing: border-box;
                    border-radius: 2px;
                    font-family: "Roboto";
                    font-style: normal;
                    padding: 17px 30px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* or 17px */
                    margin-top: 77px;
                    color: #FFFFFF;
                }
            }
        }
    }
}



.homebannerslider {
    margin-top: -100px;

    .hero-container-home-bg {
        margin-top: 0px;
    }

    .blockchain-banner {
        background-image: url('../images/BlockchainHomeBanner.png');
    }
}


@media screen and (max-width: 1400px) {
    .hero-container {
        background-size: contain;
        height: 590px;
    }
}

@media screen and (max-width: 1200px) {
    .hero-container {
        background-size: cover;
    }
}



//Footer
.footer-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100%;
    max-width: unset !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

    .row {
        background-image: url(../images/footer-bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 40px 40px 94px;
        width: 100%;
        height:100%;
        justify-content: space-between;
        --bs-gutter-x: 0px;

        .column-1 {
            .socials {
                margin-top: 36px;
                display: flex;
                margin-bottom: 14px;

                a {
                    margin-right: 40px;
                    border-radius: 2px;
                    background: black;
                }
            }

            p {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 26px;
                text-align: justify;
                color: #000000;

            }
        }

        .column-2,
        .column-3 {
            flex-direction: column;

            .heading {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 86.19%;
                /* identical to box height, or 19px */

                text-transform: capitalize;
                margin-bottom: 48px;
                color: #000000;
            }

            .links {
                height: 100%;
                display: flex;
                flex-direction: column;

                a {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 21px;

                    color: #000000;
                    text-decoration: none;
                    margin-bottom: 20px;
                }
            }
        }

        .column-4 {
            display: flex;
            flex-direction: column;

            .heading {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 86.19%;
                /* identical to box height, or 19px */
                margin-bottom: 48px;
                text-transform: capitalize;

                color: #000000;
            }

            p {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 153.69%;
                /* or 25px */
                text-transform: capitalize;

                color: #000000;
            }

            .mobile-no {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 86.2%;

                color: #000000;
                margin-top: 17px;
                display: flex;
                align-items: center;
            }


        }

        .mobile-footer-links {
            .accordion {
                width: 100%;

                .accordion-item {
                    .accordion-header {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 86.19%;
                        /* identical to box height, or 19px */

                        text-transform: capitalize;
                        color: #000000;

                        .accordion-button:not(.collapsed) {
                            color: black;
                        }
                    }

                    .accordion-collapse {
                        .accordion-body {
                            .links {
                                height: 100%;
                                display: flex;
                                flex-direction: column;

                                a {
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 16px;
                                    line-height: 21px;

                                    color: #000000;
                                    text-decoration: none;
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom-row {
        height: 55px;
        width: 100%;
        display: flex;
        flex-direction: row;
        background: #D3D3D3;
        justify-content: space-between;
        align-items: center;
        padding: 17px 40px;
        --bs-gutter-x: 0px;

        .bottom-left {
            width: fit-content;
        }

        .bottom-right {
            width: fit-content;
        }

        .bottom-left,
        .bottom-right {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 125.69%;
            /* or 20px */


            color: #000000;
        }
    }
}





.contact-section {
    margin-top: 80px;

    .midtitle {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 86.19%;
        /* identical to box height, or 28px */
        text-transform: capitalize;
        color: #000000;
    }

    .midsmalltext {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 142.19%;
        /* or 31px */

        text-align: center;

        color: #595959;
    }

    .midsmalltext.bottom {
        margin-bottom: 64px;
    }

    .contact-box {
        margin: 70px 61px;

        .row {
            .col-lg-8 {
                background: #F3F3F3;
                padding: 0px;
                padding-bottom: 35px;

                .contact-form {
                    display: flex;
                    flex-direction: column;

                    .contact-input {
                        background: transparent;
                        padding: 40px 0px 40px 40px;
                        border: none;
                        width: 100%;

                        border-bottom: 1px solid #8E8E8E;

                        input::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari/Edge */
                            /*styles here*/
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 86.19%;
                            /* or 17px */

                            text-transform: capitalize;

                            color: #A8A8A8;


                        }

                        input::-ms-input-placeholder {
                            /* Microsoft Edge */
                            /*styles here*/
                        }

                        input:-ms-input-placeholder {
                            /* IE 10+ */
                            /*styles here*/
                        }

                        input::-moz-placeholder {
                            /* Firefox 19+ */
                            opacity: 1;
                            /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
                            /*styles here*/
                        }

                        input:-moz-placeholder {
                            /* Firefox 18- */
                            /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
                            /*styles here*/
                        }
                    }

                    .row {
                        width: 100%;
                        padding: 0px;
                        --bs-gutter-x: 0;

                        .col-lg-6 {
                            padding: 0px;

                            .contact-number {
                                border-right: 1px solid #8E8E8E;
                            }
                        }
                    }

                    .textarea {
                        width: 100%;

                        background: transparent;
                        padding: 40px 0px 40px 40px;
                        border: none;
                        border-bottom: 1px solid #8E8E8E;

                        input::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari/Edge */
                            /*styles here*/
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 86.19%;
                            /* or 17px */

                            text-transform: capitalize;

                            color: #A8A8A8;


                        }

                        input::-ms-input-placeholder {
                            /* Microsoft Edge */
                            /*styles here*/
                        }

                        input:-ms-input-placeholder {
                            /* IE 10+ */
                            /*styles here*/
                        }

                        input::-moz-placeholder {
                            /* Firefox 19+ */
                            opacity: 1;
                            /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
                            /*styles here*/
                        }

                        input:-moz-placeholder {
                            /* Firefox 18- */
                            /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
                            /*styles here*/
                        }
                    }

                    .contact-input:focus-visible {
                        outline: none;
                    }

                    .textarea:focus-visible {
                        outline: none;
                    }

                    .bottom-row {
                        padding-top: 17px;
                        width: 100%;

                        .col-lg-12 {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            padding-top: 20px;

                            .file-div {
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .contact-input-file {
                                    position: absolute;
                                    width: 50px;
                                    height: 50px;
                                    z-index: 11;
                                    opacity: 0;
                                    cursor: pointer;
                                }

                                .btn-file {
                                    border-radius: 50%;
                                    font-size: 36px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 50px;
                                    height: 50px;
                                    color: white;
                                    border: none;
                                    background: #EE3A38;
                                    padding-top: 0px;
                                    position: relative;
                                    z-index: 2;

                                }

                                p {
                                    width: 140px;
                                    height: 30px;
                                    margin-bottom: 0px;
                                    line-height: 0px;
                                    display: flex;

                                    align-items: center;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }

                            p {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 56.2%;
                                /* or 11px */

                                text-transform: capitalize;
                                margin-top: 10px;
                                color: #A8A8A8;

                            }

                            .contact-form-btn {
                                background: linear-gradient(180deg, #EE3A38 0%, #9D2827 100%);
                                border-radius: 6px;
                                padding: 16px 68px;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 86.19%;
                                border: none;
                                text-transform: uppercase;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }

            .col-lg-4 {
                background: url("../images/ContactUS-new.png");
                background-repeat: no-repeat;
                background-size: cover;

                .right-side-inner {
                    padding: 0px 30px 33px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    height: 100%;

                    .bigtext {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 86.19%;
                        /* or 28px */
                        margin-bottom: 10px;
                        text-transform: capitalize;

                        color: #FFFFFF;

                        .red-text {
                            color: red;
                        }
                    }

                    .smallText {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 142.19%;
                        /* or 23px */

                        text-transform: capitalize;

                        color: rgba(255, 255, 255, 0.79);

                    }
                }
            }
        }
    }
}

//home page

.home-section2 {

    .section2-upper {
        // background: #F7F7F7;
        // padding: 70px;
        padding-bottom: 0px;

        .midText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 28px;
            text-transform: capitalize;
            color: #000000;
            width: 100%;
            text-align: center;
        }

        .midSmallText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 31px;
            text-align: center;
            width: 100%;
            text-align: center;
            color: #595959;
            padding: 0 30px 0 30px;

        }

        .card-section {
            background-image: url('../images/SmallSquareBg.png');
            background-repeat: no-repeat;
            background-position: top center;
            background-position-y: -220px;
            min-height: 360px;

            .row {
                justify-content: center;

                .column-styles {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .white-circle {
                        background: #FFFFFF;
                        box-shadow: 8px 7px 10px rgba(0, 0, 0, 0.2);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 134px;
                        height: 134px;
                        border-radius: 50%;

                        .number {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 40px;
                            line-height: 34px;
                            margin-bottom: 0px;
                            text-transform: capitalize;
                            margin-top: 0px;
                            color: #EE3A38;

                        }
                    }

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 86.19%;
                        color: #555555;
                        margin-top: 25px;
                        text-align: center;
                    }
                }

                .margin-top-style {
                    margin-top: 70px;
                }

                .uneven-columns {
                    margin: 70px 10px 0px;
                }

            }
        }
    }

    .section2-lower {
        margin-top: 42px;
        // padding: 70px;
        padding-top: 0;

        .mid-title {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            //font-size: 32px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-transform: capitalize;
            color: #000000;

            p {
                width: max-content;
                position: relative;
                font-size: 28px;

            }
        }

        .sponsers {
            margin-top: 14px;
            padding-left: 50px;
            padding-right: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            img {
                width: 157px;
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                transform: scale(1);


                margin: 30px;

            }

            img:hover {
                transform: scale(1.2);
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            }
        }
    }
}

.home-section3 {
    margin-top: 83px;

    .mid-title {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        //font-size: 32px;
        line-height: 86.19%;
        /* identical to box height, or 28px */
        text-transform: capitalize;
        color: #000000;

        p {
            width: max-content;
            position: relative;
            font-size: 28px;

        }
    }

    .mid-para {
        margin-top: 27px;
        margin-bottom: 136px;
        text-align: center;
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        //font-size: 22px;
        line-height: 142.19%;

        color: #595959;
    }

    .overlap-div {
        display: flex;
        position: relative;

        .image {
            position: absolute;
            z-index: 10;
            left: 0;
            top: -72px;

            img {
                width: 353px;
            }
        }

        .info-div {
            display: flex;
            flex-direction: column;
            width: 80%;

            .red-heading {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                // font-size: 25px;
                line-height: 125.69%;
                /* or 31px */
                text-transform: uppercase;
                color: #ee3a38;
                padding-left: 230px;
            }

            .grey-container {
                background: #eaeaea;
                padding: 50px 100px;
                min-height: 289px;
                display: flex;
                justify-content: space-around;

                .list-items {
                    display: flex;
                    flex-direction: column;

                    .item {
                        margin-top: 30px;
                        display: flex;
                        align-items: center;

                        .item-text {
                            font-family: "Roboto";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            // font-size: 22px;
                            line-height: 125.69%;
                            /* identical to box height, or 28px */
                            text-transform: capitalize;
                            color: #000000;
                            margin-bottom: 0px;
                        }

                        .icon {
                            margin-right: 40px;
                        }
                    }

                    .item:nth-child(1) {
                        margin-top: 0px;
                    }
                }

                .next-button {
                    background: transparent;
                    border: 2px solid #000000;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-top: 52px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;
                    margin-right: auto;
                    position: relative;
                }

                .btn:link,
                .btn:visited {
                    transition: all .2s;
                    position: absolute;
                }

                .btn:hover {
                    transform: translateY(-3px);
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
                }

                .btn:active {
                    transform: translateY(-1px);
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
                }

                .btn-white {
                    background-color: transparent;
                    color: #777;
                }

                .btn::after {
                    content: "";
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    transition: all .4s;
                }

                .btn-white::after {
                    background-color: #fff;
                }

                .btn:hover::after {
                    transform: scaleX(1.4) scaleY(1.6);
                    opacity: 0;
                }

                .btn-animated {
                    animation: moveInBottom 2s ease-out;
                    animation-fill-mode: backwards;
                }

                @keyframes moveInBottom {
                    0% {
                        opacity: 0;
                        transform: translateY(30px);
                    }

                    100% {
                        opacity: 1;
                        transform: translateY(0px);
                    }
                }
            }

        }

        @media screen and (max-width: 1287px) {
            .info-div {
                .grey-container {
                    padding: 50px 100px;

                }
            }

            .info-div:nth-child(2) {
                .grey-container {
                    padding-right: 20px;
                }
            }

        }
    }

    .overlap-div.rightside {
        justify-content: flex-end;
        margin-bottom: 130px;
    }

    .overlap-div.leftside {
        .image {
            z-index: 10;
            right: 0;
            left: unset;
            top: -62px;
        }

        .info-div {
            .red-heading {
                text-align: right;
                padding-right: 230px;
            }
        }
    }
}

.home-section4 {
    margin-top: 110px;

    width: 100%;

    .midtitle {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        //font-size: 32px;
        line-height: 86.19%;
        /* identical to box height, or 28px */
        text-transform: capitalize;
        color: #000000;
    }

    .process-circle {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .step {
            position: absolute;

            .stepnumber {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height: 86.19%;
                /* identical to box height, or 22px */

                text-transform: capitalize;
            }

            .step-text {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height: 86.19%;
                /* identical to box height, or 22px */

                text-transform: capitalize;

                color: #000000;

            }

            .step-subtext {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 142.19%;
                /* identical to box height, or 20px */
                max-width: 250px;
            }
        }

        .work-process-image {
            width: 685px;
            height: 718px;
            background-image: url("../images/work-process.png");
            background-repeat: no-repeat;
            background-size: contain;

        }

        .step1 {
            left: 0;
            top: 0;
            transform: translate(20px, 20px);

            .stepnumber {
                color: #3AB61A;
            }
        }

        .step2 {
            left: 0;
            top: 100px;

            .stepnumber {
                color: #E8EC13;
            }
        }

        .step3 {
            left: 0;
            top: 200px;

            .stepnumber {
                color: #1DBFA2;
            }
        }

        .step4 {
            right: 0;
            top: 0;
            text-align: right;

            .stepnumber {
                color: #FAB616;
            }
        }

        .step5 {
            right: 0;
            top: 100px;
            text-align: right;

            .stepnumber {
                color: #AE198F;
            }
        }

        .step6 {
            right: 0;
            top: 200px;
            text-align: right;

            .stepnumber {
                color: #2114BC;
            }
        }

        .step7 {
            bottom: 0px;

            .stepnumber {
                color: #EE3A38;
            }
        }

        .step1 {
            top: unset !important;
            bottom: 0 !important;
            transform: translate(84px, -142px) !important;
        }

        .step2 {
            top: unset !important;
            left: 10px !important;
            bottom: 0 !important;

            transform: translate(37px, -341px) !important;
        }

        .step3 {
            top: -8px !important;
            left: 18px !important;
            bottom: unset !important;
            transform: translate(70px, 24px) !important;
        }

        .step4 {
            top: 0 !important;
            right: 0px !important;
            bottom: unset !important;
            transform: translate(-70px, 24px) !important;
        }

        .step5 {
            top: 0 !important;
            right: 0px !important;
            bottom: unset !important;

            transform: translate(-40px, 191px) !important;
        }

        .step6 {
            top: 0 !important;
            right: 0px !important;
            bottom: unset !important;
            transform: translate(-81px, 381px) !important;
        }

        .step7 {
            top: unset !important;
            bottom: -50px !important;
            margin: 0px auto !important;
            text-align: center;
        }
    }


}

.home-section5 {
    margin-top: 170px;
    padding: 40px 70px 50px;
    background: url("../images/testimonialBG.png");
    background-repeat: no-repeat;
    background-size: cover;

    .row {
        .textside {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .text-block {


                .heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 28px;
                    text-transform: capitalize;
                    color: #FFFFFF;
                }

                .para {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 43px;
                    color: #8E0302;
                }
            }
        }

        .sliderside {
            .slider-styles {
                .slick-arrow {
                    width: 35px;
                    height: 35px;

                    &::before {
                        font-size: 33px;
                    }
                }

                .slick-arrow.slick-next {
                    right: -30px;
                }

                .slick-arrow.slick-prev {
                    left: -30px;
                }

                .slick-list {
                    .slick-track {
                        .slick-slide {

                            div {
                                height: 442px;
                                display: flex;
                                align-items: flex-end;
                                justify-content: center;

                                .item-box {
                                    position: relative;
                                    height: 321px;
                                    width: 381px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: flex-start;
                                    background: #FFFFFF;
                                    box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.25);
                                    border-radius: 14px;
                                    padding: 20px;
                                    padding-top: 40px;

                                    .whitebox {
                                        position: absolute;
                                        margin-top: -90px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 140px;
                                        height: 140px;
                                        top: 0;
                                    }

                                    .icon {
                                        margin-top: 22px;
                                        margin-bottom: 13px;
                                    }

                                    .name {
                                        font-family: 'Roboto';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 24px;
                                        line-height: 21px;
                                        text-transform: capitalize;
                                        color: #000000;
                                        margin-bottom: 17px;
                                    }

                                    .para-style {
                                        font-family: 'Roboto';
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 142.19%;
                                        /* or 23px */

                                        text-align: center;
                                        margin-bottom: 0px;
                                        color: #737373;

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.home-section6 {
    margin-top: 140px;
    margin-bottom: 60px;

    .midtitle {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        //font-size: 32px;
        font-size: 28px;
        line-height: 86.19%;
        /* identical to box height, or 28px */
        text-transform: capitalize;
        color: #000000;
    }

    .midsmalltext {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        //font-size: 22px;
        font-size: 18px;
        line-height: 142.19%;
        /* or 31px */

        text-align: center;
        text-transform: capitalize;

        color: #595959;
    }

    .contact-box {
        margin: 110px 61px;

        .row {
            .col-lg-8 {
                background: #F3F3F3;
                padding: 0px;
                padding-bottom: 35px;

                .contact-form {
                    display: flex;
                    flex-direction: column;

                    .contact-input {
                        background: transparent;
                        padding: 40px 0px 40px 40px;
                        border: none;
                        width: 100%;

                        border-bottom: 1px solid #8E8E8E;

                        input::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari/Edge */
                            /*styles here*/
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 86.19%;
                            /* or 17px */

                            text-transform: capitalize;

                            color: #A8A8A8;


                        }

                        input::-ms-input-placeholder {
                            /* Microsoft Edge */
                            /*styles here*/
                        }

                        input:-ms-input-placeholder {
                            /* IE 10+ */
                            /*styles here*/
                        }

                        input::-moz-placeholder {
                            /* Firefox 19+ */
                            opacity: 1;
                            /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
                            /*styles here*/
                        }

                        input:-moz-placeholder {
                            /* Firefox 18- */
                            /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
                            /*styles here*/
                        }
                    }

                    .row {
                        width: 100%;
                        padding: 0px;
                        --bs-gutter-x: 0;

                        .col-lg-6 {
                            padding: 0px;

                            .contact-number {
                                border-right: 1px solid #8E8E8E;
                            }
                        }
                    }

                    .textarea {
                        width: 100%;

                        background: transparent;
                        padding: 40px 0px 40px 40px;
                        border: none;
                        border-bottom: 1px solid #8E8E8E;

                        input::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari/Edge */
                            /*styles here*/
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 86.19%;
                            /* or 17px */

                            text-transform: capitalize;

                            color: #A8A8A8;


                        }

                        input::-ms-input-placeholder {
                            /* Microsoft Edge */
                            /*styles here*/
                        }

                        input:-ms-input-placeholder {
                            /* IE 10+ */
                            /*styles here*/
                        }

                        input::-moz-placeholder {
                            /* Firefox 19+ */
                            opacity: 1;
                            /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
                            /*styles here*/
                        }

                        input:-moz-placeholder {
                            /* Firefox 18- */
                            /*Firefox by default has an opacity object that usually is ideal to reset so it matches webkit*/
                            /*styles here*/
                        }
                    }

                    .contact-input:focus-visible {
                        outline: none;
                    }

                    .textarea:focus-visible {
                        outline: none;
                    }

                    .bottom-row {
                        padding-top: 17px;
                        width: 100%;

                        .col-lg-6 {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 50%;

                            .file-div {
                                position: relative;

                                .contact-input-file {
                                    position: absolute;
                                    visibility: hidden;
                                    width: 50px;
                                    height: 50px;
                                }

                                .btn-file {
                                    border-radius: 50%;
                                    font-size: 36px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 40px;
                                    height: 40px;
                                    color: white;
                                    border: none;
                                    background: #EE3A38;
                                    padding-top: 0px;
                                }

                            }

                            p {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 56.2%;
                                /* or 11px */

                                text-transform: capitalize;
                                margin-top: 10px;
                                color: #A8A8A8;

                            }

                            .contact-form-btn {
                                background: linear-gradient(180deg, #EE3A38 0%, #9D2827 100%);
                                border-radius: 6px;
                                padding: 16px 68px;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 86.19%;
                                border: none;
                                text-transform: uppercase;
                                color: #FFFFFF;
                            }

                            .contact-form-btn {

                                // background: linear-gradient(180deg, #EE3A38 0%, #9D2827 100%);
                                border-radius: 6px;
                                padding: 16px 68px;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 86.19%;
                                border: none;
                                text-transform: uppercase;
                                color: #FFFFFF;
                                position: relative;
                                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                                transform: scale(1);
                                z-index: 1;

                                &:hover {
                                    transform: scale(1.2);
                                    transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);

                                }


                            }
                        }
                    }
                }
            }

            .col-lg-4 {
                background: url("../images/ContactUS-new.png");


                .right-side-inner {
                    padding: 0px 41px 33px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    height: 100%;

                    .bigtext {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 86.19%;
                        /* or 28px */
                        margin-bottom: 0px;
                        text-transform: capitalize;

                        color: #FFFFFF;

                        .red-text {
                            color: red;
                        }
                    }

                    .smallText {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 142.19%;
                        /* or 23px */

                        text-transform: capitalize;

                        color: rgba(255, 255, 255, 0.79);

                    }
                }
            }
        }
    }
}




//industries

.ind-section1 {
    margin-top: 51px;

    .mid-div {
        display: flex;
        width: 100%;
        justify-content: center;

        .midtitle {

            width: fit-content;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 86.19%;
            /* or 17px */

            text-transform: capitalize;

            color: #7A7A7A;
            position: relative;

            &::before {
                content: " ";
                position: absolute;
                height: 1px;
                width: 125px;
                background: #7A7A7A;
                left: -131px;
                top: 7px;
            }

            &::after {
                content: " ";
                position: absolute;
                height: 1px;
                width: 125px;
                background: #7A7A7A;
                right: -131px;
                top: 7px;
            }
        }
    }



    .sponser-carousel {
        margin-top: 40px;
        padding: 0px 50px;

        .carousel {
            .carousel-indicators {
                display: none;
            }

            .carousel-control-prev {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                left: 50%;
                transform: translate(-150%, -50%);
                top: 100px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    background-size: contain;
                    width: 12px;
                    height: 14px;
                }
            }

            .carousel-control-next {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                right: 50%;
                transform: translate(150%, -50%);
                top: 100px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    background-size: contain;
                    width: 12px;
                    height: 14px;
                }
            }

            .carousel-inner {
                .carousel-item {
                    .carousel-item1 {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 64px;
                        padding: 0px 25px;

                        /* img {
                            width: 181.74px;
                            height: 44px;
                        } */
                        img {
                            width: 170px;
                            transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                            transform: scale(1);


                        }

                        img:hover {
                            transform: scale(1.2);
                            transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                        }

                    }
                }
            }
        }
    }

    .sponsers {
        margin-top: 14px;
        padding-left: 50px;
        padding-right: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;

        img {
            width: 157px;
            transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            transform: scale(1);

            margin: 10px;
        }

        img:hover {
            transform: scale(1.2);
            transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
        }
    }
}

.ind-section3 {

    background: #EE3A38;
    background-image: url("../images/red-bg-design.png");
    background-repeat: no-repeat;
    background-position: left;
    min-height: 422px;

    .row {
        --bs-gutter-x: 0;

        .col-lg-6 {

            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 65px;
            padding-top: 40px;

            .heading {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 86.19%;
                /* identical to box height, or 28px */

                text-transform: capitalize;

                color: #000000;
            }

            .text {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 142.19%;
                /* or 31px */
                margin-top: 35px;
                color: #FFFFFF;
            }

            .btn-download {
                background: linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 100%);
                border: 1px solid #D5D5D5;
                box-sizing: border-box;
                border-radius: 2px;
                padding: 17px 30px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 86.19%;
                /* or 17px */
                width: fit-content;
                margin-top: 56px;
                color: #EE3A38;

            }
        }

        .right-column {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.ind-section4 {
    margin-top: 70px;
    margin-bottom: 90px;

    .midtitle {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 86.19%;
        /* identical to box height, or 28px */
        text-transform: capitalize;
        color: #000000;
        margin-bottom: 30px;
    }

    .midsmalltext {
        display: flex;
        width: 100%;
        padding: 0px 170px;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 142.19%;
        /* or 31px */

        text-align: center;

        color: #595959;
        margin-bottom: 66px;
    }

    /* .award-carousel {
        padding-left: 70px;
        padding-right: 70px;

        .carousel {

            .carousel-indicators {
                display: none;
            }

            .carousel-control-prev {
                width: 30px;
                height: 30px;
                top: 50px;
                left: -60px;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 1;

                span {
                    background-size: contain;
                    width: 20px;
                    height: 23px;
                }
            }

            .carousel-control-next {
                width: 30px;
                height: 30px;
                top: 50px;
                right: -60px;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 1;

                span {
                    background-size: contain;
                    width: 20px;
                    height: 23px;
                }
            }

            .carousel-inner {
                .carousel-item {
                    height: 200px;

                    .carousel-item1 {

                        display: flex;
                        justify-content: space-between;
                        padding: 4px;

                        .awards {
                            background: linear-gradient(180deg, #4B4B4B 0%, #141414 100%);
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 6px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 203px;
                            height: 153px;
                        }

                    }
                }
            }
        }
    } */

    .award-carousel {
        padding-left: 70px;
        padding-right: 70px;

        .slick-slider {
            .slick-list {
                .slick-track {
                    height: 153px;
                    overflow-y: hidden;
                    display: flex;
                    justify-content: space-between;

                    .slick-slide {
                        margin-left: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        div {

                            .awards {
                                background: linear-gradient(180deg, #4B4B4B 0%, #141414 100%);
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                border-radius: 6px;
                                display: flex !important;
                                justify-content: center;
                                align-items: center;
                                width: 203px !important;
                                height: 153px;
                            }
                        }

                    }
                }
            }
        }
    }
}



.big-card {
    width: 100%;
    height: 475px;
    background: url('../images/card-dot-bg.png');
    background-position: left;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    position: relative;
    margin: 100px 0px;

    .card-inner {
        position: absolute;
        z-index: 10;
        right: 0;
        height: 475px;
        width: 84%;
        background: black;

    }

    .next-box {
        background: white;
        display: flex;
        flex-direction: column;
        padding: 50px;
        z-index: 11;
        min-width: 600px;
        min-height: 185px;
        padding-bottom: 20px;
        position: relative;

        .bigNumber {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 70px;
            line-height: 126.2%;
            color: #E9E9E9;
            position: absolute;
            left: 50px;
            top: 7px;
            z-index: 2;
        }


        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 28px;
            color: #EE3A38;
            position: relative;
            z-index: 3;
        }

        .desc-text {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            max-width: 488px;
            color: #000000;
            display: flex;
            align-items: center;

            position: relative;
            z-index: 3;

            .icon {
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                transform: scale(1);

            }

            .icon:hover {
                transform: scale(1.2);
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            }
        }
    }

    .card-1-bg {
        background: url("../images/card-1-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .card-2-bg {
        background: url("../images/MetaverseNewPoster.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 85%;
        height: 100%;
    }

    .card-3-bg {
        background: url("../images/card-3-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 85%;
        height: 100%;
    }

    .card-4-bg {
        background: url("../images/card-4-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 85%;
        height: 100%;
    }

    .card-5-bg {
        background: url("../images/card-5-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 85%;
        height: 100%;
    }

    .card-6-bg {
        background: url("../images/AIMLNewPoster.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 85%;
        height: 100%;
    }

    .card-7-bg {
        background: url("../images/card-7-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .GoldenCardBackground {
        background: url("../images/GamingNewBanner.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 85%;
       height: 100%;

    }

}



//aboutus
.about-section {
    margin: 70px 70px;
    margin-bottom: 0px;

    .section1 {
        display: flex;
        flex-direction: column;

        .info-div {
            display: flex;
            width: 100%;

            .grey-box {
                background: #F5F5F5;
                width: 20%;
                height: 270px;
                background-image: url("../images/DC-icon.png");
                background-size: auto;
                background-position: center;
                background-repeat: no-repeat;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 334px;
                margin-right: 70px;

                .redtext {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 72px;
                    line-height: 86.19%;
                    /* or 62px */

                    text-transform: capitalize;

                    color: #EE3A38;
                }

                .greytext {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 62px;
                    line-height: 86.19%;
                    /* or 62px */

                    text-transform: capitalize;

                    color: #999898;

                }
            }

            .info-text {
                width: 70%;
                display: flex;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 31px;
                color: #595959;
                text-align: justify;
            }
        }

        .info-div.reverse {
            margin-top: 60px;
            flex-direction: row-reverse;

            .grey-box {
                margin-right: 0px;
                margin-left: 70px;
            }
        }
    }

    .section2 {
        margin-top: 100px;

        .midtitle {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 28px;
            text-transform: capitalize;
            color: #000000;
            margin-bottom: 30px;
        }

        .midsmalltext {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 31px;
            text-align: center;

            color: #595959;
            margin-bottom: 66px;
        }

        .company-div {

            .row,
            .row-second {
                .col {
                    min-width: 164px;
                    min-height: 100px;
                }
            }

            .row-second {
                margin-top: 100px;
            }
        }
    }

    .section3 {
        margin-top: 100px;
        margin-bottom: 100px;

        .midtitle {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-transform: capitalize;
            color: #000000;
            margin-bottom: 30px;
        }

        .midsmalltext {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 142.19%;
            /* or 31px */

            text-align: center;

            color: #595959;
            margin-bottom: 66px;
        }

        .team-area {
            .row {
                .top-row {
                    display: flex;
                    width: 100%;
                    justify-content: center;

                    .person-desc-box:nth-child(1) {
                        margin-right: 90px;
                    }
                }

                .second-row {
                    display: flex;
                    width: 100%;
                    margin-top: 100px;
                    justify-content: space-around;

                    .person-desc-box {
                        .image-box {
                            width: 178px;
                            height: 178px;

                            img {
                                width: 168px;
                                height: 168px;
                            }
                        }

                        .person-name {
                            font-size: 30px;
                        }

                        .person-designation {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

}

//person-description
.person-desc-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-box {
        background: rgba(0, 0, 0, 0.08);
        width: 222px;
        height: 222px;
        border-radius: 50%;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 206px;
            height: 206px;
            border-radius: 50%;
        }
    }

    .person-name {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 86.19%;
        /* or 29px */
        margin-top: 25px;

        text-transform: capitalize;

        color: #707070;
    }

    .person-designation {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 86.19%;
        /* or 17px */

        text-transform: capitalize;

        color: #EE3A38;

    }
}


//contactus
.contactus-section {
    margin-top: 60px;

    .section1 {
        overflow-x: hidden;
        padding-bottom: 10px;

        .row {
            --bs-gutter-x: 0px;
            margin-left: 0px;
            margin-right: 0px;

            .col-lg-5 {
                padding: 0 90px;
                background-image: url("../images/client-speak-bg.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: auto;
                padding-right: 0px;

                .carousel {
                    position: relative;
                    width: 100%;

                    .carousel-indicators {
                        display: none;
                    }

                    .carousel-control-prev {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                        left: 50%;
                        transform: translate(-150%, -50%);
                        bottom: -80px;
                        top: unset;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 1;

                        span {
                            background-size: contain;
                            width: 12px;
                            height: 14px;
                        }
                    }

                    .carousel-control-next {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                        right: 50%;
                        transform: translate(150%, -50%);
                        bottom: -80px;
                        top: unset;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 1;

                        span {
                            background-size: contain;
                            width: 12px;
                            height: 14px;
                        }
                    }

                    .carousel-inner {
                        .carousel-item {
                            .carousel-item1 {
                                .smart-guide {

                                    position: relative;
                                    display: flex;
                                    height: 100%;
                                    justify-content: center;
                                    align-items: center;

                                    .client-text {
                                        top: 40px;
                                        left: 0px;
                                        position: absolute;
                                        font-family: 'Roboto';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 40px;
                                        line-height: 86.19%;
                                        /* or 34px */

                                        text-align: center;

                                        color: #FFCFCF;

                                    }

                                    .info-div {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        margin-top: 200px;

                                        .image-box {

                                            width: 150px;
                                            height: 150px;
                                            border-radius: 50%;
                                            padding: 4px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            img {
                                                width: 150px;
                                                height: 150px;
                                                border-radius: 50%;
                                            }
                                        }

                                        .name {
                                            font-family: 'Roboto';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 30px;
                                            line-height: 56.2%;
                                            /* identical to box height, or 17px */

                                            text-transform: capitalize;
                                            margin-top: 28px;
                                            color: #000000;
                                        }

                                        .desig {
                                            font-family: 'Roboto';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 20px;
                                            line-height: 56.2%;
                                            /* or 11px */

                                            text-transform: capitalize;
                                            margin-top: 23px;
                                            color: #EE3A38;
                                        }

                                        .info-text {
                                            font-family: 'Roboto';
                                            font-style: normal;
                                            font-weight: 300;
                                            font-size: 16px;
                                            line-height: 159.19%;
                                            /* or 25px */

                                            text-align: center;
                                            margin-top: 46px;
                                            color: #6D6D6D;

                                        }
                                    }
                                }
                            }
                        }
                    }
                }


            }

            .col-lg-6 {
                padding-right: 20px;

                .request-to-demo {
                    width: 95%;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
                    border-radius: 20px 20px 0px 0px;
                    margin-right: 20px;

                    .top-text {
                        border-radius: 20px 20px 0px 0px;
                        background: #F0F0F0;
                        padding: 30px 0px;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 30px;
                        line-height: 86.19%;
                        /* identical to box height, or 26px */

                        text-align: center;
                        text-transform: capitalize;

                        color: #575757;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                    }

                    .contact-form {
                        display: flex;
                        flex-direction: column;
                        padding: 55px 40px;

                        .contact-input {
                            border: none;
                            background: transparent;
                            padding-bottom: 24px;
                            padding-left: 20px;
                            border-bottom: 1px solid #A8A8A8;
                            color: #A8A8A8;

                            width: 100%;

                            input::-webkit-input-placeholder {
                                /* Chrome/Opera/Safari/Edge */
                                /*styles here*/
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 56.2%;
                                /* or 11px */

                                text-transform: capitalize;

                                color: #A8A8A8;

                            }

                        }

                        .contact-input:focus {
                            outline: none;
                        }

                        .row {
                            margin-top: 20px;
                        }

                        .textarea {
                            background: #F1F1F1;
                            border-radius: 4px;
                            padding: 30px;
                            min-height: 146px;
                            width: 100%;
                            margin-top: 60px;
                            border: none;
                            resize: none;
                            color: #A8A8A8;

                            input::-webkit-input-placeholder {
                                /* Chrome/Opera/Safari/Edge */
                                /*styles here*/
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 56.2%;
                                /* or 11px */

                                text-transform: capitalize;

                                color: #A8A8A8;

                            }
                        }

                        .bottom-row {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            align-items: center;

                            .upper-div {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;

                                p {
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 20px;
                                    line-height: 56.2%;
                                    /* or 11px */

                                    text-transform: capitalize;

                                    color: #A8A8A8;
                                }

                                .file-div {
                                    position: relative;

                                    .contact-input-file {
                                        visibility: hidden;
                                        position: absolute;
                                        z-index: 11;
                                    }

                                    .btn-file {
                                        border-radius: 50%;
                                        font-size: 36px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 46px;
                                        height: 46px;
                                        color: white;
                                        border: none;
                                        background: #EE3A38;
                                        padding-top: 0px;
                                    }
                                }
                            }

                            .contact-form-btn {
                                margin-top: 64px;
                                background: #EE3A38;
                                border-radius: 6px;
                                padding: 16px 68px;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 20px;
                                line-height: 86.19%;
                                border: none;
                                text-transform: uppercase;
                                color: #FFFFFF;
                                text-transform: uppercase;

                                color: #FFFFFF;

                            }

                            .grey-subtext {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 15px;
                                line-height: 22px;
                                /* or 8px */

                                text-transform: capitalize;

                                color: #A8A8A8;
                                margin-top: 24px;
                                text-align: center;
                            }
                        }
                    }
                }
            }

            .col-lg-6:nth-child(2) {
                padding-right: 0px;
            }
        }
    }

    .section2 {
        margin-top: 100px;
        background: #EE3a38;
        min-height: 406px;
        position: relative;
        margin-bottom: 250px;

        .center-box {
            margin-top: 150px;
            width: 965px;
            height: 422px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.09);
            border-radius: 21px 21px 0px 0px;

            .left-box {
                width: 50%;
                background: #FFFFFF;
                border-radius: 21px 0px 0px 0px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .suggestion {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 30px;
                    line-height: 120.19%;
                    /* or 36px */

                    text-align: right;
                    text-transform: capitalize;

                    color: #484848;

                }

                .btn-box {
                    margin-top: 60px;
                    display: flex;

                    .btn-red {

                        background: #EE3A38;
                        border: none;

                        a {

                            border-radius: 2px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 86.19%;
                            /* or 17px */
                            padding: 17px 34px;
                            text-decoration: none;
                            color: #FFFFFF;
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                        }

                    }

                    .btn-red:nth-child(1) {
                        margin-right: 20px;
                    }
                }
            }

            .right-box {
                width: 50%;
                height: 100%;
                background: url("../images/buisness.png");
                background-size: auto;
                background-repeat: no-repeat;
                border-radius: 0px 21px 0px 0px;
            }
        }
    }

    .section3 {
        .midtitle {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-transform: capitalize;
            color: #000000;
        }

        .midsmalltext {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 142.19%;
            /* or 31px */

            text-align: center;

            color: #595959;
        }

        .office-carousel {
            margin-top: 0px;
            margin-bottom: 46px;
            padding: 0px;

            div {
                div {
                    .coverflow__container__1P-xE {
                        background: transparent;

                        .coverflow__stage__14oqC {
                            figure {
                                box-shadow: none;
                                -webkit-box-reflect: unset;
                            }
                        }
                    }
                }
            }

        }
    }

    .section4 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 75px;

        .mid-text {
            width: 100%;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            text-align: center;
            text-transform: capitalize;

            color: #000000;
        }

        .details {
            display: flex;
            align-items: center;

            .mobile-no {
                display: flex;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 18px;
                margin-right: 40px;
                text-transform: lowercase;

                color: #000000;

            }
        }


    }
}




//services-page

.services-section {

    .section1 {
        margin-top: 46px;
        display: flex;
        justify-content: space-around;
        margin-bottom: 80px;

        .red-box {
            background: #EE3A38;
            height: 94px;
            padding-right: 40px;
            display: flex;
            justify-content: center;
            width: 230px;

            .redbox-inner {
                a {
                    align-items: center;
                    justify-content: flex-end;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    text-decoration: none;
                    transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                    transform: scale(1);

                    .right-side {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 114.19%;
                        /* or 18px */
                        text-decoration: none;

                        color: #FFFFFF;
                        width: 53%;
                    }
                }

            }

            .redbox-inner:hover {
                transform: scale(1.2);
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);

                cursor: pointer;
            }

        }


    }

    @media screen and (max-width: 1287px) {
        .section1 {
            .red-box {
                min-width: unset;
                padding-right: 0px;
            }
        }
    }
}


.service-card-container {
    padding: 80px 60px;
    background: #FAFAFA;

    .row {
        .col-lg-7 {
            padding-right: 70px;

            .heading {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 30px;
                line-height: 86.19%;
                /* identical to box height, or 28px */

                color: #EE3A38;
            }

            .service-para {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 142.2%;
                /* or 31px */
                margin-top: 60px;
                text-align: justify;

                color: #595959;

            }

            .services {
                margin-top: 60px;

                h2 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 86.19%;
                    /* or 22px */
                    color: #000000;

                }

                .services-lists {
                    margin-top: 33px;
                    position: relative;
                    padding-left: 35px;

                    &::before {
                        position: absolute;
                        left: 0;
                        height: 110%;
                        width: 3px;
                        background: #FF0000;
                        content: "";
                        border-radius: 20px;
                    }

                    .service-step {
                        list-style: none;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 86.19%;
                        /* or 17px */
                        margin-bottom: 43px;

                        color: #595959;
                        display: flex;
                        align-items: center;
                    }

                    .service-step:nth-child(1) {
                        padding-top: 10px;
                    }
                }
            }
        }

        .col-lg-5 {
            .image-container {
                border-radius: 9px;
                width: 467px;
                height: 405px;
            }

            .image-container-product-development {
                background-image: url("../images/employees.png");
                background-repeat: no-repeat;
                background-size: auto;
                width:100%;
                height:100%;
            }

            .image-container-thinking-and-design {
                background-image: url("../images/youngmale.png");
                background-repeat: no-repeat;
                background-size: auto;
                width:100%;
                height:100%;
            }

            .image-container-software-development {
                background-image: url("../images/coding-man.png");
                background-repeat: no-repeat;
                background-size: auto;
                width:100%;
                height:100%;
            }

            .image-container-product-growth {
                background-image: url("../images/five-glasses.png");
                background-repeat: no-repeat;
                background-size: auto;
                width:100%;
                height:100%;
            }

            .image-container-research-and-innovative {
                background-image: url("../images/paper-analysis.png");
                background-repeat: no-repeat;
                background-size: auto;
                width:100%;
                height:100%;
            }


            .link-container {
                margin-top: 60px;
                display: flex;
                align-items: center;

                .service-link {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 86.19%;


                    color: #EE3A38;
                    text-decoration: none;

                }
            }

            .link-container {
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                transform: scale(1);
            }

            .link-container:hover {
                transform: scale(1.0);
                margin-left: 10px;
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            }
        }
    }
}

.service-card-container.reverse {
    background: white;

    .row {
        flex-direction: row-reverse;
    }
}

@media screen and (max-width: 1287px) {
    .service-card-container.reverse {
        .row .col-lg-7 {

            padding-right: 0px;
            padding-left: 40px;
        }
    }

    .service-card-container {
        padding: 80px 40px;
    }

}

//productdev
.productdev-section {
    margin-top: 70px;

    .section1 {
        .breadcrumb {
            width: 100%;
            padding-left: 70px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 86.19%;
            /* or 17px */
            display: flex;
            align-items: center;

            color: #989898;

            a {
                color: #989898;
                text-decoration: none;

                &:hover {
                    color: black;
                }
            }

            .active {
                color: black;
                margin-left: 10px;
            }
        }
    }

    .section2 {
        margin: 110px 30px;

        .row {
            .col-lg-6 {
                .img-div {
                    img{
                        height: 100%;
                        width: 100%;
                    }
                    
                
                }

                .text-div {
                    padding-top: 20px;

                    .heading {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 30px;
                        line-height: 86.19%;
                        /* identical to box height, or 31px */

                        text-transform: capitalize;

                        color: #EE3A38;
                        margin-bottom: 37px;
                    }

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 159.19%;
                        /* or 29px */
                        text-align: justify;

                        color: #595959;

                    }
                }
            }
        }
    }

    .section3 {

        background-color: #EE3a38;
        min-height: 290px;
        position: relative;
        margin-bottom: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 48px;
        background-image: url(../images/darkSpots.png);

        .black-heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 86.19%;
            /* identical to box height, or 28px */

            text-transform: capitalize;

            color: #870807;

        }

        .white-heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 142.19%;
            /* or 31px */

            text-align: center;

            color: #FFFFFF;

        }

        .center-box {
            margin-top: 180px;
            width: 100%;
            position: absolute;

            .slider-styles {
                .slick-list {
                    .slick-track {
                        .slick-slide {
                            div {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                .icon-boxes {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    max-width: 240px;

                                    .icon-box {
                                        background: #FFFFFF;
                                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                        border-radius: 4px;
                                        width: 116px;
                                        height: 116px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }

                                    h2 {
                                        font-family: 'Roboto';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 26px;
                                        line-height: 86.19%;
                                        /* or 22px */

                                        text-transform: capitalize;

                                        color: #EE3A38;
                                        margin: 47px 0px 23px 0px;
                                    }

                                    p {
                                        font-family: 'Roboto';
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 20px;
                                        line-height: 142.19%;
                                        /* or 31px */

                                        text-align: center;
                                        color: #4B4B4B;

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .icon-boxes {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 240px;

                .icon-box {
                    background: #FFFFFF;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 4px;
                    width: 116px;
                    height: 116px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                h2 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 86.19%;
                    /* or 22px */

                    text-transform: capitalize;

                    color: #EE3A38;
                    margin: 47px 0px 23px 0px;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 142.19%;
                    /* or 31px */

                    text-align: center;
                    color: #4B4B4B;

                }
            }
        }
    }

    .section4 {


        margin: 182px 60px 146px 60px;

        .mid-title {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-transform: capitalize;
            color: #000000;
        }

        .sponsers {
            margin-top: 14px;
            padding-left: 50px;
            padding-right: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            img {
                width: 157px;
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                transform: scale(1);

                margin: 10px;
            }

            img:hover {
                transform: scale(1.2);
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            }
        }

    }

    .section5 {
        background: #F5F5F5;
        padding-top: 73px;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 107px;

        .mid-title {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 86.19%;
            /* identical to box height, or 28px */

            text-transform: capitalize;

            color: #EE3A38;
            margin-bottom: 22px;


        }

        .midsmalltext {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 142.19%;
            /* or 31px */

            text-align: center;

            color: #595959;
            margin-bottom: 90px;
        }

        .mid-section {
            .row {
                display: flex;

                .col-lg-6 {
                    justify-content: center;
                    display: flex;

                    .mid-box {
                        position: relative;

                        .left-side {
                            position: absolute;
                            left: 0;
                            top: 4px;
                            border-radius: 50%;
                            width: 144px;
                            height: 144px;
                            background: #FFFFFF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .blue {
                            border: 4px solid #27BCEB;
                            box-sizing: border-box;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                        }

                        .red {
                            border: 4px solid #EE3A38;
                            box-sizing: border-box;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        }

                        .yellow {
                            border: 4px solid #FAB616;
                            box-sizing: border-box;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        }

                        .green {
                            border: 4px solid #5DB434;
                            box-sizing: border-box;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        }

                        .right-side {
                            max-width: 461px;
                            margin-left: 90px;

                            h2 {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 24px;
                                line-height: 86.19%;
                                /* identical to box height, or 21px */

                                text-transform: capitalize;
                                padding-left: 50px;
                                color: #000000;
                            }

                            .round-div {
                                background: #FFFFFF;
                                padding: 23px 53px 24px 74px;
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                border-radius: 0px 60px 60px 0px;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 142.19%;
                                /* or 26px */


                                color: #595959;

                            }
                        }
                    }
                }
            }

            .row:nth-child(2) {
                margin-top: 140px;

            }
        }

    }

    .section6 {
        margin: 120px 0px;

        .midtitle {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-transform: capitalize;
            color: #000000;
        }

        .recognised-carousel {
            margin-top: 70px;
            padding-left: 70px;
            padding-right: 70px;

            .carousel {

                .carousel-indicators {
                    display: none;
                }

                .carousel-control-prev {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    left: 47%;
                    transform: translate(-50%, -50%);
                    bottom: -80px;
                    top: unset;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;

                    span {
                        background-size: contain;
                        width: 12px;
                        height: 14px;
                    }
                }

                .carousel-control-next {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    right: 47%;
                    transform: translate(-50%, -50%);
                    bottom: -80px;
                    top: unset;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;

                    span {
                        background-size: contain;
                        width: 12px;
                        height: 14px;
                    }
                }

                .carousel-inner {
                    .carousel-item {
                        .carousel-item1 {
                            display: flex;
                            justify-content: space-between;

                            .company-div {
                                max-width: 210px;
                                display: flex;
                                flex-direction: column;

                                .batch-div {
                                    margin-top: 50px;
                                    margin-bottom: 18px;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 800;
                                    font-size: 22px;
                                    line-height: 142.19%;
                                    /* or 31px */

                                    text-transform: capitalize;

                                    color: #EE3A38;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

}

.thinking-design-section {
    margin-top: 70px;
    overflow: hidden;

    .section1 {
        .breadcrumb {
            width: 100%;
            padding-left: 70px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 86.19%;
            /* or 17px */
            display: flex;
            align-items: center;

            color: #989898;

            a {
                color: #989898;
                text-decoration: none;

                &:hover {
                    color: black;
                }
            }

            .active {
                color: black;
                margin-left: 10px;
            }
        }
    }

    .section2 {
        margin: 110px 80px;

        .big-redText {
            width: 100%;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 86.19%;
            /* identical to box height, or 31px */

            text-transform: capitalize;
            margin-bottom: 31px;
            color: #EE3A38;
        }

        .midtext {
            width: 100%;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 142.19%;
            /* or 31px */
            text-align: center;
            color: #000000;
            margin-bottom: 15px;
        }

        .midtext.blackbold {
            font-weight: 500;
            margin-bottom: 30px;
        }

        .para-text {
            width: 100%;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 159.19%;


            text-align: center;

            color: #595959;

        }
    }

    .section3 {
        width: 100%;

        position: relative;

        .row {
            min-height: 415px;
            align-items: center;

            background-color: #F5F5F5;
            background-image: url("../images/background-boxes.png");
            height: 100%;

            background-position: right;
            background-repeat: no-repeat;
            background-position-y: 10px;

            .left-side {
                width: 50%;
                height: 100%;

                img {
                    width: 100%;
                    height: 415px;
                }
            }

            .right-side-outer {


                .right-side {
                    height: 100%;
                    padding: 0px 80px 120px 80px;

                    background-position: right;
                    background-repeat: no-repeat;

                    .heading {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 30px;
                        line-height: 86.19%;
                        /* identical to box height, or 28px */

                        text-transform: capitalize;
                        margin-bottom: 35px;
                        color: #EE3A38;
                    }

                    .smallText {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 142.19%;
                        /* or 28px */


                        color: #000000;
                    }
                }
            }

        }

        .list-section {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            padding: 0 37px;

            ul {
                li {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 218.19%;
                    /* or 39px */

                    text-transform: capitalize;

                    color: #595959;

                }
            }
        }
    }

    .section4 {


        margin: 50px 60px 46px 60px;

        .mid-title {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-transform: capitalize;
            color: #000000;
        }

        .sponsers {
            margin-top: 14px;
            padding-left: 50px;
            padding-right: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            img {
                width: 157px;
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                transform: scale(1);

                margin: 10px;
            }

            img:hover {
                transform: scale(1.2);
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            }
        }
    }

    .section5 {

        padding-top: 73px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 0px;

        .mid-title {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 86.19%;
            /* identical to box height, or 28px */

            text-transform: capitalize;

            color: #EE3A38;
            margin-bottom: 22px;


        }

        .midsmalltext {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 142.19%;
            /* or 31px */

            text-align: center;

            color: #000000;

            margin-bottom: 90px;
        }

        .mid-section {
            .slider-styles {
                .slick-list {
                    .slick-track {
                        height: 440px;
                        overflow-y: hidden;
                        .slick-slide {
                            margin: 0px 20px;
                            div {
                                .process-box {
                                    position: relative;
                                    height: 433px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .iconbox {
                                        position: relative;
                                        border-radius: 50%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 120px;
                                        height: 120px;
                                        background: #FFFFFF;
                                        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.25);

                                        margin-bottom: -50px;
                                    }

                                    .redbox {
                                        width: 305px;
                                        height: 233px;
                                        background: #EE3A38;
                                        border-radius: 14px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex-direction: column;
                                        padding: 56px 17px;
                                        padding-top: 72px;

                                        .heading {
                                            font-family: 'Roboto';
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 24px;
                                            line-height: 86.19%;
                                            text-transform: capitalize;
                                            color: #FFFFFF;
                                        }

                                        .content {
                                            font-family: 'Roboto';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 18px;
                                            line-height: 142.19%;
                                            text-align: center;
                                            color: #6A201F;

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .section6 {
        margin: 120px 0px;
        margin-top: 0px;

        .midtitle {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-transform: capitalize;
            color: #000000;
        }

        .recognised-carousel {
            margin-top: 70px;
            padding-left: 70px;
            padding-right: 70px;

            .carousel {

                .carousel-indicators {
                    display: none;
                }

                .carousel-control-prev {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    left: 47%;
                    transform: translate(-50%, -50%);
                    bottom: -80px;
                    top: unset;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;

                    span {
                        background-size: contain;
                        width: 12px;
                        height: 14px;
                    }
                }

                .carousel-control-next {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    right: 47%;
                    transform: translate(-50%, -50%);
                    bottom: -80px;
                    top: unset;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;

                    span {
                        background-size: contain;
                        width: 12px;
                        height: 14px;
                    }
                }

                .carousel-inner {
                    .carousel-item {
                        .carousel-item1 {
                            display: flex;
                            justify-content: space-between;

                            .company-div {
                                max-width: 210px;
                                display: flex;
                                flex-direction: column;

                                .batch-div {
                                    margin-top: 50px;
                                    margin-bottom: 18px;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 800;
                                    font-size: 22px;
                                    line-height: 142.19%;
                                    /* or 31px */

                                    text-transform: capitalize;

                                    color: #EE3A38;
                                }
                            }
                        }
                    }
                }
            }
        }

    }


}

.software-dev-section {
    margin-top: 70px;
    overflow: hidden;

    .section1 {
        .breadcrumb {
            width: 100%;
            padding-left: 70px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 86.19%;
            /* or 17px */
            display: flex;
            align-items: center;

            color: #989898;

            a {
                color: #989898;
                text-decoration: none;

                &:hover {
                    color: black;
                }
            }

            .active {
                color: black;
                margin-left: 10px;
            }
        }
    }

    .section2,
    .section4,
    .section6 {
        margin: 70px 0px;
        display: flex;

        .left-side {
            width: 50%;
        }

        .right-side {
            width: 50%;

            .content-section {
                padding: 60px;

                .redHeading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 35px;
                    line-height: 120.19%;

                    text-transform: capitalize;
                    margin-bottom: 0px;
                    color: #EE3A38;
                }

                .blackHeading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 30px;
                    line-height: 120.19%;

                    text-transform: capitalize;

                    color: #4D4D4D;
                }

                .para {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 142.19%;
                    /* or 26px */

                    text-align: justify;

                    color: #595959;
                }
            }
        }

    }

    .section3,
    .section5,
    .section7 {
        .row {
            padding-left: 10px !important;
            padding-right: 10px !important;
            justify-content: space-between;

            .left-side {
                .content-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .redbox {
                        width: 116px;
                        height: 116px;
                        background: #EE3A38;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 40px;
                    }

                    .heading {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 22px;
                        /* or 22px */

                        text-align: center;
                        text-transform: capitalize;

                        color: #000000;
                        height: 44px;
                    }

                    .content {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 142.19%;
                        /* or 26px */

                        text-align: center;
                        letter-spacing: -0.03em;

                        color: #4B4B4B;

                    }
                }
            }
        }
    }

    .section2,
    .section3 {
        background: #F5F5F5;
    }

    .section2 {
        margin-bottom: 0px;
        padding-bottom: 100px;

        .left-side {
            padding-left: 60px;
            padding-top: 60px;

            img {
                width: 100%;
            }
        }

        .right-side {
            background-image: url('../images/background-boxes.png');
            background-position: right center;
            background-repeat: no-repeat;
            background-position-y: 10px;
        }
    }

    .section4 {
        flex-direction: row-reverse;

        .left-side {
            padding-right: 60px;
            padding-top: 60px;

            img {
                width: 100%;
            }
        }
    }

    .red-carousel-section {
        position: relative;
        margin-top: 60px;

        .upper-div {
            background-color: #EE3a38;
            min-height: 290px;
            position: relative;
            margin-bottom: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 48px;
            padding-bottom: 30px;
            background-image: url("../images/darkSpots.png");

            .big-redText {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                line-height: 86.19%;
                text-transform: capitalize;
                color: #870807;
            }

            .para {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 142.19%;
                /* or 31px */

                text-align: center;

                color: #FFFFFF;
            }
        }

        .lower-div {
            position: absolute;
            bottom: -148px;
            width: 100%;

            .slider-styles {
                .slick-list {
                    .slick-track {
                        .slick-slide {
                            div {
                                .item-box {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .whitebox {
                                        background: #FFFFFF;
                                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                        border-radius: 50%;
                                        width: 116px;
                                        height: 116px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }

                                    p {
                                        font-family: 'Roboto';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 22px;
                                        line-height: 86.19%;
                                        margin-top: 30px;
                                        text-align: center;
                                        text-transform: capitalize;

                                        color: #595959;

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.product-growth-section {
    margin-top: 70px;

    .section1 {
        margin-bottom: 100px;

        .breadcrumb {
            width: 100%;
            padding-left: 70px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 86.19%;
            /* or 17px */
            display: flex;
            align-items: center;

            color: #989898;

            a {
                color: #989898;
                text-decoration: none;

                &:hover {
                    color: black;
                }
            }

            .active {
                color: black;
                margin-left: 10px;
            }
        }
    }



    .section2 {

        background-color: #EE3a38;
        min-height: 290px;
        position: relative;
        margin-bottom: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 48px;
        background-image: url(../images/darkSpots.png);

        .black-heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 38px;
            text-transform: capitalize;
            text-align: center;
            color: #870807;

        }

        .white-heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 31px;
            text-align: center;

            color: #FFFFFF;

        }

        .center-box {
            margin-top: 180px;
            width: 100%;
            position: absolute;

            .slider-styles {
                .slick-list {
                    .slick-track {
                        .slick-slide {
                            div {
                                .item-box {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .whitebox {
                                        background: #FFFFFF;
                                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                        border-radius: 4px;
                                        width: 116px;
                                        height: 116px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }

                                    p {
                                        font-family: 'Roboto';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 20px;
                                        line-height: 26px;
                                        margin-top: 30px;
                                        text-align: center;
                                        text-transform: capitalize;

                                        color: #595959;

                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }


    .section3 {
        background: #F5F5F5;
        padding-top: 73px;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 107px;
        background-image: url("../images/background-boxes.png");
        background-repeat: no-repeat;
        background-position: left;
        background-position-y: 20px;

        .mid-title {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 28px;
            text-transform: capitalize;
            color: #000000;

            margin-bottom: 40px;
        }

        .midsmalltext {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 36px;

            text-align: center;
            padding: 0 30px;
            color: #EE3A38;
        }

        .mid-section {
            margin-top: 70px;

            .row {
                display: flex;
                justify-content: space-evenly;

                .col-lg-5 {
                    justify-content: center;
                    display: flex;

                    .mid-box {
                        position: relative;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 142.19%;
                        /* or 31px */

                        text-align: justify;

                        color: #595959;

                    }
                }

            }
        }

    }

    .section4 {


        margin: 82px 60px 0px 60px;

        .mid-title {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-transform: capitalize;
            color: #000000;
        }

        .sponsers {
            margin-top: 14px;
            padding-left: 50px;
            padding-right: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            img {
                width: 157px;
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                transform: scale(1);

                margin: 10px;
            }

            img:hover {
                transform: scale(1.2);
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            }
        }
    }


    .section6 {
        margin: 120px 0px;

        .midtitle {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-transform: capitalize;
            color: #000000;
        }

        .recognised-carousel {
            margin-top: 70px;
            padding-left: 70px;
            padding-right: 70px;

            .carousel {

                .carousel-indicators {
                    display: none;
                }

                .carousel-control-prev {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    left: 47%;
                    transform: translate(-50%, -50%);
                    bottom: -80px;
                    top: unset;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;

                    span {
                        background-size: contain;
                        width: 12px;
                        height: 14px;
                    }
                }

                .carousel-control-next {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    right: 47%;
                    transform: translate(-50%, -50%);
                    bottom: -80px;
                    top: unset;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;

                    span {
                        background-size: contain;
                        width: 12px;
                        height: 14px;
                    }
                }

                .carousel-inner {
                    .carousel-item {
                        .carousel-item1 {
                            display: flex;
                            justify-content: space-between;

                            .company-div {
                                max-width: 210px;
                                display: flex;
                                flex-direction: column;

                                .batch-div {
                                    margin-top: 50px;
                                    margin-bottom: 18px;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 800;
                                    font-size: 22px;
                                    line-height: 142.19%;
                                    /* or 31px */

                                    text-transform: capitalize;

                                    color: #EE3A38;
                                }
                            }
                        }
                    }
                }
            }
        }

    }


}

.research-and-innovative-section {
    margin-top: 70px;

    .section1 {
        margin-bottom: 100px;

        .breadcrumb {
            width: 100%;
            padding-left: 70px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 86.19%;
            /* or 17px */
            display: flex;
            align-items: center;

            color: #989898;

            a {
                color: #989898;
                text-decoration: none;

                &:hover {
                    color: black;
                }
            }

            .active {
                color: black;
                margin-left: 10px;
            }
        }
    }



    .section3 {
        background: #F5F5F5;
        padding-top: 73px;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 107px;
        background-image: url("../images/background-boxes.png");
        background-repeat: no-repeat;
        background-position: left;
        background-position-y: 20px;

        .mid-title {
            display: flex;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 36px;
            text-transform: capitalize;
            color: #000000;
            text-align: center;
            margin-bottom: 40px;

            .redblock {
                background: #ee3a38;
                height: 42px;
                padding: 0px 5px;
            }
        }

        .midsmalltext {
            display: inline-block;
            width: 100%;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 36px;

            text-align: center;

            color: #595959;

            a {
                text-decoration: none;
                color: #ee3a38;
                vertical-align: top;
            }

        }

        .mid-section {
            margin-top: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
















//portfolio-card

.portfolio-card {

    border-radius: 29px;
    height: 520px;
    display: flex;
    flex-direction: column;
    background-image: url(../images/backpatch.png);
    background-position: left;
    background-repeat: no-repeat;
    margin-bottom: 70px;

    .portfolio-card-upper {
        height: 80%;
        padding: 80px 140px 30px;
        display: flex;
        justify-content: space-between;

        .left-side {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 60%;

            .heading {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 86.19%;
                /* or 34px */

                text-align: center;

                color: #000000;
            }

            .description {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 159.19%;
                /* or 29px */

                text-transform: capitalize;

                margin: 50px 0px;

            }

            .white {
                color: white;
            }

            .grey {
                color: #545454;
            }

            .btn-next {

                border-radius: 5px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 86.19%;
                /* or 17px */
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: capitalize;
                border: none;
                color: #000000;
                padding: 12px 13px 13px 20px;
            }

            .btn-next.white {
                background: #FFFFFF;
            }

            .btn-next.red {
                background: #EE3A38;
            }
        }
    }

    .portfolio-card-lower {
        height: 20%;
        padding-right: 34px;

        .tech-icons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;

            .text {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 15px;
                line-height: 86.19%;
                /* identical to box height, or 13px */
                margin-right: 100px;
                text-transform: capitalize;
                margin-bottom: 0;
                color: #000000;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    width: 60px;
                    height: 1px;
                    background: black;
                    right: -80px;
                    top: 7px;
                }
            }

            .icon {
                margin: 0px 10px;
            }
        }
    }
}

.portfolio-card.red {
    background: #EE3838;
}

.portfolio-card.grey {
    background: #DDDDDD;
}

.portfolio-section {
    padding: 80px 60px;
}



//games

.games-section {
    .carousel-section {
        margin-top: 200px;
        margin-bottom: 140px;
        padding-left: 60px;
        padding-right: 60px;

        .carousel-item1 {
            background: #EE3838;
            background-image: url('../images/backpatch.png');
            background-repeat: no-repeat;

            border-radius: 16px;
            height: 420px;
            padding: 50px 100px;
            display: flex;
            position: relative;

            .left-side {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 50%;
                position: relative;

                .heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 35px;
                    line-height: 86.19%;
                    /* or 34px */

                    text-align: center;

                    color: #000000;
                }

                .description {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 29px;

                    margin: 20px 0px;
                    color: #E7E7E7;
                }

                .source {
                    position: absolute;
                    bottom: 0;

                    .source-link {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 86.19%;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        color: #000000;
                        text-decoration: none;

                        .black-arrow {
                            margin-left: 0px;
                        }
                    }
                }

                .source {
                    transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                    transform: scale(1);
                }

                .source:hover {
                    transform: scale(1.2);
                    transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                }


            }

            .right-side {
                width: 50%;
                height: 100%;
                position: absolute;
                background-image: url("../images/PokerBoxImage.png");
                background-position-x: center;
                right: 0;
                top: -110px;
                z-index: 100;
                background-repeat: no-repeat;
            }
        }

        .carousel {

            .carousel-inner {}

            .carousel-control-prev {
                display: none;
            }

            .carousel-control-next {
                display: none;
            }

            .carousel-indicators {
                bottom: -70px;

                button {

                    border-radius: 50%;
                    padding: 0;
                    background: #C4C4C4;
                    opacity: 1;
                    width: 14px;
                    height: 0px;
                }

                .active {
                    background: #EE3A38;
                }
            }

        }
    }

    .card-section {
        .row {
            margin: 250px 0px;

            .col-lg-6 {
                padding-left: 60px;
                padding-right: 60px;
            }
        }
    }
}

.games-card {
    min-width: 457px;
    height: 410px;
    background-image: url(../images/gamescard-backpatch.png);
    background-repeat: no-repeat;
    background-position: right;
    position: relative;
    padding: 37px 46px;
    border-radius: 16px;

    .leftside {
        display: flex;
        flex-direction: column;
        width: 60%;
        height: 100%;
        position: relative;
        padding-top: 30px;

        .heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 35px;
            line-height: 86.19%;
            /* or 34px */

            text-transform: capitalize;
        }

        .heading-black {
            color: #000000;
        }

        .heading-grey {
            color: #EE3A38;
        }

        .para {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 159.19%;
            /* or 25px */
            margin-top: 35px;
        }

        .para-white {
            color: #FFFFFF;
        }

        .para-black {
            color: #000000;
        }

        .btn-div {
            position: absolute;
            bottom: 0;

            .btn-next {
                background: #FFFFFF;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 86.19%;
                /* or 17px */

                text-transform: capitalize;
                padding: 13px 16px;
                color: #000000;
                border-radius: 5px;
            }

            .source-link {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 86.19%;
                /* identical to box height, or 16px */

                text-transform: capitalize;
                display: flex;
                align-items: center;
                text-decoration: none;

                .icon {
                    margin-left: 0px;
                }
            }

        }

        .btn-div {
            transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            transform: scale(1);
        }

        .btn-div:hover {
            transform: scale(1.2);
            transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
        }

        .btn-div.grey-link {
            .source-link {
                color: #EE3A38;

                .icon {
                    height: 30px;
                    width: 34px;
                    margin-left: 22px;

                    &::before {
                        background-image: url("../images/redArrowNew.png");
                    }
                }
            }
        }

        .btn-div.red-link {
            .source-link {
                color: black;

                .icon {
                    height: 30px;
                    width: 34px;
                    margin-left: 22px;

                    &::before {
                        background-image: url("../images/blackArrowNew.png");
                    }
                }
            }
        }
    }

    .rummy-leftside {
        width: 100%;
        padding-top: 70px;
    }

    .rightside {
        width: 198.39px;
        height: 315px;
        position: absolute;
        z-index: 20;
        right: 20px;
        top: -130px;
    }

    .rummy-image {
        height: 203.35px;
        width: 315px;
        top: -100px;
        margin: 0 auto;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

.games-card.red {
    background-color: #EE3838;
}

.games-card.grey {
    background-color: #DDDDDD;
}


//dcpoker
.gamepages-section {
    margin-top: 70px;

    .section1 {
        .breadcrumb {
            width: 100%;
            padding-left: 70px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 86.19%;
            /* or 17px */
            display: flex;
            align-items: center;

            a {
                color: #989898;
                text-decoration: none;

                &:hover {
                    color: black;
                }
            }

            .active {
                color: black;
                margin-left: 10px;
            }
        }
    }

    .section2 {
        margin-top: 82px;

        .row {
            margin-bottom: 73px;
            --bs-gutter-x: 0;

            .left-side {
                padding: 0px 70px;

                .heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 35px;
                    line-height: 86.19%;
                    /* or 34px */

                    color: #000000;

                }

                .text {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 29px;
                    text-align: justify;
                    margin-top: 30px;
                    margin-bottom: 50px;
                    color: #000000;

                }

                .lower-section {
                    .row {
                        .col-lg-6 {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;

                            .bigtext {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 300;
                                font-size: 40px;
                                line-height: 159.19%;
                                /* identical to box height, or 64px */
                                margin-right: 20px;
                                text-transform: capitalize;

                                color: #EE3A38;

                            }

                            .smalltext {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 159.19%;
                                /* or 22px */

                                text-transform: capitalize;

                                color: #000000;
                            }
                        }

                        .col-lg-6:nth-child(1) {
                            &::after {
                                content: " ";
                                position: absolute;
                                height: 100%;
                                width: 2px;
                                background: black;
                                right: 0;
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }

            .right-side {
                display: flex;
                align-items: center;
                justify-content: center;
                background: url("../images/square-bg.png");
                background-repeat: no-repeat;

            }
        }

        .row:nth-child(1) {
            .right-side {
                img {
                    width: 528px;
                    height: 628px;
                    object-fit: contain;
                    margin-top: -110px;
                }
            }

            .sports-betting-right-side {
                img {
                    width: 585px;
                    height: 342px;

                    margin-top: -110px;
                }
            }

        }

        .row:nth-child(1) {
            .rummy-right-side-phone {
                img {
                    width: 472px;
                    height: 269px;
                }
            }
        }

        .row:nth-child(2) {
            flex-direction: row-reverse;
        }
    }

    .section3 {
        .heading {
            display: flex;
            width: 100%;
            padding-left: 71px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 35px;
            line-height: 86.19%;
            /* or 34px */

            text-transform: capitalize;

            color: #000000;
        }

        .redbox {
            background-color: #EE3A38;
            width: 100%;
            padding: 46px 70px 30px 130px;
            background-image: url("../images/backpatch.png");
            display: flex;
            justify-content: space-between;

            .list-div {
                display: flex;
                flex-direction: column;

                .list-heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* identical to box height, or 22px */

                    text-transform: capitalize;

                    color: #AB1715;
                    margin-bottom: 40px;
                }

                .list-item {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 86.19%;
                    /* identical to box height, or 19px */

                    text-transform: capitalize;
                    margin-bottom: 30px;
                    color: #FFFFFF;

                }
            }

        }
    }

    .section4 {
        margin-top: 84px;
        margin-bottom: 84px;


        .heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 35px;
            line-height: 86.19%;
            /* or 34px */
            width: 100%;
            text-align: center;
            text-transform: capitalize;
            margin-bottom: 30px;
            color: #000000;
        }

        .smalltext {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            margin-bottom: 72px;
            color: #4B4B4B;
            padding: 0 50px;
        }

        .card-section {
            background-image: url('../images/SmallSquareBg.png');
            background-repeat: no-repeat;
            background-position: top center;
            background-position-y: -130px;
            min-height: 360px;
            padding: 0px 71px;
            background-color: #F7F7F7;

            .row {
                justify-content: space-between;

                .column-styles {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .white-circle {
                        background: #FFFFFF;
                        box-shadow: 8px 7px 10px rgba(0, 0, 0, 0.2);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 134px;
                        height: 134px;
                        border-radius: 50%;
                    }

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 26px;
                        color: #555555;
                        margin-top: 25px;
                        text-align: center;
                    }
                }

                .margin-top-style {
                    margin-top: 70px;
                }

                .uneven-columns {
                    margin: 70px 10px 0px;
                }

                .col-lg-4 {
                    .list-card {
                        background: #FFFFFE;
                        box-shadow: 13px 10px 20px rgba(0, 0, 0, 0.09);
                        border-radius: 16px;
                        padding: 14px;
                        height: 100%;

                        ul {
                            li {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 218.19%;
                                /* or 39px */

                                text-transform: capitalize;

                                color: #000000;

                            }
                        }
                    }
                }
            }
        }

        .ludo-card-section {
            padding-top: 25px;
            background-position-y: -230px;
        }

        .trivia-feature-section {
            background-image: none;
            align-items: center;
            display: flex;
            padding: 0px;

            .row {
                width: 100%;

                .col-lg-4 {
                    display: flex;
                    justify-content: center;

                    .list-card {
                        width: max-content;
                    }
                }
            }
        }
    }

    .section5 {
        width: 100%;
        margin-bottom: 62px;

        .mid-text {
            width: 100%;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;

            color: #4B4B4B;
        }
    }
}

.redQuotes {
    color: #EE3A38;
}



//images 
.ford-logo {
    background-image: url('../images/ford-logo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.prestoLogo {
    background-image: url('../images/prestoLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.aggosLogo {
    background-image: url('../images/aggosLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.michelinLogo {
    background-image: url('../images/michelinLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.tescoLogo {
    background-image: url('../images/tescoLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.sodexoLogo {
    background-image: url('../images/sodexoLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.infosysLogo {
    background-image: url('../images/infosysLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.cargomaticLogo {
    background-image: url('../images/cargomaticLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.ceatLogo {
    background-image: url('../images/ceatLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.cnbcLogo {
    background-image: url('../images/cnbcLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.doveLogo {
    background-image: url('../images/doveLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}

.apolloLogo {
    background-image: url('../images/apolloLogo.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    width:100%;
    height:100%;
}




.iGaming {
    background-image: url('../images/gamepad.png');
    height: 100%;
    width: 30%;
    background-repeat: no-repeat;
    background-position: center;
}

.nodes {
    background-image: url('../images/nodes.png');
    height: 100%;
    width: 30%;

    background-repeat: no-repeat;
    background-position: center;
}

.mobileapp {
    background-image: url('../images/mobile-app.png ');
    height: 100%;
    width: 30%;

    background-repeat: no-repeat;
    background-position: center;
}

.computer {
    background-image: url('../images/computer.png');
    height: 100%;
    width: 30%;

    background-repeat: no-repeat;
    background-position: center;
}

.teenpatti {
    background-image: url('../images/TeenpattiBoxImage.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.blackjack {
    background-image: url('../images/BlackjackBoxImage.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.rummy {
    background-image: url('../images/RummyBoxImage.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.fantasysports {
    background-image: url('../images/FantasySportsBoxImage.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.ludo {
    background-image: url('../images/LudoBoxImage.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.trivia {
    background-image: url('../images/TriviaBoxImage.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.SportBettingLaptop {
    background-image: url('../images/SportBettingLaptop.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 484px !important;
    height: 315px !important;
    position: absolute !important;
    z-index: 20 !important;
    right: 119px !important;
    top: -130px !important;
}


.custom-prev-icon {
    background-image: url("../images/prev-arrow.png") !important;
}

.custom-next-icon {
    background-image: url("../images/next-arrow.png") !important;
}

.custom-prev-icon-ind-trusted {
    background-image: url("../images/prev-arrow-ind-trusted.png") !important;
}

.custom-next-icon-ind-trusted {
    background-image: url("../images/next-arrow-ind-trusted.png") !important;
}



//navbar-dropdown-menu

/* .dropdown {
    position: static !important;
    margin-left: -40px;

    .dropdown-toggle {
        color: white;
    }

    .dropdown-toggle[aria-expanded="true"] {
        color: #ee3a38;
    }

    .dropdown-menu {
        width: 100% !important;
        padding: 0;
        border-left: 0px;
        border-right: 0px;
        background: rgba(255, 255, 255, 0.9);

        .row {
            --bs-gutter-x: 0px;

            .col-sm-3 {
                .navbar-nav {
                    .nav-item {
                        position: relative;

                        a {
                            background: transparent;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 21px;

                            text-transform: uppercase;
                            padding: 52px 41px;
                            color: #FFFFFF;
                            z-index: 3;

                            background: #ee3a38;
                        }

                        a.active {
                            color: #ee3a38;

                            background: transparent;
                        }
                    }

                    .nav-item:nth-child(1) {
                        a {

                            padding-top: 82px;
                        }
                    }

                    .nav-item:after,
                    .nav-item a:after {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }

                    .nav-item::before,
                    .nav-item a::before {
                        position: absolute;
                        right: 0;
                        top: 0;

                    }

                    .nav-item:last-child:after,
                    .nav-item:last-child a:after,
                    .nav-item:first-child:before,
                    .nav-item:first-child a:before,
                    .nav-item a.active:after,
                    .nav-item a.active:before,
                    .nav-item a.active a:after,
                    .nav-item a.active:before {
                        content: "";
                    }

                    .nav-item a.active:before,
                    .nav-item a.active:after {
                        background: black;

                        z-index: 1;
                    }

                    .nav-item:before,
                    .nav-item:after {
                        background: #ddc385;
                        width: 10px;
                        height: 10px;
                    }

                    .nav-item a:after,
                    .nav-item a:before {
                        width: 20px;
                        height: 20px;
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        border-radius: 10px;
                        background: #222;

                        z-index: 2;
                    }

                    .nav-item a.active:after,
                    .nav-item a.active:before {
                        background: #ddc385;
                    }

                    .nav-item:first-child a.active:before,
                    .nav-item:last-child a.active:after {
                        background: white;
                    }
                    .nav-item:first-child:before,
                    .nav-item:last-child:after {
                        background: white;
                    } 
                }
            }

            .col-sm-9 {
                height: auto;

                .tab-content {
                    height: 100%;

                    .tab-pane {

                        height: 100%;

                        .row {
                            --bs-gutter-x: 0;
                            height: 100%;

                            .row-cols-lg-10 {
                                height: 82%;
                                justify-content: center;

                                .col-lg-2 {
                                    p {
                                        margin-top: 70px;
                                        font-family: 'Roboto';
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 18px;
                                        line-height: 21px;
color: #010101;
}

.list-item:nth-child(3) {
    margin-bottom: 100px;
}
}
}

.row-cols-lg-2 {
    height: 18%;
    //padding-top: 76px;
    position: relative;
    padding-right: 87px;

    .services {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: rgba(0, 0, 0, 0.1);
            top: 0px;
            left: 0;
        }

        p {
            width: fit-content;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
color: #EE3A38;
margin-bottom: 0px;
}

}
}
}

}
}
}
}

.tab-container {
    position: relative;

    &::before {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-110%, -97%);
        background: white;
        height: 100px;
        width: 160px;
        content: " ";
        z-index: 3;
    }
}
}
}

*/




//sidebar-styles

.sidebar-drawer {
    .MuiPaper-root {
        width: 70vw;

        .sidebar-inner {
            height: 100%;
            display:flex;
            flex-direction: column;
            .brand{
                padding: 10px;
                img{
                    width:224px;
                }
                border-bottom: 2px solid black;
            }
            .links{
                display:flex;
                flex-direction: column;
                padding: 10px;
                a{

                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    //font-size: 16px;
                    font-size: 24px;
                    line-height: 19px;
                    /* identical to box height */
                    color: #ee3a38;
                    margin-bottom: 20px;
                    text-transform: capitalize;
                    text-decoration: none;
                }
    
            }
        }
    }
}

.menu-icon {
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;

    .menu-icon__cheeckbox {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;
        z-index: 2;
        -webkit-touch-callout: none;
        position: absolute;
        opacity: 0;
    }

    div {
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 32px;
        height: 24px;
        transform: rotate(180deg)
    }

    span {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background-color: white;
        border-radius: 1px;
        transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

        &:first-of-type {
            top: 0;
        }

        &:last-of-type {
            bottom: 0;
        }
    }



    &:hover {

        // no need hover effect on mobile.
        span:first-of-type {
            width: 12px;
        }

        span:last-of-type {
            width: 26px;
        }

    }

    .second-bar {
        width: 18px;
        top: 10px;
    }
}



.metaverse-section {
    .section1 {
        margin-top: 51px;

        .mid-div {
            display: flex;
            width: 100%;
            justify-content: center;

            .midtitle {

                width: fit-content;
                justify-content: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 86.19%;
                /* or 17px */

                text-transform: capitalize;

                color: #7A7A7A;
                position: relative;
                
                &::before {
                    content: " ";
                    position: absolute;
                    height: 1px;
                    width: 125px;
                    background: #7A7A7A;
                    left: -131px;
                    top: 7px;
                }

                &::after {
                    content: " ";
                    position: absolute;
                    height: 1px;
                    width: 125px;
                    background: #7A7A7A;
                    right: -131px;
                    top: 7px;
                }
            }
        }



        .sponser-carousel {
            margin-top: 40px;
            padding: 0px 50px;

            .carousel {
                .carousel-indicators {
                    display: none;
                }

                .carousel-control-prev {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    left: 50%;
                    transform: translate(-150%, -50%);
                    top: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        background-size: contain;
                        width: 12px;
                        height: 14px;
                    }
                }

                .carousel-control-next {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    right: 50%;
                    transform: translate(150%, -50%);
                    top: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        background-size: contain;
                        width: 12px;
                        height: 14px;
                    }
                }

                .carousel-inner {
                    .carousel-item {
                        .carousel-item1 {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            height: 64px;
                            padding: 0px 25px;

                            /* img {
                                width: 181.74px;
                                height: 44px;
                            } */
                            img {
                                width: 170px;
                                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                                transform: scale(1);


                            }

                            img:hover {
                                transform: scale(1.2);
                                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                            }

                        }
                    }
                }
            }
        }

        .sponsers {
            margin-top: 14px;
            padding-left: 50px;
            padding-right: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            img {
                width: 157px;
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                transform: scale(1);

                margin: 10px;
            }

            img:hover {
                transform: scale(1.2);
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            }
        }
    }

    .section2 {
        margin-top: 70px;

        .midText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-align: center;
            text-transform: capitalize;

            color: #000000;
        }

        .midSmallText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 31px;
            text-align: center;
            color: #595959;
           
            max-width: 1135px;
            margin: 24px auto;
           
        }

        .block-section {
            background: #F5F5F5;
            margin-top: 40px;

            .row {
                padding: 40px 20px;

                .card-style {

                    .card {
                        padding: 30px;
                        border: none;
                        background: transparent;
                        min-height: 285px;

                        .upper-section {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 22px;
                            text-transform: capitalize;
                            color: #000000;
                            display: flex;
                            align-items: center;

                            .icon {
                                margin-right: 20px;
                            }
                        }

                        .lower-section {
                            margin-top: 30px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 18px;
                            line-height: 28px;
                            color: #595959;
                        }
                    }
                }

                .card-style.box-style {
                    .card {
                        background: #FFFFFF;
                        box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 24px;
                    }
                }
            }
        }
    }

    .section3 {
        margin-top: 40px;
        background-color: #EE3a38;
        min-height: 290px;
        position: relative;
        margin-bottom: 360px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 48px;
        background-image: url(../images/darkSpots.png);

        .black-heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 38px;
            text-transform: capitalize;
            text-align: center;
            color: #870807;

        }

        .white-heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 31px;
            text-align: center;

            color: #FFFFFF;

        }

        .center-box {
            margin-top: 180px;
            width: 100%;
            position: absolute;

            .slider-styles {
                .slick-list {
                    .slick-track {
                        .slick-slide {
                            div {
                                .item-box {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .whitebox {
                                        background: #FFFFFF;
                                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                        border-radius: 4px;
                                        width: 116px;
                                        height: 116px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }

                                    .name {
                                        font-family: 'Roboto';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 22px;
                                        line-height: 26px;
                                        margin-top: 30px;
                                        text-align: center;
                                        text-transform: capitalize;

                                        color: #EE3A38;
                                    }

                                    .para-style {
                                        font-family: 'Roboto';
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 20px;
                                        line-height: 142.19%;
                                        /* or 28px */
                                        margin-top: 23px;
                                        text-align: center;
                                        padding: 0px 20px;
                                        color: #4B4B4B;

                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    .section4 {
        .heading {
            width: 100%;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 35px;
            line-height: 86.19%;
            /* or 34px */

            text-transform: capitalize;

            color: #000000;
            margin-bottom: 40px;
        }

        .redbox {
            background-color: #EE3A38;
            width: 100%;
            padding: 46px 70px 30px 130px;
            background-image: url("../images/backpatch.png");
            display: flex;
            justify-content: space-between;

            .list-div {
                display: flex;
                flex-direction: column;

                .list-heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* identical to box height, or 22px */

                    text-transform: capitalize;

                    color: #AB1715;
                    margin-bottom: 40px;
                }

                .list-item {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 86.19%;
                    /* identical to box height, or 19px */

                    text-transform: capitalize;
                    margin-bottom: 30px;
                    color: #FFFFFF;

                }
            }

        }
    }

    .section5 {
        margin-top: 70px;
        padding: 0px 30px;
        margin-bottom: 30px;

        .midText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 28px;
            text-transform: capitalize;
            color: #000000;
            width: 100%;
            text-align: center;
        }

        .midSmallText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 31px;
            text-align: center;
            color: #595959;
        }

        .process-section {
            margin-top: 20px;

            .row {
                .col-lg-4 {
                    .process {
                        position: relative;

                        .bigNumber {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 70px;
                            line-height: 126.2%;
                            color: #E9E9E9;
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: 2;
                        }

                        .redText {
                            padding-top: 47px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 25px;
                            line-height: 86.19%;
                            color: #EE3A38;
                            position: relative;
                            z-index: 3;
                        }

                        .para {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 23px;
                            color: #5A5A5A;
                        }
                    }
                }
            }
        }
    }
}

.blockchain-section {
    .section1 {
        margin-top: 54px;

        .midText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 86.19%;
            text-align: center;
            text-transform: capitalize;

            color: #000000;
            margin-bottom: 20px;
        }

        .midSmallText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 31px;
            text-align: center;
            color: #595959;
            margin-top: 24px;
        }

        .block-section {
            padding: 30px 60px;
            margin-top: 60px;
            background: #F5F5F5;

            .slider-styles {
                .slick-list {
                    .slick-track {
                        .slick-slide {

                            div {
                                display: flex;
                                justify-content: center;

                                .item-box {
                                    position: relative;
                                    max-width: 454px;
                                    height: 300px;

                                    .whitebox {
                                        background: #FFFFFF;
                                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                        border-radius: 10px;

                                        display: block;
                                        flex-direction: column;
                                        align-items: baseline;
                                        padding: 25px 10px 10px 90px;
                                        height: 250px;
                                        max-width: 454px;

                                        .name {
                                            font-family: 'Roboto';
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 20px;
                                            line-height: 86.19%;
                                            color: #000000;
                                        }

                                        .para-style {
                                            font-family: 'Roboto';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 16px;
                                            line-height: 23px;
                                            margin-top: 21px;
                                            color: #8E8E8E;
                                        }
                                    }

                                    .redbox {
                                        width: 108px;
                                        height: 108px;
                                        position: absolute;
                                        background: #EE3A38;
                                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        top: 70px;
                                        left: -53px;
                                        border-radius: 50%;
                                    }
                                }
                            }
                        }
                    }
                }

                .slick-arrow.slick-prev {
                    width: 34.02px;
                    height: 39px;
                    background: url("../images/prevIconNew.png");

                    z-index: 10;

                    &::before {
                        display: none;
                    }

                }

                .slick-arrow.slick-next {
                    width: 34.02px;
                    height: 39px;
                    background: url("../images/nextIconNew.png");
                    z-index: 10;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .section2 {
        margin-top: 60px;

        .midText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 86.19%;
            /* identical to box height, or 28px */
            text-align: center;
            text-transform: capitalize;

            color: #000000;
        }

        .midSmallText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 31px;
            text-align: center;
            color: #595959;
            margin-top: 24px;
        }

        .block-section {
            background: #F5F5F5;
            margin-top: 40px;

            .row {
                padding: 40px 20px;

                .card-style {

                    .card {
                        padding: 30px;
                        border: none;
                        background: transparent;
                        min-height: 330px;

                        .upper-section {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 25px;
                            line-height: 22px;
                            text-transform: capitalize;
                            color: #000000;
                            display: flex;
                            align-items: center;

                            .icon {
                                margin-right: 20px;
                            }
                        }

                        .lower-section {
                            margin-top: 30px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 20px;
                            line-height: 28px;
                            color: #595959;
                        }
                    }
                }

                .card-style.box-style {
                    .card {
                        background: #FFFFFF;
                        box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 24px;
                    }
                }
            }
        }
    }

    .section3 {
        margin-top: 90px;
        background-color: #EE3a38;
        min-height: 290px;
        position: relative;
        padding: 60px;
        background-image: url(../images/darkSpots.png);

        .row {
            .card-side {
                .row {
                    .card-details {
                        .card {
                            display: flex;
                            flex-direction: row;
                            padding: 22px 12px 10px;
                            background: #FFFFFF;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 10px;
                            height: 194px;

                            .right-side {
                                padding-left: 20px;

                                .heading {
                                    margin-top: 10px;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 15px;
                                    line-height: 18px;
                                    text-transform: capitalize;
                                    color: #000000;
                                }

                                .para {
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 17px;
                                    color: #8E8E8E;
                                }
                            }
                        }

                        .card:nth-child(1) {
                            margin-left: 20px;
                        }
                    }
                }

                .row:nth-child(1) {
                    .card-details {
                        .card {
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            .para-side {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 40px;

                .para-inner {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 44px;
                    color: #FFFFFF;
                }
            }
        }
    }

    .section4 {
        margin-top: 60px;
        padding: 0px 0px;

        .midText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 28px;
            text-transform: capitalize;
            text-align: center;
            width: 100%;
            color: #000000;
            margin-bottom: 70px;
        }

        .list-items-section {
            background-color: #F5F5F5;
            padding: 30px 20px;

            .row {
                .list-item-details {
                    .card {
                        padding: 30px;
                        border: none;
                        background: transparent;
                        min-height: 285px;

                        .upper-section {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 25px;
                            line-height: 22px;
                            text-transform: capitalize;
                            color: #000000;
                            display: flex;
                            align-items: center;

                            .icon {
                                margin-right: 20px;
                            }
                        }

                        .lower-section {
                            margin-top: 30px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 20px;
                            line-height: 28px;
                            color: #595959;
                        }
                    }

                    .card.card-box {
                        background: #FFFFFF;
                        box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 24px;
                    }
                }
            }
        }

    }

    .section5 {
        margin-top: 90px;

        .heading {
            width: 100%;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 35px;
            line-height: 86.19%;
            /* or 34px */

            text-transform: capitalize;

            color: #000000;
            margin-bottom: 50px;
        }

        .redbox {
            background-color: #EE3A38;
            width: 100%;
            padding: 46px 70px 30px 130px;
            background-image: url("../images/backpatch.png");
            display: flex;
            justify-content: space-between;

            .list-div {
                display: flex;
                flex-direction: column;

                .list-heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* identical to box height, or 22px */

                    text-transform: capitalize;

                    color: #AB1715;
                    margin-bottom: 40px;
                }

                .list-item {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 86.19%;
                    /* identical to box height, or 19px */

                    text-transform: capitalize;
                    margin-bottom: 30px;
                    color: #FFFFFF;

                }
            }

        }
    }


    .section-last {
        margin-top: 51px;
        margin-bottom: 40px;

        .mid-div {
            display: flex;
            width: 100%;
            justify-content: center;

            .midtitle {

                width: fit-content;
                justify-content: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 86.19%;
                /* or 17px */

                text-transform: capitalize;

                color: #7A7A7A;
                position: relative;

                &::before {
                    content: " ";
                    position: absolute;
                    height: 1px;
                    width: 125px;
                    background: #7A7A7A;
                    left: -131px;
                    top: 7px;
                }

                &::after {
                    content: " ";
                    position: absolute;
                    height: 1px;
                    width: 125px;
                    background: #7A7A7A;
                    right: -131px;
                    top: 7px;
                }
            }
        }




        .sponsers {
            margin-top: 14px;
            padding-left: 50px;
            padding-right: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            img {
                width: 157px;
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                transform: scale(1);

                margin: 10px;
            }

            img:hover {
                transform: scale(1.2);
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            }
        }
    }
}


.AIML-section {
    .section1 {
        padding-top: 80px;
        background: #F5F5F5;

        .row {
            padding: 0 30px;

            .col-lg-7 {


                .right-section {
                    background-image: url('../images/background-boxes.png');
                    background-repeat: no-repeat;
                    background-position: right;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;

                    .heading {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 40px;

                        text-transform: capitalize;

                        color: #000000;
                        margin-bottom: 20px;
                    }

                    .para {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 28px;
                        color: #595959;
                        text-align: justify;
                    }
                }
            }

            .col-lg-5 {
                .left-section {
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }

    }

    .section2 {
        margin-top: 90px;

        .midText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 28px;
            color: #000000;
            width: 100%;
            text-align: center;
            margin-bottom: 24px;
        }

        .midSmallText {
            width: 100%;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 31px;
            text-align: center;

            color: #595959;

        }

        .card-area {
            padding: 60px 28px;
            margin-top: 30px;
            background: #F5F5F5;

            .row {
                .card-area-inner {
                    padding: 25px;

                    .card {
                        padding: 30px;
                        border: none;
                        background: transparent;
                        height: 330px;
                        background: #FFFFFF;
                        box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 24px;

                        .upper-section {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 25px;
                            line-height: 22px;
                            text-transform: capitalize;
                            color: #000000;
                            display: flex;
                            align-items: center;

                            .icon {
                                margin-right: 20px;
                            }
                        }

                        .lower-section {
                            margin-top: 30px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 300;
                            font-size: 20px;
                            line-height: 28px;
                            color: #595959;
                        }
                    }


                }
            }
        }
    }

    .section3 {
        padding: 0 30px;
        margin-top: 100px;
        margin-bottom: 100px;

        .row {
            .left-side-aiml {

                display: flex;
                justify-content: center;
                align-items: center;

                .pics-block {
                    .row {
                        img {
                            display: inline-block;

                            width: 230px;
                            height: 230px;
                        }

                        .secpic {
                            margin-top: 60px;
                            margin-left: 20px;
                        }

                        img:nth-child(2) {
                            margin-top: 40px;
                        }

                        img:nth-child(3) {
                            margin-top: 0px;
                        }

                        img:last-child {
                            margin-top: 40px;
                        }
                    }

                }
            }

            .right-side-aiml {
                margin-top: 80px;
                padding: 0;

                .heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 40px;
                    color: #000000;
                }

                .para {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    text-align: justify;
                    line-height: 28px;
                    color: #595959;
                }
            }
        }
    }

    .section4 {
        .midText {

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 22px;
            color: #000000;
            width: 100%;
            text-align: center;
        }

        .image-section {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 91px;

            img {
                max-width: 1007px;
                height: 695px;
            }
        }
    }

    .section5 {
        margin-top: 160px;
        margin-bottom: 40px;

        .heading {
            text-align: center;
            width: 100%;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 35px;
            line-height: 34px;
            text-transform: capitalize;
            color: #000000;
            margin-bottom: 47px;
        }

        .redbox {
            background-color: #EE3A38;
            width: 100%;
            padding: 46px 70px 30px 130px;
            background-image: url("../images/backpatch.png");
            display: flex;
            justify-content: space-between;

            .list-div {
                display: flex;
                flex-direction: column;

                .list-heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* identical to box height, or 22px */

                    text-transform: capitalize;

                    color: #AB1715;
                    margin-bottom: 40px;
                }

                .list-item {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 86.19%;
                    /* identical to box height, or 19px */

                    text-transform: capitalize;
                    margin-bottom: 30px;
                    color: #FFFFFF;

                }
            }

        }
    }

    .section6 {
        background: url("../images/AIMLDarkBanner.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 90px 50px;

        .heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 86.19%;
            /* identical to box height, or 28px */

            text-transform: capitalize;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 40px;

            span {
                color: #AB1715;
            }
        }

        .para {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            text-align: center;

            color: #FFFFFF;

        }
    }

    .section-last {
        margin-top: 51px;
        margin-bottom: 40px;

        .mid-div {
            display: flex;
            width: 100%;
            justify-content: center;

            .midtitle {

                width: fit-content;
                justify-content: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 86.19%;
                /* or 17px */

                text-transform: capitalize;

                color: #7A7A7A;
                position: relative;

                &::before {
                    content: " ";
                    position: absolute;
                    height: 1px;
                    width: 125px;
                    background: #7A7A7A;
                    left: -131px;
                    top: 7px;
                }

                &::after {
                    content: " ";
                    position: absolute;
                    height: 1px;
                    width: 125px;
                    background: #7A7A7A;
                    right: -131px;
                    top: 7px;
                }
            }
        }




        .sponsers {
            margin-top: 14px;
            padding-left: 50px;
            padding-right: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            img {
                width: 157px;
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
                transform: scale(1);

                margin: 10px;
            }

            img:hover {
                transform: scale(1.2);
                transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
            }
        }
    }
}

.fintech-section {
    .section1 {
        margin-top: 0px;
        padding: 55px 36px;
        background: #F5F5F5;

        .midText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 28px;
            text-transform: capitalize;
            color: #000000;
            width: 100%;
            text-align: center;
            margin-bottom: 40px;
        }

        .para {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            text-align: justify;
            color: #595959;

        }
    }

    .section2 {
        margin-top: 90px;

        .midText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 28px;
            color: #000000;
            width: 100%;
            text-align: center;
            margin-bottom: 80px;
        }

        .services-list {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 70px;

            img {
                max-width: 1231px;
                height: 296px;
            }
        }
    }

    .section3 {
        padding: 0 0px;
        margin-top: 100px;

        .row {
            margin-bottom: 121px;
            padding: 0 30px;

            .left-side-fintech {
                img {
                    max-width: 455px;
                    width: 100%;
                }
            }

            .right-side-fintech {
                margin-top: 40px;

                .heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 40px;
                    color: #000000;
                }

                .para {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 28px;
                    color: #595959;
                    text-align: justify;
                    padding-right: 50px;
                }
            }
        }

        .row:nth-child(1),
        .row:nth-child(3) {
            background: #F5F5F5;
            padding: 35px 30px;
        }
    }

    .section4 {
        padding: 40px 63px;
        background: url("../images/RedBGNew.png");
        background-repeat: no-repeat;
        background-size: cover;

        .text-section {
            display: flex;
            flex-direction: column;
            margin-bottom: 62px;

            .upper-section {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 40px;
                }

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height:22px;
                text-transform: capitalize;
                margin-bottom: 20px;
                text-align: justify;
                color: #FFFFFF;
            }

            .lower-section {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 28px;
                color: #FFFFFF;
            }
        }
    }

    .section5 {
        margin-top: 100px;
        margin-bottom: 40px;

        .heading {
            text-align: center;
            width: 100%;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 35px;
            line-height: 34px;
            text-transform: capitalize;
            color: #000000;
            margin-bottom: 47px;
        }

        .redbox {
            background-color: #EE3A38;
            width: 100%;
            padding: 46px 70px 30px 130px;
            background-image: url("../images/backpatch.png");
            display: flex;
            justify-content: space-between;

            .list-div {
                display: flex;
                flex-direction: column;

                .list-heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* identical to box height, or 22px */

                    text-transform: capitalize;

                    color: #AB1715;
                    margin-bottom: 40px;
                }

                .list-item {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 86.19%;
                    /* identical to box height, or 19px */

                    text-transform: capitalize;
                    margin-bottom: 30px;
                    color: #FFFFFF;

                }
            }

        }
    }

    .section6 {
        background: url("../images/fintechDarkBanner.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 135px 50px;
        margin-bottom: 40px;

        .heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 86.19%;
            /* identical to box height, or 28px */

            text-transform: capitalize;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 40px;

            span {
                color: #AB1715;
            }
        }

        .para {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            text-align: center;

            color: #FFFFFF;

        }
    }


}


.healthcare-section {

    .section1 {
        padding: 0 30px;
        margin-top: 70px;

        .row {
            margin-bottom: 121px;
            padding: 30px 0px;
            background: #F5F5F5;

            .left-side-fintech {
                img {
                    max-width: 455px;
                    width: 100%;
                }
            }

            .right-side-fintech {
                padding-left: 30px;
                padding-right: 30px;

                .heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 40px;
                    color: #000000;
                }

                .para {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 28px;
                    color: #595959;
                    text-align: justify;
                }
            }
        }
    }

    .section2 {
        margin-top: 90px;
        padding: 0 50px;
        margin-bottom: 50px;

        .row {
            .left-side-fintech {
                img {
                    width: 100%;
                }
            }

            .right-side-fintech {
                .heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 40px;
                    color: #000000;
                }

                .para {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 28px;
                    color: #595959;
                }
            }
        }
    }

    .section3 {
        padding: 40px 63px;
        background-image: url('../images/largeRedSquareBG.png');
        background-repeat: no-repeat;
        background-size: cover;

        .text-section {
            display: flex;
            flex-direction: column;
            margin-bottom: 62px;

            .upper-section {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 40px;
                }

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height:22px;
                text-transform: capitalize;
                margin-bottom: 30px;
                color: #FFFFFF;
            }

            .lower-section {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 28px;
                color: #FFFFFF;
            }
        }
    }

    .section4 {
        margin-top: 100px;
        margin-bottom: 40px;

        .heading {
            text-align: center;
            width: 100%;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 35px;
            line-height: 34px;
            text-transform: capitalize;
            color: #000000;
            margin-bottom: 47px;
        }

        .redbox {
            background-color: #EE3A38;
            width: 100%;
            padding: 46px 70px 30px 130px;
            background-image: url("../images/backpatch.png");
            display: flex;
            justify-content: space-between;

            .list-div {
                display: flex;
                flex-direction: column;

                .list-heading {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 86.19%;
                    /* identical to box height, or 22px */

                    text-transform: capitalize;

                    color: #AB1715;
                    margin-bottom: 40px;
                }

                .list-item {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 86.19%;
                    /* identical to box height, or 19px */

                    text-transform: capitalize;
                    margin-bottom: 30px;
                    color: #FFFFFF;

                }
            }

        }
    }

    .section5 {
        background: url("../images/healthcareDarkBanner.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 135px 50px;
        margin-bottom: 40px;

        .heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 86.19%;
            /* identical to box height, or 28px */

            text-transform: capitalize;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 40px;

            span {
                color: #AB1715;
            }
        }

        .para {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            text-align: center;

            color: #FFFFFF;

        }
    }
}

.pic-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: -100px;
    margin-top: -100px;
    margin-left: -37px;

    figure {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        /*animation*/

        animation: slideShow 24s linear infinite 0s;
        -o-animation: slideShow 24s linear infinite 0s;
        -moz-animation: slideShow 24s linear infinite 0s;
        -webkit-animation: slideShow 24s linear infinite 0s;
    }

    .pic-1 {
        opacity: 1;
        background: url("../images/Homepagebg.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width:100%;
        height:100%;
    }

    .pic-2 {
        animation-delay: 6s;
        -o-animation-delay: 6s;
        -moz--animation-delay: 6s;
        -webkit-animation-delay: 6s;
        background: url("../images/BlockchainHomeBanner.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width:100%;
        height:100%;
    }

    .pic-3 {
        animation-delay: 12s;
        -o-animation-delay: 12s;
        -moz--animation-delay: 12s;
        -webkit-animation-delay: 12s;
        background: url("../images/AIMLHomeBanner.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width:100%;
        height:100%;
    }

    .pic-4 {
        animation-delay: 18s;
        -o-animation-delay: 18s;
        -moz--animation-delay: 18s;
        -webkit-animation-delay: 18s;
        background: url("../images/FintechHomeBanner.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width:100%;
        height:100%;
    }

    .pic-5 {
        animation-delay: 18s;
        -o-animation-delay: 18s;
        -moz--animation-delay: 18s;
        -webkit-animation-delay: 18s;
        background: url("../images/MetaverseHomeBanner.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width:100%;
        height:100%;
    }

    .pic-6 {
        animation-delay: 18s;
        -o-animation-delay: 18s;
        -moz--animation-delay: 18s;
        -webkit-animation-delay: 18s;
        background: url("../images/HealthcareHomeBanner.png") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width:100%;
        height:100%;
    }

    /* keyframes*/

    @keyframes slideShow {
        0% {
            opacity: 0;
            transform: scale(1);
            -ms-transform: scale(1);
        }

        5% {
            opacity: 1
        }

        25% {
            opacity: 1;
        }

        30% {
            opacity: 0;
            transform: scale(1.1);
            -ms-transform: scale(1.1);
        }

        100% {
            opacity: 0;
            transform: scale(1);
            -ms-transformm: scale(1);
        }
    }

    @-o-keyframes slideShow {
        0% {
            opacity: 0;
            -o-transform: scale(1);
        }

        5% {
            opacity: 1
        }

        25% {
            opacity: 1;
        }

        30% {
            opacity: 0;
            -o-transform: scale(1.1);
        }

        100% {
            opacity: 0;
            -o-transformm: scale(1);
        }
    }

    @-moz-keyframes slideShow {
        0% {
            opacity: 0;
            -moz-transform: scale(1);
        }

        5% {
            opacity: 1
        }

        25% {
            opacity: 1;
        }

        30% {
            opacity: 0;
            -moz-transform: scale(1.1);
        }

        100% {
            opacity: 0;
            -moz-transformm: scale(1);
        }
    }

    @-webkit-keyframes slideShow {
        0% {
            opacity: 0;
            -webkit-transform: scale(1);
        }

        5% {
            opacity: 1
        }

        25% {
            opacity: 1;
        }

        30% {
            opacity: 0;
            -webkit-transform: scale(1.1);
        }

        100% {
            opacity: 0;
            -webkit-transformm: scale(1);
        }
    }
}


.section-breadcrumb {
    margin-top: 90px;

    .breadcrumb {
        width: 100%;
        padding-left: 70px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 86.19%;
        /* or 17px */
        display: flex;
        align-items: center;

        color: #989898;

        a {
            color: #989898;
            text-decoration: none;

            &:hover {
                color: black;
            }
        }

        .active {
            color: black;
            margin-left: 10px;
        }
    }
}


#root {
    position: relative;
}

.toast {
    position: absolute;
    bottom: calc(100vh - 100px);
}

.floating-button-custom {

    div[class*='styles-module_whatsappButton'],
    div[class^='styles-module_whatsappButton'] {
        bottom: 6rem;
        z-index: 9999;
    }

    header {
        display: none;
    }

    div[class*='styles-module_whatsappChatBox'],
    div[class^='styles-module_whatsappChatBox'] {
        z-index: 999;
    }
}