.icon {
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
}

.icon-upi {
  height: 70px;
  width: 200px;
  position: relative;
  display: inline-block;
}

.icon-lg {
  height: 80px;
  width: 80px;
  position: relative;
  display: inline-block;
}

.icon-big {
  height: 105px;
  width: 105px;
  position: relative;
  display: inline-block;
}

.icon-xl {
  height: 130px;
  width: 130px;
  position: relative;
  display: inline-block;
}

.icon-small {
  height: 50px;
  width: 50px;
  position: relative;
  display: inline-block;
}

.icon-pay {
  height: 55px;
  width: 55px;
  position: relative;
  display: inline-block;
}

.icon-xs {
  height: 30px;
  width: 30px;
  position: relative;
  display: inline-block;
}

.icon,
.icon-lg,
.icon-xl,
.icon-small,
.icon-xs,
.icon-upi,
.icon-big,
.icon-pay {
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    top: 0;
  }
}


.spring-boot-white {
  height: 24px;
  width: 24px;

  &::before {
    background-size: contain;

    background-image: url("../images/springboot-white.png");
  }
}

.spring-boot-black {
  height: 24px;
  width: 24px;

  &::before {
    background-size: contain;

    background-image: url("../images/springboot-black.png");
  }
}

.framework-white {
  height: 24px;
  width: 24px;

  &::before {
    background-size: contain;

    background-image: url("../images/framework-white.png");
  }
}

.framework-black {
  height: 24px;
  width: 24px;

  &::before {
    background-size: contain;

    background-image: url("../images/framework-black.png");
  }
}

.javascript-white {
  height: 24px;
  width: 24px;

  &::before {
    background-size: contain;
    background-image: url("../images/javascipt-white.png");
  }
}

.javascript-black {
  height: 24px;
  width: 24px;

  &::before {
    background-size: contain;

    background-image: url("../images/javascript-black.png");
  }
}

.react-white {
  height: 24px;
  width: 24px;

  &::before {
    background-size: contain;

    background-image: url("../images/react-white.png");
  }
}

.react-black {
  height: 24px;
  width: 24px;

  &::before {
    background-size: contain;

    background-image: url("../images/react-black.png");
  }
}

.redstar {
  height: 16px;
  width: 16px;

  &::before {
    background-size: contain;

    background-image: url("../images/list-star.png");
  }

}

.red-arrow {
  height: 25px;
  width: 25px;

  &::before {
    background-size: contain;
    background-image: url("../images/red-arrow.png");
  }
}

.black-arrow {
  height: 30px;
  width: 34px;
  margin-left: 12px!important;
  &::before {
    background-size: contain;
    background-image: url("../images/blackArrowNew.png");
  }
}

.heartwarrior {
  width: 110.41px;
  height: 59px;

  &::before {
    background-size: contain;
    background-image: url("../images/heartwarrior.png");
  }
}

.treasure {
  width: 110.41px;
  height: 59px;

  &::before {
    background-size: contain;
    background-image: url("../images/treasure.png");
  }
}

.pinnacle {
  width: 110.41px;
  height: 59px;

  &::before {
    background-size: contain;
    background-image: url("../images/pinnacle.png");
  }
}

.brilliantbeg {
  width: 110.41px;
  height: 59px;

  &::before {
    background-size: contain;
    background-image: url("../images/brilliantbeginning.png");
  }
}

.alliance {
  width: 110.41px;
  height: 59px;

  &::before {
    background-size: contain;
    background-image: url("../images/alliance.png");
  }
}


.black-list-arrow {
  height: 36px;
  width: 36px;
  margin-right: 22px;

  &::before {
    background-size: contain;
    background-image: url("../images/black-list-arrow.png");
  }
}

.know-more-arrow {
  height: 36px;
  width: 36px;
  margin-left: 22px;

  &::before {
    background-size: contain;
    transform: rotate(180deg);
    background-image: url("../images/black-list-arrow.png");
  }
}

.verified-testing {
  height: 50px;
  width: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/ProductDevNewIcon1.png");
  }
}

.fast-delivery {
  height: 50px;
  width: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/ProductDevNewIcon2.png");
  }
}

.low-budget {
  height: 50px;
  width: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/ProductDevNewIcon3.png");
  }
}

.project {
  height: 70px;
  width: 70px;

  &::before {
    background-size: contain;
    background-image: url("../images/project.png");
  }
}

.people-talking {
  height: 70px;
  width: 70px;

  &::before {
    background-size: contain;
    background-image: url("../images/people-talking.png");
  }
}

.yellow-lens {
  height: 70px;
  width: 70px;

  &::before {
    background-size: contain;
    background-image: url("../images/yellow-lens.png");
  }
}

.moneybag {
  height: 70px;
  width: 70px;

  &::before {
    background-size: contain;
    background-image: url("../images/moneybag.png");
  }
}

.batch-grey {
  height: 50px;
  width: 50px;
  margin-right: 20px;

  &::before {
    background-size: contain;
    background-image: url("../images/moneybag.png");
  }
}

.call {
  height: 20px;
  width: 20px;
  margin-right: 20px;

  &::before {
    background-size: contain;
    background-image: url("../images/Call.png");
  }
}

.mail {
  height: 20px;
  width: 20px;
  margin-right: 20px;

  &::before {
    background-size: contain;
    background-image: url("../images/Mail.png");
  }
}

.play-btn {
  height: 24px;
  width: 24px;
  margin-left: 20px;

  &::before {
    background-size: contain;
    background-image: url("../images/play-icon.png");
  }
}

.whatsapp-white {
  height: 24px;
  width: 24px;
  margin-right: 10px;

  &::before {
    background-size: contain;
    background-image: url("../images/whatsapp-white-icon.png");
  }
}

.skype-white {
  height: 24px;
  width: 24px;
  margin-right: 10px;

  &::before {
    background-size: contain;
    background-image: url("../images/skype-white-icon.png");
  }
}


.prod-dev-red {
  height: 40px;
  width: 40px;
  margin-right: 30px;

  &::before {
    background-size: contain;
    background-image: url("../images/prod-dev-red.png");
  }
}

.red-bulb {
  height: 40px;
  width: 40px;
  margin-right: 30px;

  &::before {
    background-size: contain;
    background-image: url("../images/red-bulb.png");
  }
}

.red-computer-icon {
  height: 40px;
  width: 40px;
  margin-right: 30px;

  &::before {
    background-size: contain;
    background-image: url("../images/red-computer-icon.png");
  }
}

.growth-icon-red {
  height: 40px;
  width: 40px;
  margin-right: 30px;

  &::before {
    background-size: contain;
    background-image: url("../images/growth-icon-red.png");
  }
}

.red-bulb-icon {
  height: 40px;
  width: 40px;
  margin-right: 30px;

  &::before {
    background-size: contain;
    background-image: url("../images/red-bulb-icon.png");
  }
}

.red-arrow-portfolio {
  width: 40px;
  height: 16px;
  margin-left: 20px;

  &::before {
    background-size: contain;
    background-image: url("../images/red-arrow-portfoilio.png");
  }
}

.goinside-white-icon {
  width: 40px;
  height: 16px;
  margin-left: 20px;

  &::before {
    background-size: contain;
    background-image: url("../images/goinside-white-icon.png");
  }
}

.services-icon {
  width: 40px;
  height: 16px;
  margin-left: 10px;

  &::before {
    background-size: contain;
    background-image: url("../images/services-icon.png");
  }
}

.revitLogo {
  width: 175.41px;
  height: 59px;

  &::before {
    background-size: contain;
    background-image: url("../images/revitLogo.png");
    width:100%;
    height:100%;
  }

}

.pokerYaar {
  width: 175.41px;
  height: 59px;

  &::before {
    background-size: contain;
    background-image: url("../images/pokerYaar.png");
    width:100%;
    height:100%;
  }
}

.bigFanLogo {
  width: 175.41px;
  height: 59px;

  &::before {
    background-size: contain;
    background-image: url("../images/bigFanLogo.png");
    width:100%;
    height:100%;
  }
}

.pokerWarLogo {
  width: 175.41px;
  height: 59px;

  &::before {
    background-size: contain;
    background-image: url("../images/pokerWarLogo.png");
    width:100%;
    height:100%;
  }
}

.traviaLogo {
  width: 175.41px;
  height: 59px;

  &::before {
    background-size: contain;
    background-image: url("../images/traviaLogo.png");
    width:100%;
    height:100%;
  }
}


.AndroidIcon1 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/AndroidIcon1.png");
  }
}

.AndroidIcon2 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/AndroidIcon2.png");
  }
}

.AndroidIcon3 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/AndroidIcon3.png");
  }
}

.AndroidIcon4 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/AndroidIcon4.png");
  }
}

.AndroidIcon5 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/AndroidIcon5.png");
  }
}

.metaverseIcon1 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/MetaverseNewIcon1.png");
  }
}

.metaverseIcon2 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/MetaverseNewIcon2.png");
  }
}

.metaverseIcon3 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/MetaverseNewIcon3.png");
  }
}

.metaverseIcon4 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/MetaverseNewIcon4.png");
  }
}

.metaverseIcon5 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/MetaverseNewIcon5.png");
  }
}

.metaverseIcon6 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/MetaverseNewIcon6.png");
  }
}

.metaverseIcon7 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/MetaverseNewIcon7.png");
  }
}

.metaverseIcon8 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/MetaverseNewIcon8.png");
  }
}

.blockchainCardIcon1 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/blockchainCardIcon1.png");
  }
}

.blockchainCardIcon2 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/blockchainCardIcon2.png");
  }
}

.blockchainCardIcon3 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/blockchainCardIcon3.png");
  }
}

.blockchainCardIcon4 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/blockchainCardIcon4.png");
  }
}


.blockchainListIcon1 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/BlockchainNewIcon1.png");
  }
}

.blockchainListIcon2 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/BlockchainNewIcon2.png");
  }
}

.blockchainListIcon3 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/BlockchainNewIcon3.png");
  }
}

.blockchainListIcon4 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/BlockchainNewIcon4.png");
  }
}

.blockchainListIcon5 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/BlockchainNewIcon5.png");
  }
}

.blockchainListIcon6 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/BlockchainNewIcon6.png");
  }
}

.blockchainListIcon7 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/BlockchainNewIcon7.png");
  }
}

.blockchainListIcon8 {
  width: 60px;
  height: 60px;

  &::before {
    background-size: contain;
    background-image: url("../images/BlockchainNewIcon8.png");
  }
}

.AIMLCardIcon1 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/AIMLCardIcon1.png");
  }
}

.AIMLCardIcon2 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/AIMLCardIcon2.png");
  }
}

.AIMLCardIcon3 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/AIMLCardIcon3.png");
  }
}

.AIMLCardIcon4 {
  width: 40px;
  height: 40px;

  &::before {
    background-size: contain;
    background-image: url("../images/AIMLNewIcon.png");
  }
}

.fintechIcon1 {
  width: 50px;
  height: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/FintechNewIcon1.png");
  }
}

.fintechIcon2 {
  width: 50px;
  height: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/FintechNewIcon2.png");
  }
}

.fintechIcon3 {
  width: 50px;
  height: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/FintechNewIcon3.png");
  }
}

.fintechIcon4 {
  width: 50px;
  height: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/FintechNewIcon4.png");
  }
}

.fintechIcon5 {
  width: 50px;
  height: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/FintechNewIcon5.png");
  }
}

.healthcareIcon1 {
  width: 50px;
  height: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/HealthcareNewIcon1.png");
  }
}

.healthcareIcon2 {
  width: 50px;
  height: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/HealthcareNewIcon2.png");
  }
}

.healthcareIcon3 {
  width: 50px;
  height: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/HealthcareNewIcon3.png");
  }
}

.healthcareIcon4 {
  width: 50px;
  height: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/HealthcareNewIcon4.png");
  }
}

.healthcareIcon5 {
  width: 50px;
  height: 50px;

  &::before {
    background-size: contain;
    background-image: url("../images/HealthcareNewIcon5.png");
  }
}

.redDoubleQoutes {
  width: 26px;
  height: 22px;
  &::before {
    background-size: contain;
    background-image: url("../images/redDoubleQoutes.png");
  }
}