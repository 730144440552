@mixin textGradient1 {
    background: radial-gradient(76.8% 315.28% at 51.55% -16.3%, #BFA587 0%, #705841 100%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin textGradient2 {
    background: linear-gradient(79.81deg, #165cc7 -8.12%, #00b9c5 162.79%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}



@mixin respond($breakpoint) {
    @if $breakpoint ==smallphone {
        @media screen and (max-width: 321px) {
            @content;
        }
    }

    @if $breakpoint ==marginproblem {
        @media screen and (width: 375.2px) {
            @content;
        }
    }

    @if $breakpoint ==miphone {
        @media screen and (min-width: 393px) and (max-width: 394px) {
            @content;
        }
    }

    @if $breakpoint ==phone {
        @media screen and (min-width: 321px) and (max-width: 480px) {
            @content;
        }
    }

    @if $breakpoint ==smalltab {
        @media screen and (min-width: 481px) and (max-width: 600px) {
            @content;
        }
    }

    @if $breakpoint ==tab-port {
        @media screen and (min-width: 601px) and (max-width: 768px) {
            @content;
        }
    }

    @if $breakpoint ==tab-land {
        @media (min-width: 769px) and (max-width: 800px) {
            @content;
        }
    }

    @if $breakpoint ==large-tab {
        @media (min-width: 801px) and (max-width: 1024px) {
            @content;
        }
    }

    @if $breakpoint ==desktop {
        @media (min-width: 1025px) and (max-width: 1300px) {
            @content;
        }
    }

    @if $breakpoint ==largedesktop {
        @media (min-width: 1301px) {
            @content;
        }
    }
}