.lp-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  background-image: url("../../assests/images/Homepagebg.png");
}

.hero-container-thankyou-page-bg {
  background: url("../../assests/images/thanksImg.png");
  background-size: cover;
  background-position: center center;
  /* Center the image in both directions */
  background-repeat: no-repeat;
  min-height: 400px;
  /* Set minimum height */
  height: 550px;
  /* Default height for larger screens */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* Add padding for spacing */
}

@media (max-width: 768px) {
  .hero-container-thankyou-page-bg {
    height: 400px;
    /* Set the height for tablets */
    justify-content: center;
    /* Center content on mobile */
    padding: 15px;
    /* Adjust padding */
  }
}

@media (max-width: 480px) {
  .hero-container-thankyou-page-bg {
    height: 400px;
    /* Maintain minimum height for mobile */
    background-size: cover;
    /* Ensure image covers the area */
    flex-direction: column;
    /* Stack items vertically */
    text-align: center;
    /* Center text */
    padding: 10px;
    /* Adjust padding for small screens */
  }
}



.hero-container-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* landing page  */

.hero-container-landing-page-bg {
  background: url("../../assests/images/lp-bg-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 750px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.landing-page-hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  padding: 20px;
}

.lp-contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  margin: 0 120px 80px;
  height: fit-content;

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
    color: gray;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
  }

  button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f44336;
    color: white;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: #e53935;
    }
  }
}

.lp-big-text {
  font-size: 40px !important;
  font-weight: 500 !important;
  color: #FFFFFF;
}

.red {
  color: #EE3A38 !important;
}

.lp-small-text {
  font-size: 25px !important;
  font-weight: 400 !important;
  color: #FFFFFF;
  width: 100%;
}

.lp-qr-images {
  display: flex;
  gap: 30px;
  margin: 30px 10px 10px 10px;
}

.lp-qr-image {
  width: 100px;
  height: 100px;
}

.lp-phone-image {
  width: 80px;
  height: 80px;
  margin-top: 15px;
}

/* Responsive Styles */
/* @media (max-width: 1024px) {
  .hero-container-landing-page-bg {
    height: 600px;
  }

  .lp-contact-form {
    margin: 0 50px 40px; 
  }

  .lp-big-text {
    font-size: 35px !important; 
  }

  .lp-small-text {
    font-size: 22px !important; 
  }
} */

.contact-us-text-margin {
  margin-top: 50px;
}

@media (max-width: 768px) {
  .hero-container-landing-page-bg {
    height: 500px;
    flex-direction: column;
    justify-content: center;
  }

  .hero-container,
  .hero-container-inner {
    display: block;
  }

  .lp-contact-form {
    margin: 20px 20px 40px;
  }

  .lp-big-text {
    font-size: 30px !important;
    /* margin-top: 30px; */
  }

  .lp-small-text {
    font-size: 20px !important;
  }

  .lp-qr-images {
    flex-direction: row;
    gap: 20px;
  }

  .lp-qr-image {
    width: 80px;
    height: 80px;
  }

  .lp-phone-image {
    width: 60px;
    height: 60px;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .hero-container-landing-page-bg {
    height: auto;
    /* Adjust height for very small screens */
    padding: 5px;
    /* Reduce padding for very small screens */
  }

  .contact-us-text-margin {
    margin-top: 30px;
  }

  .hero-container .hero-container-inner {
    display: block;
  }

  .lp-big-text {
    font-size: 25px !important;
    /* Adjust font size for very small screens */
    /* margin-top: 50px; */
    margin-bottom: 10px;
  }

  .lp-small-text {
    font-size: 18px !important;
    /* Adjust font size for very small screens */
  }

  .lp-contact-form {
    margin: 0px 0px 0px;
    /* Adjust margins for very small screens */
    padding: 15px;
    /* Adjust padding for very small screens */
  }

  .lp-qr-images {
    gap: 15px;
    /* Reduce gap for very small screens */
    display: flex;
  }

  .lp-qr-image {
    width: 60px;
    height: 60px;
  }

  .lp-phone-image {
    width: 50px;
    height: 50px;
    margin-top: 5px;
  }
}


.center {
  text-align: center;
  align-self: center;
}

.mt--30 {
  margin-top: -30px;
}

.mt--15 {
  margin-top: -15px;
}

.mt-50 {
  margin-top: 50px;
}

.clients {
  margin-top: 14px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.clients img {
  width: 157px;
  transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
  transform: scale(1);
  margin: 30px;
}

.clients img:hover {
  transform: scale(1.2);
  transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
}

.bg-container {
  justify-content: space-between;
  align-items: center;
  height: 500px;
  background: url('../../assests/images/pngegg_left.png') no-repeat left,
    url('../../assests/images/pngegg_right.png') no-repeat right;
  background-size: auto 100%;
  background-color: #fff;
  padding: 0 50px;
}

/* about us  */

.about-us-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;
  gap: 30px;
  flex-wrap: wrap;
}

.about-us-card,
.about-us-card-red {
  width: 100%;
  max-width: 400px;
  min-width: 250px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  box-sizing: border-box;
}

.about-us-card {
  background-color: #F1F1F1;
}

.image-heading-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.img-box {
  min-width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #FF8887 0%, #EE3A38 100%);
  border-radius: 10px;
  margin-right: 20px;
}

.img-box img {
  width: 40px !important;
  height: 40px !important;
}

.heading {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.text-box {
  flex: 1;
}

.text-box p {
  font-size: 16px;
  font-weight: 400;
  color: #595959;
}

.about-us-card-red {
  background-color: #EE3A38;
}

.about-us-card-red .img-box {
  background: linear-gradient(180deg, #FFFFFF 0%, #CBCBCB 100%);
}

.about-us-card-red .heading,
.about-us-card-red .text-box p {
  color: #FFFFFF;
}

/* Responsive Styles */
/* @media (min-width: 768px) {
  .about-us-card,
  .about-us-card-red {
    width: 85%; 
  }
} */

@media (max-width: 767px) {
  .about-us-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: -100px;
    gap: 20px;
  }

  .about-us-card,
  .about-us-card-red {
    width: 90%;
    /* Adjust width for mobile screens */
    margin-bottom: 20px;
    /* Add space between cards */
  }
}



/* Development service container */
.dev-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #EE3A38;
  /* padding: 30px 50px; */
  color: white;
  background: url('../../assests/images/pngegg-red-left.png') no-repeat left,
    url('../../assests/images/pngegg-red-right.png') no-repeat right,
    #EE3A38;
  background-size: cover 100%;
  margin-top: 50px;
  height: 450px;
}

.dev-section {
  text-align: center;
  width: 20%;
}

.dev-img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}

.dev-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  margin: 0;
}

/* Contact us button */
.contact-us {
  background-color: #EE3A38;
  display: flex;
  justify-content: center;
  align-items: center;

}

.contact-us-div {
  display: flex;
  background-color: white;
  width: 20%;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: -30px;
  border-bottom-right-radius: -30px;
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.25);
}

.contact-us-text {
  color: #000000;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}






/* .our-process-image {
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
 img {
  margin-top: 50px;
  width: auto;
  height: 300px;
 }
} */

.our-process-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  /* Adjust the height as needed */
}

.our-process-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.our-process-image img {
  margin-top: 60px;
  width: auto;
  height: 300px;
}


@media (max-width: 768px) {

  .bg-container {
    height: 650px;
  }

  .dev-container {
    flex-direction: column;
    padding: 20px;
    height: auto;
  }

  .dev-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .contact-us {
    height: auto;
  }

  .contact-us-div {
    width: 50%;
    padding: 15px 20px;
  }

  .contact-us-text {
    font-size: 20px;
  }
}

/* why us  */

.why-us-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.why-us-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.why-us-tab-button {
  background-color: #e0e0e0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 20px;
  font-size: 16px;
}

.why-us-tab-button.active {
  background-color: #EE3A38;
  color: white;
}

.why-us-tab-content {
  display: none;
  justify-content: center;
  flex-wrap: wrap;
}

.why-us-tab-content.active {
  display: flex;
}

.why-us-card {
  background-color: #EE3A38;
  color: white;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  flex: 1 1 calc(33.333% - 40px);
  max-width: calc(33.333% - 40px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.why-us-card img {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.why-us-card h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.why-us-card p {
  font-size: 16px;
}

@media (max-width: 768px) {
  .why-us-container {
    margin-top: -150px;
  }

  .why-us-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .why-us-tab-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .why-us-card {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .why-us-tab-button {
    padding: 6px 12px;
    font-size: 12px;
  }
  h1 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}



/* benefits  */

.benefits-heading {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 50px;
}

.benefits-heading-1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 125.69%;
  text-transform: uppercase;
  color: black;
  padding-left: 350px;
}

.benefits-heading-2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 125.69%;
  text-transform: uppercase;
  color: black;
  padding-left: 100px;
}

.benefits-heading-3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 125.69%;
  text-transform: uppercase;
  color: black;
  padding-left: 350px;
  margin-top: 150px;
}

.benefits-text-1 {
  margin-left: 80px;
}

.benefits-text-2 {
  margin-right: 480px;
}

.benefits-text-3 {
  margin-left: 80px;
}

.benefits-img-1 {
  position: absolute !important;
  z-index: 10 !important;
  top: -20px !important;
  left: 40px !important;
  height: 400px !important;
  height: 400px !important;
}

.benefits-img-2 {
  z-index: 10 !important;
  right: 40px !important;
  left: unset !important;
  top: -45px !important;
  width: 400px !important;
  height: 400px !important;
}

.benefits-img-3 {
  position: absolute !important;
  z-index: 10 !important;
  top: 110px !important;
  left: 40px !important;
  height: 400px !important;
  height: 400px !important;
}

@media (max-width: 768px) {

  .benefits-heading-1,
  .benefits-heading-2,
  .benefits-heading-3 {
    padding-left: 0px;
    text-align: center;
  }

  .benefits-heading-3 {
    margin-top: 0px;
  }

  .benefits-text-1 {
    margin-left: 0px;
  }

  .benefits-text-2 {
    margin-right: 0px;
  }

  .benefits-text-3 {
    margin-left: 0px;
  }

  .benefits-img-1 {
    left: 0px !important;
  }

  .benefits-img-2 {
    right: -7px !important;
  }

  .benefits-img-3 {
    left: 0px !important;
  }

}


/* thank you page  */

.pt-100 {
  padding-top: 100px;
}



.thanks-dev-container {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #EE3A38;
  background: url('../../assests/images/pngegg-red-left.png') no-repeat left,
    url('../../assests/images/pngegg-red-right.png') no-repeat right,
    #EE3A38;
  background-size: cover 100%;
  margin-top: 50px;
  padding: 20px;
}

.thanks-bg-container {
  justify-content: space-between;
  align-items: center;
  height: 400px;
  background: url('../../assests/images/pngegg_left.png') no-repeat left,
    url('../../assests/images/pngegg_right.png') no-repeat right;
  background-size: auto 100%;
  background-color: #fff;
  padding: 0 50px;
}

.thanks-card {
  margin-top: -160px;
  height: 440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.thanks-text-container {
  flex: 1;
  padding: 20px;
  background-color: #EDEDED;
}


.thanks-bg-image {
  flex: 1;
}

.thanks-bg-image img {
  width: 100%;
  height: auto;
}

.thanks-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.thanks-subtitle {
  font-size: 16px;
  margin-top: 10px;
  color: #666;
}

.thanks-contact {
  font-size: 16px;
  margin-top: 10px;
  color: #333;
  display: flex;
  align-items: center;
}

.thanks-icon {
  margin-right: 10px;
}

.phone-icon::before,
.email-icon::before {
  display: inline-block;
  content: "";
  width: 20px;
  height: 20px;
  background-size: cover;
}

.phone-icon::before {
  background-image: url('../../assests/images/callIconRed.png');
}

.email-icon::before {
  background-image: url('../../assests/images/mailIconRed.png');
}

.thanks-qr-container {
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 35px;
}

.thanks-qr {
  gap: 20px;
  display: flex;
}

.thanks-qr img {
  width: 100px;
  height: auto;
  margin-bottom: 5px;
}

.thanks-qr-text {
  margin-top: 20px;
}

.midSmallText {
  text-align: center;
  color: #fff;
  margin-top: 20px;
}

.green {
  color: #51D314;
}

.blue {
  color: #4F96F2;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .thanks-card {
    flex-direction: column;
  }

  .thanks-text-container,
  .thanks-bg-image {
    width: 100%;
  }

  .thanks-qr {
    gap: 2px;
  }

  .thanks-qr img {
    width: 65px;
  }

  .thanks-qr-text {
    margin-top: 5px;
  }

  .thanks-text-container {
    padding: 15px;
    margin-top: -15px;
  }

  .thanks-qr-container {
    flex-direction: column;
  }

  .thanks-bg-text {
    margin-top: -125px !important;
  }
}